$(document).ready(function () {

    //On cities modal call
    $('.show-cities').on('click', function (e) {
        $.ajax({
            url: '/cities',
            success: function (response) {
                $('#city-get').html(response.cities);
            },
            dataType: 'json'
        });
    });

    //Burger open/close
    $('.header__burger').on('click', function () {
        $('.fixed_menu').addClass('nav-open');
    });
    $('.fixed_menu_close').on('click', function () {
        $('.fixed_menu').removeClass('nav-open');
    });

    //Submenu events
    $(document).on('mouseout', '.submenu', function () {
        $(this).removeClass('is-active');
        $('.header-overlay').removeClass('is-active');
    });
    $(document).on('mouseover', '.submenu', function () {
        $(this).addClass('is-active');
        $('.header-overlay').addClass('is-active');
    });

    //Header drop
    $('.header__drop').on('click', function () {
        if ($(this).hasClass('is-active')) {
            $(this).removeClass('is-active');
        } else {
            $(this).addClass('is-active');
        }
    });

    //City search in modal
    $(document).on('change paste keyup', '#city-search', function (e) {
        e.preventDefault();
        var searchPart = $(this).val().toLowerCase();
        if (searchPart.length > 0) {
            var allHidden = true;

            $('.city-item').each(function () {
                var citiesList = $(this).children('ul')[0];
                var toHide = true;
                $(citiesList).children('li').each(function () {
                    var link = $(this).find('a')[0];
                    link = $(link);
                    var linkText = link.html().toLowerCase();

                    if (!linkText.includes(searchPart)) {
                        $(this).hide();
                    } else {
                        $(this).show();
                        toHide = false;
                    }
                });

                if (toHide) {
                    $(this).hide();
                } else {
                    $(this).show();
                    allHidden = false;
                }
            });

            if (allHidden) {
                $('.city-search-info').show();
                $('#city-get').hide();
            } else {
                $('.city-search-info').hide();
                $('#city-get').show();
            }

        } else {
            $('.city-item').each(function () {
                var citiesList = $(this).children('ul')[0];
                $(citiesList).children('li').each(function () {
                    $(this).show();
                });

                $(this).show();
            });
            $('.city-search-info').hide();
            $('#city-get').show();
        }
    });

    //Collapsible part
    var coll = document.getElementsByClassName("collapsible");
    var i;

    for (i = 0; i < coll.length; i++) {
        coll[i].addEventListener("click", function () {
            this.classList.toggle("active");
            var content = this.nextElementSibling;
            if (content.style.maxHeight) {
                content.style.maxHeight = null;
            } else {
                content.style.maxHeight = (content.scrollHeight) + "px";
            }
        });
    }

    //Tabs
    $('.tab.active').each(function () {
        var panelId = $(this).attr('id') + '-panel';
        $('#' + panelId).show();
    });
    $(document).on('click', '.tab', function () {
        var tabGroup = $(this).data('tab-group');
        var attributeSelectorPostfix = '[data-tab-group='+tabGroup+']';
        $('.tab' + attributeSelectorPostfix).removeClass('active');
        $('.panel' + attributeSelectorPostfix).hide();

        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
            $('#' + $(this).attr('id') + '-panel').hide();
        } else {
            $(this).addClass('active');
            $('#' + $(this).attr('id') + '-panel').show();
        }
    });

    //Open tab from parameter
    var searchParams = new URLSearchParams(window.location.search);
    console.log(searchParams.get('tab'));
    if (searchParams.has('tab')) {
        var tabIdToOpen = searchParams.get('tab');
        $('#' + tabIdToOpen + '-tab').click();
    }
});

//Load swiper
var swiper = new Swiper('.swiper', {
    // Optional parameters
    direction: 'horizontal',
    loop: true,

    // If we need pagination
    pagination: {
        el: '.swiper-pagination',
    },

    // Navigation arrows
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },

    // And if we need scrollbar
    scrollbar: {
        el: '.swiper-scrollbar',
    }
});

