!function (e, t) {
    "use strict";
    "object" == typeof module && "object" == typeof module.exports ? module.exports = e.document ? t(e, !0) : function (e) {
        if (!e.document) throw new Error("jQuery requires a window with a document");
        return t(e)
    } : t(e)
}("undefined" != typeof window ? window : this, function (T, e) {
    "use strict";

    function g(e) {
        return null != e && e === e.window
    }

    var t = [], $ = T.document, i = Object.getPrototypeOf, a = t.slice, m = t.concat, l = t.push, o = t.indexOf, n = {},
        r = n.toString, v = n.hasOwnProperty, s = v.toString, c = s.call(Object), y = {}, b = function (e) {
            return "function" == typeof e && "number" != typeof e.nodeType
        }, u = {type: !0, src: !0, noModule: !0};

    function x(e, t, n) {
        var i, o = (t = t || $).createElement("script");
        if (o.text = e, n) for (i in u) n[i] && (o[i] = n[i]);
        t.head.appendChild(o).parentNode.removeChild(o)
    }

    function w(e) {
        return null == e ? e + "" : "object" == typeof e || "function" == typeof e ? n[r.call(e)] || "object" : typeof e
    }

    var S = function (e, t) {
        return new S.fn.init(e, t)
    }, d = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g;

    function f(e) {
        var t = !!e && "length" in e && e.length, n = w(e);
        return !b(e) && !g(e) && ("array" === n || 0 === t || "number" == typeof t && 0 < t && t - 1 in e)
    }

    S.fn = S.prototype = {
        jquery: "3.3.1", constructor: S, length: 0, toArray: function () {
            return a.call(this)
        }, get: function (e) {
            return null == e ? a.call(this) : e < 0 ? this[e + this.length] : this[e]
        }, pushStack: function (e) {
            var t = S.merge(this.constructor(), e);
            return t.prevObject = this, t
        }, each: function (e) {
            return S.each(this, e)
        }, map: function (n) {
            return this.pushStack(S.map(this, function (e, t) {
                return n.call(e, t, e)
            }))
        }, slice: function () {
            return this.pushStack(a.apply(this, arguments))
        }, first: function () {
            return this.eq(0)
        }, last: function () {
            return this.eq(-1)
        }, eq: function (e) {
            var t = this.length, n = +e + (e < 0 ? t : 0);
            return this.pushStack(0 <= n && n < t ? [this[n]] : [])
        }, end: function () {
            return this.prevObject || this.constructor()
        }, push: l, sort: t.sort, splice: t.splice
    }, S.extend = S.fn.extend = function () {
        var e, t, n, i, o, r, s = arguments[0] || {}, a = 1, l = arguments.length, c = !1;
        for ("boolean" == typeof s && (c = s, s = arguments[a] || {}, a++), "object" == typeof s || b(s) || (s = {}), a === l && (s = this, a--); a < l; a++) if (null != (e = arguments[a])) for (t in e) n = s[t], s !== (i = e[t]) && (c && i && (S.isPlainObject(i) || (o = Array.isArray(i))) ? (r = o ? (o = !1, n && Array.isArray(n) ? n : []) : n && S.isPlainObject(n) ? n : {}, s[t] = S.extend(c, r, i)) : void 0 !== i && (s[t] = i));
        return s
    }, S.extend({
        expando: "jQuery" + ("3.3.1" + Math.random()).replace(/\D/g, ""), isReady: !0, error: function (e) {
            throw new Error(e)
        }, noop: function () {
        }, isPlainObject: function (e) {
            var t, n;
            return !(!e || "[object Object]" !== r.call(e) || (t = i(e)) && ("function" != typeof (n = v.call(t, "constructor") && t.constructor) || s.call(n) !== c))
        }, isEmptyObject: function (e) {
            var t;
            for (t in e) return !1;
            return !0
        }, globalEval: function (e) {
            x(e)
        }, each: function (e, t) {
            var n, i = 0;
            if (f(e)) for (n = e.length; i < n && !1 !== t.call(e[i], i, e[i]); i++) ; else for (i in e) if (!1 === t.call(e[i], i, e[i])) break;
            return e
        }, trim: function (e) {
            return null == e ? "" : (e + "").replace(d, "")
        }, makeArray: function (e, t) {
            var n = t || [];
            return null != e && (f(Object(e)) ? S.merge(n, "string" == typeof e ? [e] : e) : l.call(n, e)), n
        }, inArray: function (e, t, n) {
            return null == t ? -1 : o.call(t, e, n)
        }, merge: function (e, t) {
            for (var n = +t.length, i = 0, o = e.length; i < n; i++) e[o++] = t[i];
            return e.length = o, e
        }, grep: function (e, t, n) {
            for (var i = [], o = 0, r = e.length, s = !n; o < r; o++) !t(e[o], o) != s && i.push(e[o]);
            return i
        }, map: function (e, t, n) {
            var i, o, r = 0, s = [];
            if (f(e)) for (i = e.length; r < i; r++) null != (o = t(e[r], r, n)) && s.push(o); else for (r in e) null != (o = t(e[r], r, n)) && s.push(o);
            return m.apply([], s)
        }, guid: 1, support: y
    }), "function" == typeof Symbol && (S.fn[Symbol.iterator] = t[Symbol.iterator]), S.each("Boolean Number String Function Array Date RegExp Object Error Symbol".split(" "), function (e, t) {
        n["[object " + t + "]"] = t.toLowerCase()
    });
    var h = function (n) {
        function d(e, t, n) {
            var i = "0x" + t - 65536;
            return i != i || n ? t : i < 0 ? String.fromCharCode(65536 + i) : String.fromCharCode(i >> 10 | 55296, 1023 & i | 56320)
        }

        function o() {
            C()
        }

        var e, h, x, r, s, p, f, g, w, l, c, C, T, a, $, m, u, v, y, S = "sizzle" + 1 * new Date, b = n.document, k = 0,
            i = 0, E = se(), P = se(), A = se(), L = function (e, t) {
                return e === t && (c = !0), 0
            }, q = {}.hasOwnProperty, t = [], D = t.pop, j = t.push, M = t.push, N = t.slice, F = function (e, t) {
                for (var n = 0, i = e.length; n < i; n++) if (e[n] === t) return n;
                return -1
            },
            I = "checked|selected|async|autofocus|autoplay|controls|defer|disabled|hidden|ismap|loop|multiple|open|readonly|required|scoped",
            R = "[\\x20\\t\\r\\n\\f]", H = "(?:\\\\.|[\\w-]|[^\0-\\xa0])+",
            O = "\\[" + R + "*(" + H + ")(?:" + R + "*([*^$|!~]?=)" + R + "*(?:'((?:\\\\.|[^\\\\'])*)'|\"((?:\\\\.|[^\\\\\"])*)\"|(" + H + "))|)" + R + "*\\]",
            z = ":(" + H + ")(?:\\((('((?:\\\\.|[^\\\\'])*)'|\"((?:\\\\.|[^\\\\\"])*)\")|((?:\\\\.|[^\\\\()[\\]]|" + O + ")*)|.*)\\)|)",
            B = new RegExp(R + "+", "g"), _ = new RegExp("^" + R + "+|((?:^|[^\\\\])(?:\\\\.)*)" + R + "+$", "g"),
            W = new RegExp("^" + R + "*," + R + "*"), X = new RegExp("^" + R + "*([>+~]|" + R + ")" + R + "*"),
            Y = new RegExp("=" + R + "*([^\\]'\"]*?)" + R + "*\\]", "g"), U = new RegExp(z),
            V = new RegExp("^" + H + "$"), Z = {
                ID: new RegExp("^#(" + H + ")"),
                CLASS: new RegExp("^\\.(" + H + ")"),
                TAG: new RegExp("^(" + H + "|[*])"),
                ATTR: new RegExp("^" + O),
                PSEUDO: new RegExp("^" + z),
                CHILD: new RegExp("^:(only|first|last|nth|nth-last)-(child|of-type)(?:\\(" + R + "*(even|odd|(([+-]|)(\\d*)n|)" + R + "*(?:([+-]|)" + R + "*(\\d+)|))" + R + "*\\)|)", "i"),
                bool: new RegExp("^(?:" + I + ")$", "i"),
                needsContext: new RegExp("^" + R + "*[>+~]|:(even|odd|eq|gt|lt|nth|first|last)(?:\\(" + R + "*((?:-\\d)?\\d*)" + R + "*\\)|)(?=[^-]|$)", "i")
            }, Q = /^(?:input|select|textarea|button)$/i, G = /^h\d$/i, K = /^[^{]+\{\s*\[native \w/,
            J = /^(?:#([\w-]+)|(\w+)|\.([\w-]+))$/, ee = /[+~]/,
            te = new RegExp("\\\\([\\da-f]{1,6}" + R + "?|(" + R + ")|.)", "ig"),
            ne = /([\0-\x1f\x7f]|^-?\d)|^-$|[^\0-\x1f\x7f-\uFFFF\w-]/g, ie = function (e, t) {
                return t ? "\0" === e ? "�" : e.slice(0, -1) + "\\" + e.charCodeAt(e.length - 1).toString(16) + " " : "\\" + e
            }, oe = ye(function (e) {
                return !0 === e.disabled && ("form" in e || "label" in e)
            }, {dir: "parentNode", next: "legend"});
        try {
            M.apply(t = N.call(b.childNodes), b.childNodes), t[b.childNodes.length].nodeType
        } catch (n) {
            M = {
                apply: t.length ? function (e, t) {
                    j.apply(e, N.call(t))
                } : function (e, t) {
                    for (var n = e.length, i = 0; e[n++] = t[i++];) ;
                    e.length = n - 1
                }
            }
        }

        function re(e, t, n, i) {
            var o, r, s, a, l, c, u, d = t && t.ownerDocument, f = t ? t.nodeType : 9;
            if (n = n || [], "string" != typeof e || !e || 1 !== f && 9 !== f && 11 !== f) return n;
            if (!i && ((t ? t.ownerDocument || t : b) !== T && C(t), t = t || T, $)) {
                if (11 !== f && (l = J.exec(e))) if (o = l[1]) {
                    if (9 === f) {
                        if (!(s = t.getElementById(o))) return n;
                        if (s.id === o) return n.push(s), n
                    } else if (d && (s = d.getElementById(o)) && y(t, s) && s.id === o) return n.push(s), n
                } else {
                    if (l[2]) return M.apply(n, t.getElementsByTagName(e)), n;
                    if ((o = l[3]) && h.getElementsByClassName && t.getElementsByClassName) return M.apply(n, t.getElementsByClassName(o)), n
                }
                if (h.qsa && !A[e + " "] && (!m || !m.test(e))) {
                    if (1 !== f) d = t, u = e; else if ("object" !== t.nodeName.toLowerCase()) {
                        for ((a = t.getAttribute("id")) ? a = a.replace(ne, ie) : t.setAttribute("id", a = S), r = (c = p(e)).length; r--;) c[r] = "#" + a + " " + ve(c[r]);
                        u = c.join(","), d = ee.test(e) && ge(t.parentNode) || t
                    }
                    if (u) try {
                        return M.apply(n, d.querySelectorAll(u)), n
                    } catch (e) {
                    } finally {
                        a === S && t.removeAttribute("id")
                    }
                }
            }
            return g(e.replace(_, "$1"), t, n, i)
        }

        function se() {
            var i = [];
            return function e(t, n) {
                return i.push(t + " ") > x.cacheLength && delete e[i.shift()], e[t + " "] = n
            }
        }

        function ae(e) {
            return e[S] = !0, e
        }

        function le(e) {
            var t = T.createElement("fieldset");
            try {
                return !!e(t)
            } catch (e) {
                return !1
            } finally {
                t.parentNode && t.parentNode.removeChild(t), t = null
            }
        }

        function ce(e, t) {
            for (var n = e.split("|"), i = n.length; i--;) x.attrHandle[n[i]] = t
        }

        function ue(e, t) {
            var n = t && e, i = n && 1 === e.nodeType && 1 === t.nodeType && e.sourceIndex - t.sourceIndex;
            if (i) return i;
            if (n) for (; n = n.nextSibling;) if (n === t) return -1;
            return e ? 1 : -1
        }

        function de(t) {
            return function (e) {
                return "input" === e.nodeName.toLowerCase() && e.type === t
            }
        }

        function fe(n) {
            return function (e) {
                var t = e.nodeName.toLowerCase();
                return ("input" === t || "button" === t) && e.type === n
            }
        }

        function he(t) {
            return function (e) {
                return "form" in e ? e.parentNode && !1 === e.disabled ? "label" in e ? "label" in e.parentNode ? e.parentNode.disabled === t : e.disabled === t : e.isDisabled === t || e.isDisabled !== !t && oe(e) === t : e.disabled === t : "label" in e && e.disabled === t
            }
        }

        function pe(s) {
            return ae(function (r) {
                return r = +r, ae(function (e, t) {
                    for (var n, i = s([], e.length, r), o = i.length; o--;) e[n = i[o]] && (e[n] = !(t[n] = e[n]))
                })
            })
        }

        function ge(e) {
            return e && void 0 !== e.getElementsByTagName && e
        }

        for (e in h = re.support = {}, s = re.isXML = function (e) {
            var t = e && (e.ownerDocument || e).documentElement;
            return !!t && "HTML" !== t.nodeName
        }, C = re.setDocument = function (e) {
            var t, n, i = e ? e.ownerDocument || e : b;
            return i !== T && 9 === i.nodeType && i.documentElement && (a = (T = i).documentElement, $ = !s(T), b !== T && (n = T.defaultView) && n.top !== n && (n.addEventListener ? n.addEventListener("unload", o, !1) : n.attachEvent && n.attachEvent("onunload", o)), h.attributes = le(function (e) {
                return e.className = "i", !e.getAttribute("className")
            }), h.getElementsByTagName = le(function (e) {
                return e.appendChild(T.createComment("")), !e.getElementsByTagName("*").length
            }), h.getElementsByClassName = K.test(T.getElementsByClassName), h.getById = le(function (e) {
                return a.appendChild(e).id = S, !T.getElementsByName || !T.getElementsByName(S).length
            }), h.getById ? (x.filter.ID = function (e) {
                var t = e.replace(te, d);
                return function (e) {
                    return e.getAttribute("id") === t
                }
            }, x.find.ID = function (e, t) {
                if (void 0 !== t.getElementById && $) {
                    var n = t.getElementById(e);
                    return n ? [n] : []
                }
            }) : (x.filter.ID = function (e) {
                var n = e.replace(te, d);
                return function (e) {
                    var t = void 0 !== e.getAttributeNode && e.getAttributeNode("id");
                    return t && t.value === n
                }
            }, x.find.ID = function (e, t) {
                if (void 0 !== t.getElementById && $) {
                    var n, i, o, r = t.getElementById(e);
                    if (r) {
                        if ((n = r.getAttributeNode("id")) && n.value === e) return [r];
                        for (o = t.getElementsByName(e), i = 0; r = o[i++];) if ((n = r.getAttributeNode("id")) && n.value === e) return [r]
                    }
                    return []
                }
            }), x.find.TAG = h.getElementsByTagName ? function (e, t) {
                return void 0 !== t.getElementsByTagName ? t.getElementsByTagName(e) : h.qsa ? t.querySelectorAll(e) : void 0
            } : function (e, t) {
                var n, i = [], o = 0, r = t.getElementsByTagName(e);
                if ("*" !== e) return r;
                for (; n = r[o++];) 1 === n.nodeType && i.push(n);
                return i
            }, x.find.CLASS = h.getElementsByClassName && function (e, t) {
                if (void 0 !== t.getElementsByClassName && $) return t.getElementsByClassName(e)
            }, u = [], m = [], (h.qsa = K.test(T.querySelectorAll)) && (le(function (e) {
                a.appendChild(e).innerHTML = "<a id='" + S + "'></a><select id='" + S + "-\r\\' msallowcapture=''><option selected=''></option></select>", e.querySelectorAll("[msallowcapture^='']").length && m.push("[*^$]=" + R + "*(?:''|\"\")"), e.querySelectorAll("[selected]").length || m.push("\\[" + R + "*(?:value|" + I + ")"), e.querySelectorAll("[id~=" + S + "-]").length || m.push("~="), e.querySelectorAll(":checked").length || m.push(":checked"), e.querySelectorAll("a#" + S + "+*").length || m.push(".#.+[+~]")
            }), le(function (e) {
                e.innerHTML = "<a href='' disabled='disabled'></a><select disabled='disabled'><option/></select>";
                var t = T.createElement("input");
                t.setAttribute("type", "hidden"), e.appendChild(t).setAttribute("name", "D"), e.querySelectorAll("[name=d]").length && m.push("name" + R + "*[*^$|!~]?="), 2 !== e.querySelectorAll(":enabled").length && m.push(":enabled", ":disabled"), a.appendChild(e).disabled = !0, 2 !== e.querySelectorAll(":disabled").length && m.push(":enabled", ":disabled"), e.querySelectorAll("*,:x"), m.push(",.*:")
            })), (h.matchesSelector = K.test(v = a.matches || a.webkitMatchesSelector || a.mozMatchesSelector || a.oMatchesSelector || a.msMatchesSelector)) && le(function (e) {
                h.disconnectedMatch = v.call(e, "*"), v.call(e, "[s!='']:x"), u.push("!=", z)
            }), m = m.length && new RegExp(m.join("|")), u = u.length && new RegExp(u.join("|")), t = K.test(a.compareDocumentPosition), y = t || K.test(a.contains) ? function (e, t) {
                var n = 9 === e.nodeType ? e.documentElement : e, i = t && t.parentNode;
                return e === i || !(!i || 1 !== i.nodeType || !(n.contains ? n.contains(i) : e.compareDocumentPosition && 16 & e.compareDocumentPosition(i)))
            } : function (e, t) {
                if (t) for (; t = t.parentNode;) if (t === e) return !0;
                return !1
            }, L = t ? function (e, t) {
                if (e === t) return c = !0, 0;
                var n = !e.compareDocumentPosition - !t.compareDocumentPosition;
                return n || (1 & (n = (e.ownerDocument || e) === (t.ownerDocument || t) ? e.compareDocumentPosition(t) : 1) || !h.sortDetached && t.compareDocumentPosition(e) === n ? e === T || e.ownerDocument === b && y(b, e) ? -1 : t === T || t.ownerDocument === b && y(b, t) ? 1 : l ? F(l, e) - F(l, t) : 0 : 4 & n ? -1 : 1)
            } : function (e, t) {
                if (e === t) return c = !0, 0;
                var n, i = 0, o = e.parentNode, r = t.parentNode, s = [e], a = [t];
                if (!o || !r) return e === T ? -1 : t === T ? 1 : o ? -1 : r ? 1 : l ? F(l, e) - F(l, t) : 0;
                if (o === r) return ue(e, t);
                for (n = e; n = n.parentNode;) s.unshift(n);
                for (n = t; n = n.parentNode;) a.unshift(n);
                for (; s[i] === a[i];) i++;
                return i ? ue(s[i], a[i]) : s[i] === b ? -1 : a[i] === b ? 1 : 0
            }), T
        }, re.matches = function (e, t) {
            return re(e, null, null, t)
        }, re.matchesSelector = function (e, t) {
            if ((e.ownerDocument || e) !== T && C(e), t = t.replace(Y, "='$1']"), h.matchesSelector && $ && !A[t + " "] && (!u || !u.test(t)) && (!m || !m.test(t))) try {
                var n = v.call(e, t);
                if (n || h.disconnectedMatch || e.document && 11 !== e.document.nodeType) return n
            } catch (e) {
            }
            return 0 < re(t, T, null, [e]).length
        }, re.contains = function (e, t) {
            return (e.ownerDocument || e) !== T && C(e), y(e, t)
        }, re.attr = function (e, t) {
            (e.ownerDocument || e) !== T && C(e);
            var n = x.attrHandle[t.toLowerCase()],
                i = n && q.call(x.attrHandle, t.toLowerCase()) ? n(e, t, !$) : void 0;
            return void 0 !== i ? i : h.attributes || !$ ? e.getAttribute(t) : (i = e.getAttributeNode(t)) && i.specified ? i.value : null
        }, re.escape = function (e) {
            return (e + "").replace(ne, ie)
        }, re.error = function (e) {
            throw new Error("Syntax error, unrecognized expression: " + e)
        }, re.uniqueSort = function (e) {
            var t, n = [], i = 0, o = 0;
            if (c = !h.detectDuplicates, l = !h.sortStable && e.slice(0), e.sort(L), c) {
                for (; t = e[o++];) t === e[o] && (i = n.push(o));
                for (; i--;) e.splice(n[i], 1)
            }
            return l = null, e
        }, r = re.getText = function (e) {
            var t, n = "", i = 0, o = e.nodeType;
            if (o) {
                if (1 === o || 9 === o || 11 === o) {
                    if ("string" == typeof e.textContent) return e.textContent;
                    for (e = e.firstChild; e; e = e.nextSibling) n += r(e)
                } else if (3 === o || 4 === o) return e.nodeValue
            } else for (; t = e[i++];) n += r(t);
            return n
        }, (x = re.selectors = {
            cacheLength: 50,
            createPseudo: ae,
            match: Z,
            attrHandle: {},
            find: {},
            relative: {
                ">": {dir: "parentNode", first: !0},
                " ": {dir: "parentNode"},
                "+": {dir: "previousSibling", first: !0},
                "~": {dir: "previousSibling"}
            },
            preFilter: {
                ATTR: function (e) {
                    return e[1] = e[1].replace(te, d), e[3] = (e[3] || e[4] || e[5] || "").replace(te, d), "~=" === e[2] && (e[3] = " " + e[3] + " "), e.slice(0, 4)
                }, CHILD: function (e) {
                    return e[1] = e[1].toLowerCase(), "nth" === e[1].slice(0, 3) ? (e[3] || re.error(e[0]), e[4] = +(e[4] ? e[5] + (e[6] || 1) : 2 * ("even" === e[3] || "odd" === e[3])), e[5] = +(e[7] + e[8] || "odd" === e[3])) : e[3] && re.error(e[0]), e
                }, PSEUDO: function (e) {
                    var t, n = !e[6] && e[2];
                    return Z.CHILD.test(e[0]) ? null : (e[3] ? e[2] = e[4] || e[5] || "" : n && U.test(n) && (t = p(n, !0)) && (t = n.indexOf(")", n.length - t) - n.length) && (e[0] = e[0].slice(0, t), e[2] = n.slice(0, t)), e.slice(0, 3))
                }
            },
            filter: {
                TAG: function (e) {
                    var t = e.replace(te, d).toLowerCase();
                    return "*" === e ? function () {
                        return !0
                    } : function (e) {
                        return e.nodeName && e.nodeName.toLowerCase() === t
                    }
                }, CLASS: function (e) {
                    var t = E[e + " "];
                    return t || (t = new RegExp("(^|" + R + ")" + e + "(" + R + "|$)")) && E(e, function (e) {
                        return t.test("string" == typeof e.className && e.className || void 0 !== e.getAttribute && e.getAttribute("class") || "")
                    })
                }, ATTR: function (n, i, o) {
                    return function (e) {
                        var t = re.attr(e, n);
                        return null == t ? "!=" === i : !i || (t += "", "=" === i ? t === o : "!=" === i ? t !== o : "^=" === i ? o && 0 === t.indexOf(o) : "*=" === i ? o && -1 < t.indexOf(o) : "$=" === i ? o && t.slice(-o.length) === o : "~=" === i ? -1 < (" " + t.replace(B, " ") + " ").indexOf(o) : "|=" === i && (t === o || t.slice(0, o.length + 1) === o + "-"))
                    }
                }, CHILD: function (p, e, t, g, m) {
                    var v = "nth" !== p.slice(0, 3), y = "last" !== p.slice(-4), b = "of-type" === e;
                    return 1 === g && 0 === m ? function (e) {
                        return !!e.parentNode
                    } : function (e, t, n) {
                        var i, o, r, s, a, l, c = v != y ? "nextSibling" : "previousSibling", u = e.parentNode,
                            d = b && e.nodeName.toLowerCase(), f = !n && !b, h = !1;
                        if (u) {
                            if (v) {
                                for (; c;) {
                                    for (s = e; s = s[c];) if (b ? s.nodeName.toLowerCase() === d : 1 === s.nodeType) return !1;
                                    l = c = "only" === p && !l && "nextSibling"
                                }
                                return !0
                            }
                            if (l = [y ? u.firstChild : u.lastChild], y && f) {
                                for (h = (a = (i = (o = (r = (s = u)[S] || (s[S] = {}))[s.uniqueID] || (r[s.uniqueID] = {}))[p] || [])[0] === k && i[1]) && i[2], s = a && u.childNodes[a]; s = ++a && s && s[c] || (h = a = 0) || l.pop();) if (1 === s.nodeType && ++h && s === e) {
                                    o[p] = [k, a, h];
                                    break
                                }
                            } else if (f && (h = a = (i = (o = (r = (s = e)[S] || (s[S] = {}))[s.uniqueID] || (r[s.uniqueID] = {}))[p] || [])[0] === k && i[1]), !1 === h) for (; (s = ++a && s && s[c] || (h = a = 0) || l.pop()) && ((b ? s.nodeName.toLowerCase() !== d : 1 !== s.nodeType) || !++h || (f && ((o = (r = s[S] || (s[S] = {}))[s.uniqueID] || (r[s.uniqueID] = {}))[p] = [k, h]), s !== e));) ;
                            return (h -= m) === g || h % g == 0 && 0 <= h / g
                        }
                    }
                }, PSEUDO: function (e, r) {
                    var t, s = x.pseudos[e] || x.setFilters[e.toLowerCase()] || re.error("unsupported pseudo: " + e);
                    return s[S] ? s(r) : 1 < s.length ? (t = [e, e, "", r], x.setFilters.hasOwnProperty(e.toLowerCase()) ? ae(function (e, t) {
                        for (var n, i = s(e, r), o = i.length; o--;) e[n = F(e, i[o])] = !(t[n] = i[o])
                    }) : function (e) {
                        return s(e, 0, t)
                    }) : s
                }
            },
            pseudos: {
                not: ae(function (e) {
                    var i = [], o = [], a = f(e.replace(_, "$1"));
                    return a[S] ? ae(function (e, t, n, i) {
                        for (var o, r = a(e, null, i, []), s = e.length; s--;) (o = r[s]) && (e[s] = !(t[s] = o))
                    }) : function (e, t, n) {
                        return i[0] = e, a(i, null, n, o), i[0] = null, !o.pop()
                    }
                }), has: ae(function (t) {
                    return function (e) {
                        return 0 < re(t, e).length
                    }
                }), contains: ae(function (t) {
                    return t = t.replace(te, d), function (e) {
                        return -1 < (e.textContent || e.innerText || r(e)).indexOf(t)
                    }
                }), lang: ae(function (n) {
                    return V.test(n || "") || re.error("unsupported lang: " + n), n = n.replace(te, d).toLowerCase(), function (e) {
                        var t;
                        do {
                            if (t = $ ? e.lang : e.getAttribute("xml:lang") || e.getAttribute("lang")) return (t = t.toLowerCase()) === n || 0 === t.indexOf(n + "-")
                        } while ((e = e.parentNode) && 1 === e.nodeType);
                        return !1
                    }
                }), target: function (e) {
                    var t = n.location && n.location.hash;
                    return t && t.slice(1) === e.id
                }, root: function (e) {
                    return e === a
                }, focus: function (e) {
                    return e === T.activeElement && (!T.hasFocus || T.hasFocus()) && !!(e.type || e.href || ~e.tabIndex)
                }, enabled: he(!1), disabled: he(!0), checked: function (e) {
                    var t = e.nodeName.toLowerCase();
                    return "input" === t && !!e.checked || "option" === t && !!e.selected
                }, selected: function (e) {
                    return e.parentNode && e.parentNode.selectedIndex, !0 === e.selected
                }, empty: function (e) {
                    for (e = e.firstChild; e; e = e.nextSibling) if (e.nodeType < 6) return !1;
                    return !0
                }, parent: function (e) {
                    return !x.pseudos.empty(e)
                }, header: function (e) {
                    return G.test(e.nodeName)
                }, input: function (e) {
                    return Q.test(e.nodeName)
                }, button: function (e) {
                    var t = e.nodeName.toLowerCase();
                    return "input" === t && "button" === e.type || "button" === t
                }, text: function (e) {
                    var t;
                    return "input" === e.nodeName.toLowerCase() && "text" === e.type && (null == (t = e.getAttribute("type")) || "text" === t.toLowerCase())
                }, first: pe(function () {
                    return [0]
                }), last: pe(function (e, t) {
                    return [t - 1]
                }), eq: pe(function (e, t, n) {
                    return [n < 0 ? n + t : n]
                }), even: pe(function (e, t) {
                    for (var n = 0; n < t; n += 2) e.push(n);
                    return e
                }), odd: pe(function (e, t) {
                    for (var n = 1; n < t; n += 2) e.push(n);
                    return e
                }), lt: pe(function (e, t, n) {
                    for (var i = n < 0 ? n + t : n; 0 <= --i;) e.push(i);
                    return e
                }), gt: pe(function (e, t, n) {
                    for (var i = n < 0 ? n + t : n; ++i < t;) e.push(i);
                    return e
                })
            }
        }).pseudos.nth = x.pseudos.eq, {
            radio: !0,
            checkbox: !0,
            file: !0,
            password: !0,
            image: !0
        }) x.pseudos[e] = de(e);
        for (e in {submit: !0, reset: !0}) x.pseudos[e] = fe(e);

        function me() {
        }

        function ve(e) {
            for (var t = 0, n = e.length, i = ""; t < n; t++) i += e[t].value;
            return i
        }

        function ye(a, e, t) {
            var l = e.dir, c = e.next, u = c || l, d = t && "parentNode" === u, f = i++;
            return e.first ? function (e, t, n) {
                for (; e = e[l];) if (1 === e.nodeType || d) return a(e, t, n);
                return !1
            } : function (e, t, n) {
                var i, o, r, s = [k, f];
                if (n) {
                    for (; e = e[l];) if ((1 === e.nodeType || d) && a(e, t, n)) return !0
                } else for (; e = e[l];) if (1 === e.nodeType || d) if (o = (r = e[S] || (e[S] = {}))[e.uniqueID] || (r[e.uniqueID] = {}), c && c === e.nodeName.toLowerCase()) e = e[l] || e; else {
                    if ((i = o[u]) && i[0] === k && i[1] === f) return s[2] = i[2];
                    if ((o[u] = s)[2] = a(e, t, n)) return !0
                }
                return !1
            }
        }

        function be(o) {
            return 1 < o.length ? function (e, t, n) {
                for (var i = o.length; i--;) if (!o[i](e, t, n)) return !1;
                return !0
            } : o[0]
        }

        function xe(e, t, n, i, o) {
            for (var r, s = [], a = 0, l = e.length, c = null != t; a < l; a++) (r = e[a]) && (n && !n(r, i, o) || (s.push(r), c && t.push(a)));
            return s
        }

        function we(h, p, g, m, v, e) {
            return m && !m[S] && (m = we(m)), v && !v[S] && (v = we(v, e)), ae(function (e, t, n, i) {
                var o, r, s, a = [], l = [], c = t.length, u = e || function (e, t, n) {
                        for (var i = 0, o = t.length; i < o; i++) re(e, t[i], n);
                        return n
                    }(p || "*", n.nodeType ? [n] : n, []), d = !h || !e && p ? u : xe(u, a, h, n, i),
                    f = g ? v || (e ? h : c || m) ? [] : t : d;
                if (g && g(d, f, n, i), m) for (o = xe(f, l), m(o, [], n, i), r = o.length; r--;) (s = o[r]) && (f[l[r]] = !(d[l[r]] = s));
                if (e) {
                    if (v || h) {
                        if (v) {
                            for (o = [], r = f.length; r--;) (s = f[r]) && o.push(d[r] = s);
                            v(null, f = [], o, i)
                        }
                        for (r = f.length; r--;) (s = f[r]) && -1 < (o = v ? F(e, s) : a[r]) && (e[o] = !(t[o] = s))
                    }
                } else f = xe(f === t ? f.splice(c, f.length) : f), v ? v(null, t, f, i) : M.apply(t, f)
            })
        }

        function Ce(e) {
            for (var o, t, n, i = e.length, r = x.relative[e[0].type], s = r || x.relative[" "], a = r ? 1 : 0, l = ye(function (e) {
                return e === o
            }, s, !0), c = ye(function (e) {
                return -1 < F(o, e)
            }, s, !0), u = [function (e, t, n) {
                var i = !r && (n || t !== w) || ((o = t).nodeType ? l(e, t, n) : c(e, t, n));
                return o = null, i
            }]; a < i; a++) if (t = x.relative[e[a].type]) u = [ye(be(u), t)]; else {
                if ((t = x.filter[e[a].type].apply(null, e[a].matches))[S]) {
                    for (n = ++a; n < i && !x.relative[e[n].type]; n++) ;
                    return we(1 < a && be(u), 1 < a && ve(e.slice(0, a - 1).concat({value: " " === e[a - 2].type ? "*" : ""})).replace(_, "$1"), t, a < n && Ce(e.slice(a, n)), n < i && Ce(e = e.slice(n)), n < i && ve(e))
                }
                u.push(t)
            }
            return be(u)
        }

        function Te(m, v) {
            function e(e, t, n, i, o) {
                var r, s, a, l = 0, c = "0", u = e && [], d = [], f = w, h = e || b && x.find.TAG("*", o),
                    p = k += null == f ? 1 : Math.random() || .1, g = h.length;
                for (o && (w = t === T || t || o); c !== g && null != (r = h[c]); c++) {
                    if (b && r) {
                        for (s = 0, t || r.ownerDocument === T || (C(r), n = !$); a = m[s++];) if (a(r, t || T, n)) {
                            i.push(r);
                            break
                        }
                        o && (k = p)
                    }
                    y && ((r = !a && r) && l--, e && u.push(r))
                }
                if (l += c, y && c !== l) {
                    for (s = 0; a = v[s++];) a(u, d, t, n);
                    if (e) {
                        if (0 < l) for (; c--;) u[c] || d[c] || (d[c] = D.call(i));
                        d = xe(d)
                    }
                    M.apply(i, d), o && !e && 0 < d.length && 1 < l + v.length && re.uniqueSort(i)
                }
                return o && (k = p, w = f), u
            }

            var y = 0 < v.length, b = 0 < m.length;
            return y ? ae(e) : e
        }

        return me.prototype = x.filters = x.pseudos, x.setFilters = new me, p = re.tokenize = function (e, t) {
            var n, i, o, r, s, a, l, c = P[e + " "];
            if (c) return t ? 0 : c.slice(0);
            for (s = e, a = [], l = x.preFilter; s;) {
                for (r in n && !(i = W.exec(s)) || (i && (s = s.slice(i[0].length) || s), a.push(o = [])), n = !1, (i = X.exec(s)) && (n = i.shift(), o.push({
                    value: n,
                    type: i[0].replace(_, " ")
                }), s = s.slice(n.length)), x.filter) !(i = Z[r].exec(s)) || l[r] && !(i = l[r](i)) || (n = i.shift(), o.push({
                    value: n,
                    type: r,
                    matches: i
                }), s = s.slice(n.length));
                if (!n) break
            }
            return t ? s.length : s ? re.error(e) : P(e, a).slice(0)
        }, f = re.compile = function (e, t) {
            var n, i = [], o = [], r = A[e + " "];
            if (!r) {
                for (n = (t = t || p(e)).length; n--;) (r = Ce(t[n]))[S] ? i.push(r) : o.push(r);
                (r = A(e, Te(o, i))).selector = e
            }
            return r
        }, g = re.select = function (e, t, n, i) {
            var o, r, s, a, l, c = "function" == typeof e && e, u = !i && p(e = c.selector || e);
            if (n = n || [], 1 === u.length) {
                if (2 < (r = u[0] = u[0].slice(0)).length && "ID" === (s = r[0]).type && 9 === t.nodeType && $ && x.relative[r[1].type]) {
                    if (!(t = (x.find.ID(s.matches[0].replace(te, d), t) || [])[0])) return n;
                    c && (t = t.parentNode), e = e.slice(r.shift().value.length)
                }
                for (o = Z.needsContext.test(e) ? 0 : r.length; o-- && (s = r[o], !x.relative[a = s.type]);) if ((l = x.find[a]) && (i = l(s.matches[0].replace(te, d), ee.test(r[0].type) && ge(t.parentNode) || t))) {
                    if (r.splice(o, 1), !(e = i.length && ve(r))) return M.apply(n, i), n;
                    break
                }
            }
            return (c || f(e, u))(i, t, !$, n, !t || ee.test(e) && ge(t.parentNode) || t), n
        }, h.sortStable = S.split("").sort(L).join("") === S, h.detectDuplicates = !!c, C(), h.sortDetached = le(function (e) {
            return 1 & e.compareDocumentPosition(T.createElement("fieldset"))
        }), le(function (e) {
            return e.innerHTML = "<a href='#'></a>", "#" === e.firstChild.getAttribute("href")
        }) || ce("type|href|height|width", function (e, t, n) {
            if (!n) return e.getAttribute(t, "type" === t.toLowerCase() ? 1 : 2)
        }), h.attributes && le(function (e) {
            return e.innerHTML = "<input/>", e.firstChild.setAttribute("value", ""), "" === e.firstChild.getAttribute("value")
        }) || ce("value", function (e, t, n) {
            if (!n && "input" === e.nodeName.toLowerCase()) return e.defaultValue
        }), le(function (e) {
            return null == e.getAttribute("disabled")
        }) || ce(I, function (e, t, n) {
            var i;
            if (!n) return !0 === e[t] ? t.toLowerCase() : (i = e.getAttributeNode(t)) && i.specified ? i.value : null
        }), re
    }(T);
    S.find = h, S.expr = h.selectors, S.expr[":"] = S.expr.pseudos, S.uniqueSort = S.unique = h.uniqueSort, S.text = h.getText, S.isXMLDoc = h.isXML, S.contains = h.contains, S.escapeSelector = h.escape;

    function p(e, t, n) {
        for (var i = [], o = void 0 !== n; (e = e[t]) && 9 !== e.nodeType;) if (1 === e.nodeType) {
            if (o && S(e).is(n)) break;
            i.push(e)
        }
        return i
    }

    function C(e, t) {
        for (var n = []; e; e = e.nextSibling) 1 === e.nodeType && e !== t && n.push(e);
        return n
    }

    var k = S.expr.match.needsContext;

    function E(e, t) {
        return e.nodeName && e.nodeName.toLowerCase() === t.toLowerCase()
    }

    var P = /^<([a-z][^\/\0>:\x20\t\r\n\f]*)[\x20\t\r\n\f]*\/?>(?:<\/\1>|)$/i;

    function A(e, n, i) {
        return b(n) ? S.grep(e, function (e, t) {
            return !!n.call(e, t, e) !== i
        }) : n.nodeType ? S.grep(e, function (e) {
            return e === n !== i
        }) : "string" != typeof n ? S.grep(e, function (e) {
            return -1 < o.call(n, e) !== i
        }) : S.filter(n, e, i)
    }

    S.filter = function (e, t, n) {
        var i = t[0];
        return n && (e = ":not(" + e + ")"), 1 === t.length && 1 === i.nodeType ? S.find.matchesSelector(i, e) ? [i] : [] : S.find.matches(e, S.grep(t, function (e) {
            return 1 === e.nodeType
        }))
    }, S.fn.extend({
        find: function (e) {
            var t, n, i = this.length, o = this;
            if ("string" != typeof e) return this.pushStack(S(e).filter(function () {
                for (t = 0; t < i; t++) if (S.contains(o[t], this)) return !0
            }));
            for (n = this.pushStack([]), t = 0; t < i; t++) S.find(e, o[t], n);
            return 1 < i ? S.uniqueSort(n) : n
        }, filter: function (e) {
            return this.pushStack(A(this, e || [], !1))
        }, not: function (e) {
            return this.pushStack(A(this, e || [], !0))
        }, is: function (e) {
            return !!A(this, "string" == typeof e && k.test(e) ? S(e) : e || [], !1).length
        }
    });
    var L, q = /^(?:\s*(<[\w\W]+>)[^>]*|#([\w-]+))$/;
    (S.fn.init = function (e, t, n) {
        var i, o;
        if (!e) return this;
        if (n = n || L, "string" != typeof e) return e.nodeType ? (this[0] = e, this.length = 1, this) : b(e) ? void 0 !== n.ready ? n.ready(e) : e(S) : S.makeArray(e, this);
        if (!(i = "<" === e[0] && ">" === e[e.length - 1] && 3 <= e.length ? [null, e, null] : q.exec(e)) || !i[1] && t) return !t || t.jquery ? (t || n).find(e) : this.constructor(t).find(e);
        if (i[1]) {
            if (t = t instanceof S ? t[0] : t, S.merge(this, S.parseHTML(i[1], t && t.nodeType ? t.ownerDocument || t : $, !0)), P.test(i[1]) && S.isPlainObject(t)) for (i in t) b(this[i]) ? this[i](t[i]) : this.attr(i, t[i]);
            return this
        }
        return (o = $.getElementById(i[2])) && (this[0] = o, this.length = 1), this
    }).prototype = S.fn, L = S($);
    var D = /^(?:parents|prev(?:Until|All))/, j = {children: !0, contents: !0, next: !0, prev: !0};

    function M(e, t) {
        for (; (e = e[t]) && 1 !== e.nodeType;) ;
        return e
    }

    S.fn.extend({
        has: function (e) {
            var t = S(e, this), n = t.length;
            return this.filter(function () {
                for (var e = 0; e < n; e++) if (S.contains(this, t[e])) return !0
            })
        }, closest: function (e, t) {
            var n, i = 0, o = this.length, r = [], s = "string" != typeof e && S(e);
            if (!k.test(e)) for (; i < o; i++) for (n = this[i]; n && n !== t; n = n.parentNode) if (n.nodeType < 11 && (s ? -1 < s.index(n) : 1 === n.nodeType && S.find.matchesSelector(n, e))) {
                r.push(n);
                break
            }
            return this.pushStack(1 < r.length ? S.uniqueSort(r) : r)
        }, index: function (e) {
            return e ? "string" == typeof e ? o.call(S(e), this[0]) : o.call(this, e.jquery ? e[0] : e) : this[0] && this[0].parentNode ? this.first().prevAll().length : -1
        }, add: function (e, t) {
            return this.pushStack(S.uniqueSort(S.merge(this.get(), S(e, t))))
        }, addBack: function (e) {
            return this.add(null == e ? this.prevObject : this.prevObject.filter(e))
        }
    }), S.each({
        parent: function (e) {
            var t = e.parentNode;
            return t && 11 !== t.nodeType ? t : null
        }, parents: function (e) {
            return p(e, "parentNode")
        }, parentsUntil: function (e, t, n) {
            return p(e, "parentNode", n)
        }, next: function (e) {
            return M(e, "nextSibling")
        }, prev: function (e) {
            return M(e, "previousSibling")
        }, nextAll: function (e) {
            return p(e, "nextSibling")
        }, prevAll: function (e) {
            return p(e, "previousSibling")
        }, nextUntil: function (e, t, n) {
            return p(e, "nextSibling", n)
        }, prevUntil: function (e, t, n) {
            return p(e, "previousSibling", n)
        }, siblings: function (e) {
            return C((e.parentNode || {}).firstChild, e)
        }, children: function (e) {
            return C(e.firstChild)
        }, contents: function (e) {
            return E(e, "iframe") ? e.contentDocument : (E(e, "template") && (e = e.content || e), S.merge([], e.childNodes))
        }
    }, function (i, o) {
        S.fn[i] = function (e, t) {
            var n = S.map(this, o, e);
            return "Until" !== i.slice(-5) && (t = e), t && "string" == typeof t && (n = S.filter(t, n)), 1 < this.length && (j[i] || S.uniqueSort(n), D.test(i) && n.reverse()), this.pushStack(n)
        }
    });
    var N = /[^\x20\t\r\n\f]+/g;

    function F(e) {
        return e
    }

    function I(e) {
        throw e
    }

    function R(e, t, n, i) {
        var o;
        try {
            e && b(o = e.promise) ? o.call(e).done(t).fail(n) : e && b(o = e.then) ? o.call(e, t, n) : t.apply(void 0, [e].slice(i))
        } catch (e) {
            n.apply(void 0, [e])
        }
    }

    S.Callbacks = function (i) {
        var e, n;
        i = "string" == typeof i ? (e = i, n = {}, S.each(e.match(N) || [], function (e, t) {
            n[t] = !0
        }), n) : S.extend({}, i);

        function o() {
            for (a = a || i.once, s = r = !0; c.length; u = -1) for (t = c.shift(); ++u < l.length;) !1 === l[u].apply(t[0], t[1]) && i.stopOnFalse && (u = l.length, t = !1);
            i.memory || (t = !1), r = !1, a && (l = t ? [] : "")
        }

        var r, t, s, a, l = [], c = [], u = -1, d = {
            add: function () {
                return l && (t && !r && (u = l.length - 1, c.push(t)), function n(e) {
                    S.each(e, function (e, t) {
                        b(t) ? i.unique && d.has(t) || l.push(t) : t && t.length && "string" !== w(t) && n(t)
                    })
                }(arguments), t && !r && o()), this
            }, remove: function () {
                return S.each(arguments, function (e, t) {
                    for (var n; -1 < (n = S.inArray(t, l, n));) l.splice(n, 1), n <= u && u--
                }), this
            }, has: function (e) {
                return e ? -1 < S.inArray(e, l) : 0 < l.length
            }, empty: function () {
                return l = l && [], this
            }, disable: function () {
                return a = c = [], l = t = "", this
            }, disabled: function () {
                return !l
            }, lock: function () {
                return a = c = [], t || r || (l = t = ""), this
            }, locked: function () {
                return !!a
            }, fireWith: function (e, t) {
                return a || (t = [e, (t = t || []).slice ? t.slice() : t], c.push(t), r || o()), this
            }, fire: function () {
                return d.fireWith(this, arguments), this
            }, fired: function () {
                return !!s
            }
        };
        return d
    }, S.extend({
        Deferred: function (e) {
            var r = [["notify", "progress", S.Callbacks("memory"), S.Callbacks("memory"), 2], ["resolve", "done", S.Callbacks("once memory"), S.Callbacks("once memory"), 0, "resolved"], ["reject", "fail", S.Callbacks("once memory"), S.Callbacks("once memory"), 1, "rejected"]],
                o = "pending", s = {
                    state: function () {
                        return o
                    }, always: function () {
                        return a.done(arguments).fail(arguments), this
                    }, catch: function (e) {
                        return s.then(null, e)
                    }, pipe: function () {
                        var o = arguments;
                        return S.Deferred(function (i) {
                            S.each(r, function (e, t) {
                                var n = b(o[t[4]]) && o[t[4]];
                                a[t[1]](function () {
                                    var e = n && n.apply(this, arguments);
                                    e && b(e.promise) ? e.promise().progress(i.notify).done(i.resolve).fail(i.reject) : i[t[0] + "With"](this, n ? [e] : arguments)
                                })
                            }), o = null
                        }).promise()
                    }, then: function (t, n, i) {
                        var l = 0;

                        function c(o, r, s, a) {
                            return function () {
                                function e() {
                                    var e, t;
                                    if (!(o < l)) {
                                        if ((e = s.apply(n, i)) === r.promise()) throw new TypeError("Thenable self-resolution");
                                        t = e && ("object" == typeof e || "function" == typeof e) && e.then, b(t) ? a ? t.call(e, c(l, r, F, a), c(l, r, I, a)) : (l++, t.call(e, c(l, r, F, a), c(l, r, I, a), c(l, r, F, r.notifyWith))) : (s !== F && (n = void 0, i = [e]), (a || r.resolveWith)(n, i))
                                    }
                                }

                                var n = this, i = arguments, t = a ? e : function () {
                                    try {
                                        e()
                                    } catch (e) {
                                        S.Deferred.exceptionHook && S.Deferred.exceptionHook(e, t.stackTrace), l <= o + 1 && (s !== I && (n = void 0, i = [e]), r.rejectWith(n, i))
                                    }
                                };
                                o ? t() : (S.Deferred.getStackHook && (t.stackTrace = S.Deferred.getStackHook()), T.setTimeout(t))
                            }
                        }

                        return S.Deferred(function (e) {
                            r[0][3].add(c(0, e, b(i) ? i : F, e.notifyWith)), r[1][3].add(c(0, e, b(t) ? t : F)), r[2][3].add(c(0, e, b(n) ? n : I))
                        }).promise()
                    }, promise: function (e) {
                        return null != e ? S.extend(e, s) : s
                    }
                }, a = {};
            return S.each(r, function (e, t) {
                var n = t[2], i = t[5];
                s[t[1]] = n.add, i && n.add(function () {
                    o = i
                }, r[3 - e][2].disable, r[3 - e][3].disable, r[0][2].lock, r[0][3].lock), n.add(t[3].fire), a[t[0]] = function () {
                    return a[t[0] + "With"](this === a ? void 0 : this, arguments), this
                }, a[t[0] + "With"] = n.fireWith
            }), s.promise(a), e && e.call(a, a), a
        }, when: function (e) {
            function t(t) {
                return function (e) {
                    o[t] = this, r[t] = 1 < arguments.length ? a.call(arguments) : e, --n || s.resolveWith(o, r)
                }
            }

            var n = arguments.length, i = n, o = Array(i), r = a.call(arguments), s = S.Deferred();
            if (n <= 1 && (R(e, s.done(t(i)).resolve, s.reject, !n), "pending" === s.state() || b(r[i] && r[i].then))) return s.then();
            for (; i--;) R(r[i], t(i), s.reject);
            return s.promise()
        }
    });
    var H = /^(Eval|Internal|Range|Reference|Syntax|Type|URI)Error$/;
    S.Deferred.exceptionHook = function (e, t) {
        T.console && T.console.warn && e && H.test(e.name) && T.console.warn("jQuery.Deferred exception: " + e.message, e.stack, t)
    }, S.readyException = function (e) {
        T.setTimeout(function () {
            throw e
        })
    };
    var O = S.Deferred();

    function z() {
        $.removeEventListener("DOMContentLoaded", z), T.removeEventListener("load", z), S.ready()
    }

    S.fn.ready = function (e) {
        return O.then(e).catch(function (e) {
            S.readyException(e)
        }), this
    }, S.extend({
        isReady: !1, readyWait: 1, ready: function (e) {
            (!0 === e ? --S.readyWait : S.isReady) || ((S.isReady = !0) !== e && 0 < --S.readyWait || O.resolveWith($, [S]))
        }
    }), S.ready.then = O.then, "complete" === $.readyState || "loading" !== $.readyState && !$.documentElement.doScroll ? T.setTimeout(S.ready) : ($.addEventListener("DOMContentLoaded", z), T.addEventListener("load", z));
    var B = function (e, t, n, i, o, r, s) {
        var a = 0, l = e.length, c = null == n;
        if ("object" === w(n)) for (a in o = !0, n) B(e, t, a, n[a], !0, r, s); else if (void 0 !== i && (o = !0, b(i) || (s = !0), c && (t = s ? (t.call(e, i), null) : (c = t, function (e, t, n) {
            return c.call(S(e), n)
        })), t)) for (; a < l; a++) t(e[a], n, s ? i : i.call(e[a], a, t(e[a], n)));
        return o ? e : c ? t.call(e) : l ? t(e[0], n) : r
    }, _ = /^-ms-/, W = /-([a-z])/g;

    function X(e, t) {
        return t.toUpperCase()
    }

    function Y(e) {
        return e.replace(_, "ms-").replace(W, X)
    }

    function U(e) {
        return 1 === e.nodeType || 9 === e.nodeType || !+e.nodeType
    }

    function V() {
        this.expando = S.expando + V.uid++
    }

    V.uid = 1, V.prototype = {
        cache: function (e) {
            var t = e[this.expando];
            return t || (t = {}, U(e) && (e.nodeType ? e[this.expando] = t : Object.defineProperty(e, this.expando, {
                value: t,
                configurable: !0
            }))), t
        }, set: function (e, t, n) {
            var i, o = this.cache(e);
            if ("string" == typeof t) o[Y(t)] = n; else for (i in t) o[Y(i)] = t[i];
            return o
        }, get: function (e, t) {
            return void 0 === t ? this.cache(e) : e[this.expando] && e[this.expando][Y(t)]
        }, access: function (e, t, n) {
            return void 0 === t || t && "string" == typeof t && void 0 === n ? this.get(e, t) : (this.set(e, t, n), void 0 !== n ? n : t)
        }, remove: function (e, t) {
            var n, i = e[this.expando];
            if (void 0 !== i) {
                if (void 0 !== t) {
                    n = (t = Array.isArray(t) ? t.map(Y) : (t = Y(t)) in i ? [t] : t.match(N) || []).length;
                    for (; n--;) delete i[t[n]]
                }
                void 0 !== t && !S.isEmptyObject(i) || (e.nodeType ? e[this.expando] = void 0 : delete e[this.expando])
            }
        }, hasData: function (e) {
            var t = e[this.expando];
            return void 0 !== t && !S.isEmptyObject(t)
        }
    };
    var Z = new V, Q = new V, G = /^(?:\{[\w\W]*\}|\[[\w\W]*\])$/, K = /[A-Z]/g;

    function J(e, t, n) {
        var i, o;
        if (void 0 === n && 1 === e.nodeType) if (i = "data-" + t.replace(K, "-$&").toLowerCase(), "string" == typeof (n = e.getAttribute(i))) {
            try {
                n = "true" === (o = n) || "false" !== o && ("null" === o ? null : o === +o + "" ? +o : G.test(o) ? JSON.parse(o) : o)
            } catch (e) {
            }
            Q.set(e, t, n)
        } else n = void 0;
        return n
    }

    S.extend({
        hasData: function (e) {
            return Q.hasData(e) || Z.hasData(e)
        }, data: function (e, t, n) {
            return Q.access(e, t, n)
        }, removeData: function (e, t) {
            Q.remove(e, t)
        }, _data: function (e, t, n) {
            return Z.access(e, t, n)
        }, _removeData: function (e, t) {
            Z.remove(e, t)
        }
    }), S.fn.extend({
        data: function (n, e) {
            var t, i, o, r = this[0], s = r && r.attributes;
            if (void 0 !== n) return "object" == typeof n ? this.each(function () {
                Q.set(this, n)
            }) : B(this, function (e) {
                var t;
                if (r && void 0 === e) {
                    if (void 0 !== (t = Q.get(r, n))) return t;
                    if (void 0 !== (t = J(r, n))) return t
                } else this.each(function () {
                    Q.set(this, n, e)
                })
            }, null, e, 1 < arguments.length, null, !0);
            if (this.length && (o = Q.get(r), 1 === r.nodeType && !Z.get(r, "hasDataAttrs"))) {
                for (t = s.length; t--;) s[t] && 0 === (i = s[t].name).indexOf("data-") && (i = Y(i.slice(5)), J(r, i, o[i]));
                Z.set(r, "hasDataAttrs", !0)
            }
            return o
        }, removeData: function (e) {
            return this.each(function () {
                Q.remove(this, e)
            })
        }
    }), S.extend({
        queue: function (e, t, n) {
            var i;
            if (e) return t = (t || "fx") + "queue", i = Z.get(e, t), n && (!i || Array.isArray(n) ? i = Z.access(e, t, S.makeArray(n)) : i.push(n)), i || []
        }, dequeue: function (e, t) {
            t = t || "fx";
            var n = S.queue(e, t), i = n.length, o = n.shift(), r = S._queueHooks(e, t);
            "inprogress" === o && (o = n.shift(), i--), o && ("fx" === t && n.unshift("inprogress"), delete r.stop, o.call(e, function () {
                S.dequeue(e, t)
            }, r)), !i && r && r.empty.fire()
        }, _queueHooks: function (e, t) {
            var n = t + "queueHooks";
            return Z.get(e, n) || Z.access(e, n, {
                empty: S.Callbacks("once memory").add(function () {
                    Z.remove(e, [t + "queue", n])
                })
            })
        }
    }), S.fn.extend({
        queue: function (t, n) {
            var e = 2;
            return "string" != typeof t && (n = t, t = "fx", e--), arguments.length < e ? S.queue(this[0], t) : void 0 === n ? this : this.each(function () {
                var e = S.queue(this, t, n);
                S._queueHooks(this, t), "fx" === t && "inprogress" !== e[0] && S.dequeue(this, t)
            })
        }, dequeue: function (e) {
            return this.each(function () {
                S.dequeue(this, e)
            })
        }, clearQueue: function (e) {
            return this.queue(e || "fx", [])
        }, promise: function (e, t) {
            function n() {
                --o || r.resolveWith(s, [s])
            }

            var i, o = 1, r = S.Deferred(), s = this, a = this.length;
            for ("string" != typeof e && (t = e, e = void 0), e = e || "fx"; a--;) (i = Z.get(s[a], e + "queueHooks")) && i.empty && (o++, i.empty.add(n));
            return n(), r.promise(t)
        }
    });

    function ee(e, t, n, i) {
        var o, r, s = {};
        for (r in t) s[r] = e.style[r], e.style[r] = t[r];
        for (r in o = n.apply(e, i || []), t) e.style[r] = s[r];
        return o
    }

    var te = /[+-]?(?:\d*\.|)\d+(?:[eE][+-]?\d+|)/.source, ne = new RegExp("^(?:([+-])=|)(" + te + ")([a-z%]*)$", "i"),
        ie = ["Top", "Right", "Bottom", "Left"], oe = function (e, t) {
            return "none" === (e = t || e).style.display || "" === e.style.display && S.contains(e.ownerDocument, e) && "none" === S.css(e, "display")
        };

    function re(e, t, n, i) {
        var o, r, s = 20, a = i ? function () {
                return i.cur()
            } : function () {
                return S.css(e, t, "")
            }, l = a(), c = n && n[3] || (S.cssNumber[t] ? "" : "px"),
            u = (S.cssNumber[t] || "px" !== c && +l) && ne.exec(S.css(e, t));
        if (u && u[3] !== c) {
            for (l /= 2, c = c || u[3], u = +l || 1; s--;) S.style(e, t, u + c), (1 - r) * (1 - (r = a() / l || .5)) <= 0 && (s = 0), u /= r;
            u *= 2, S.style(e, t, u + c), n = n || []
        }
        return n && (u = +u || +l || 0, o = n[1] ? u + (n[1] + 1) * n[2] : +n[2], i && (i.unit = c, i.start = u, i.end = o)), o
    }

    var se = {};

    function ae(e, t) {
        for (var n, i, o = [], r = 0, s = e.length; r < s; r++) (i = e[r]).style && (n = i.style.display, t ? ("none" === n && (o[r] = Z.get(i, "display") || null, o[r] || (i.style.display = "")), "" === i.style.display && oe(i) && (o[r] = (d = c = l = void 0, c = (a = i).ownerDocument, u = a.nodeName, (d = se[u]) || (l = c.body.appendChild(c.createElement(u)), d = S.css(l, "display"), l.parentNode.removeChild(l), "none" === d && (d = "block"), se[u] = d)))) : "none" !== n && (o[r] = "none", Z.set(i, "display", n)));
        var a, l, c, u, d;
        for (r = 0; r < s; r++) null != o[r] && (e[r].style.display = o[r]);
        return e
    }

    S.fn.extend({
        show: function () {
            return ae(this, !0)
        }, hide: function () {
            return ae(this)
        }, toggle: function (e) {
            return "boolean" == typeof e ? e ? this.show() : this.hide() : this.each(function () {
                oe(this) ? S(this).show() : S(this).hide()
            })
        }
    });
    var le = /^(?:checkbox|radio)$/i, ce = /<([a-z][^\/\0>\x20\t\r\n\f]+)/i, ue = /^$|^module$|\/(?:java|ecma)script/i,
        de = {
            option: [1, "<select multiple='multiple'>", "</select>"],
            thead: [1, "<table>", "</table>"],
            col: [2, "<table><colgroup>", "</colgroup></table>"],
            tr: [2, "<table><tbody>", "</tbody></table>"],
            td: [3, "<table><tbody><tr>", "</tr></tbody></table>"],
            _default: [0, "", ""]
        };

    function fe(e, t) {
        var n;
        return n = void 0 !== e.getElementsByTagName ? e.getElementsByTagName(t || "*") : void 0 !== e.querySelectorAll ? e.querySelectorAll(t || "*") : [], void 0 === t || t && E(e, t) ? S.merge([e], n) : n
    }

    function he(e, t) {
        for (var n = 0, i = e.length; n < i; n++) Z.set(e[n], "globalEval", !t || Z.get(t[n], "globalEval"))
    }

    de.optgroup = de.option, de.tbody = de.tfoot = de.colgroup = de.caption = de.thead, de.th = de.td;
    var pe, ge, me = /<|&#?\w+;/;

    function ve(e, t, n, i, o) {
        for (var r, s, a, l, c, u, d = t.createDocumentFragment(), f = [], h = 0, p = e.length; h < p; h++) if ((r = e[h]) || 0 === r) if ("object" === w(r)) S.merge(f, r.nodeType ? [r] : r); else if (me.test(r)) {
            for (s = s || d.appendChild(t.createElement("div")), a = (ce.exec(r) || ["", ""])[1].toLowerCase(), l = de[a] || de._default, s.innerHTML = l[1] + S.htmlPrefilter(r) + l[2], u = l[0]; u--;) s = s.lastChild;
            S.merge(f, s.childNodes), (s = d.firstChild).textContent = ""
        } else f.push(t.createTextNode(r));
        for (d.textContent = "", h = 0; r = f[h++];) if (i && -1 < S.inArray(r, i)) o && o.push(r); else if (c = S.contains(r.ownerDocument, r), s = fe(d.appendChild(r), "script"), c && he(s), n) for (u = 0; r = s[u++];) ue.test(r.type || "") && n.push(r);
        return d
    }

    pe = $.createDocumentFragment().appendChild($.createElement("div")), (ge = $.createElement("input")).setAttribute("type", "radio"), ge.setAttribute("checked", "checked"), ge.setAttribute("name", "t"), pe.appendChild(ge), y.checkClone = pe.cloneNode(!0).cloneNode(!0).lastChild.checked, pe.innerHTML = "<textarea>x</textarea>", y.noCloneChecked = !!pe.cloneNode(!0).lastChild.defaultValue;
    var ye = $.documentElement, be = /^key/, xe = /^(?:mouse|pointer|contextmenu|drag|drop)|click/,
        we = /^([^.]*)(?:\.(.+)|)/;

    function Ce() {
        return !0
    }

    function Te() {
        return !1
    }

    function $e() {
        try {
            return $.activeElement
        } catch (e) {
        }
    }

    function Se(e, t, n, i, o, r) {
        var s, a;
        if ("object" == typeof t) {
            for (a in "string" != typeof n && (i = i || n, n = void 0), t) Se(e, a, n, i, t[a], r);
            return e
        }
        if (null == i && null == o ? (o = n, i = n = void 0) : null == o && ("string" == typeof n ? (o = i, i = void 0) : (o = i, i = n, n = void 0)), !1 === o) o = Te; else if (!o) return e;
        return 1 === r && (s = o, (o = function (e) {
            return S().off(e), s.apply(this, arguments)
        }).guid = s.guid || (s.guid = S.guid++)), e.each(function () {
            S.event.add(this, t, o, i, n)
        })
    }

    S.event = {
        global: {}, add: function (t, e, n, i, o) {
            var r, s, a, l, c, u, d, f, h, p, g, m = Z.get(t);
            if (m) for (n.handler && (n = (r = n).handler, o = r.selector), o && S.find.matchesSelector(ye, o), n.guid || (n.guid = S.guid++), (l = m.events) || (l = m.events = {}), (s = m.handle) || (s = m.handle = function (e) {
                return void 0 !== S && S.event.triggered !== e.type ? S.event.dispatch.apply(t, arguments) : void 0
            }), c = (e = (e || "").match(N) || [""]).length; c--;) h = g = (a = we.exec(e[c]) || [])[1], p = (a[2] || "").split(".").sort(), h && (d = S.event.special[h] || {}, h = (o ? d.delegateType : d.bindType) || h, d = S.event.special[h] || {}, u = S.extend({
                type: h,
                origType: g,
                data: i,
                handler: n,
                guid: n.guid,
                selector: o,
                needsContext: o && S.expr.match.needsContext.test(o),
                namespace: p.join(".")
            }, r), (f = l[h]) || ((f = l[h] = []).delegateCount = 0, d.setup && !1 !== d.setup.call(t, i, p, s) || t.addEventListener && t.addEventListener(h, s)), d.add && (d.add.call(t, u), u.handler.guid || (u.handler.guid = n.guid)), o ? f.splice(f.delegateCount++, 0, u) : f.push(u), S.event.global[h] = !0)
        }, remove: function (e, t, n, i, o) {
            var r, s, a, l, c, u, d, f, h, p, g, m = Z.hasData(e) && Z.get(e);
            if (m && (l = m.events)) {
                for (c = (t = (t || "").match(N) || [""]).length; c--;) if (h = g = (a = we.exec(t[c]) || [])[1], p = (a[2] || "").split(".").sort(), h) {
                    for (d = S.event.special[h] || {}, f = l[h = (i ? d.delegateType : d.bindType) || h] || [], a = a[2] && new RegExp("(^|\\.)" + p.join("\\.(?:.*\\.|)") + "(\\.|$)"), s = r = f.length; r--;) u = f[r], !o && g !== u.origType || n && n.guid !== u.guid || a && !a.test(u.namespace) || i && i !== u.selector && ("**" !== i || !u.selector) || (f.splice(r, 1), u.selector && f.delegateCount--, d.remove && d.remove.call(e, u));
                    s && !f.length && (d.teardown && !1 !== d.teardown.call(e, p, m.handle) || S.removeEvent(e, h, m.handle), delete l[h])
                } else for (h in l) S.event.remove(e, h + t[c], n, i, !0);
                S.isEmptyObject(l) && Z.remove(e, "handle events")
            }
        }, dispatch: function (e) {
            var t, n, i, o, r, s, a = S.event.fix(e), l = new Array(arguments.length),
                c = (Z.get(this, "events") || {})[a.type] || [], u = S.event.special[a.type] || {};
            for (l[0] = a, t = 1; t < arguments.length; t++) l[t] = arguments[t];
            if (a.delegateTarget = this, !u.preDispatch || !1 !== u.preDispatch.call(this, a)) {
                for (s = S.event.handlers.call(this, a, c), t = 0; (o = s[t++]) && !a.isPropagationStopped();) for (a.currentTarget = o.elem, n = 0; (r = o.handlers[n++]) && !a.isImmediatePropagationStopped();) a.rnamespace && !a.rnamespace.test(r.namespace) || (a.handleObj = r, a.data = r.data, void 0 !== (i = ((S.event.special[r.origType] || {}).handle || r.handler).apply(o.elem, l)) && !1 === (a.result = i) && (a.preventDefault(), a.stopPropagation()));
                return u.postDispatch && u.postDispatch.call(this, a), a.result
            }
        }, handlers: function (e, t) {
            var n, i, o, r, s, a = [], l = t.delegateCount, c = e.target;
            if (l && c.nodeType && !("click" === e.type && 1 <= e.button)) for (; c !== this; c = c.parentNode || this) if (1 === c.nodeType && ("click" !== e.type || !0 !== c.disabled)) {
                for (r = [], s = {}, n = 0; n < l; n++) void 0 === s[o = (i = t[n]).selector + " "] && (s[o] = i.needsContext ? -1 < S(o, this).index(c) : S.find(o, this, null, [c]).length), s[o] && r.push(i);
                r.length && a.push({elem: c, handlers: r})
            }
            return c = this, l < t.length && a.push({elem: c, handlers: t.slice(l)}), a
        }, addProp: function (t, e) {
            Object.defineProperty(S.Event.prototype, t, {
                enumerable: !0, configurable: !0, get: b(e) ? function () {
                    if (this.originalEvent) return e(this.originalEvent)
                } : function () {
                    if (this.originalEvent) return this.originalEvent[t]
                }, set: function (e) {
                    Object.defineProperty(this, t, {enumerable: !0, configurable: !0, writable: !0, value: e})
                }
            })
        }, fix: function (e) {
            return e[S.expando] ? e : new S.Event(e)
        }, special: {
            load: {noBubble: !0}, focus: {
                trigger: function () {
                    if (this !== $e() && this.focus) return this.focus(), !1
                }, delegateType: "focusin"
            }, blur: {
                trigger: function () {
                    if (this === $e() && this.blur) return this.blur(), !1
                }, delegateType: "focusout"
            }, click: {
                trigger: function () {
                    if ("checkbox" === this.type && this.click && E(this, "input")) return this.click(), !1
                }, _default: function (e) {
                    return E(e.target, "a")
                }
            }, beforeunload: {
                postDispatch: function (e) {
                    void 0 !== e.result && e.originalEvent && (e.originalEvent.returnValue = e.result)
                }
            }
        }
    }, S.removeEvent = function (e, t, n) {
        e.removeEventListener && e.removeEventListener(t, n)
    }, S.Event = function (e, t) {
        if (!(this instanceof S.Event)) return new S.Event(e, t);
        e && e.type ? (this.originalEvent = e, this.type = e.type, this.isDefaultPrevented = e.defaultPrevented || void 0 === e.defaultPrevented && !1 === e.returnValue ? Ce : Te, this.target = e.target && 3 === e.target.nodeType ? e.target.parentNode : e.target, this.currentTarget = e.currentTarget, this.relatedTarget = e.relatedTarget) : this.type = e, t && S.extend(this, t), this.timeStamp = e && e.timeStamp || Date.now(), this[S.expando] = !0
    }, S.Event.prototype = {
        constructor: S.Event,
        isDefaultPrevented: Te,
        isPropagationStopped: Te,
        isImmediatePropagationStopped: Te,
        isSimulated: !1,
        preventDefault: function () {
            var e = this.originalEvent;
            this.isDefaultPrevented = Ce, e && !this.isSimulated && e.preventDefault()
        },
        stopPropagation: function () {
            var e = this.originalEvent;
            this.isPropagationStopped = Ce, e && !this.isSimulated && e.stopPropagation()
        },
        stopImmediatePropagation: function () {
            var e = this.originalEvent;
            this.isImmediatePropagationStopped = Ce, e && !this.isSimulated && e.stopImmediatePropagation(), this.stopPropagation()
        }
    }, S.each({
        altKey: !0,
        bubbles: !0,
        cancelable: !0,
        changedTouches: !0,
        ctrlKey: !0,
        detail: !0,
        eventPhase: !0,
        metaKey: !0,
        pageX: !0,
        pageY: !0,
        shiftKey: !0,
        view: !0,
        char: !0,
        charCode: !0,
        key: !0,
        keyCode: !0,
        button: !0,
        buttons: !0,
        clientX: !0,
        clientY: !0,
        offsetX: !0,
        offsetY: !0,
        pointerId: !0,
        pointerType: !0,
        screenX: !0,
        screenY: !0,
        targetTouches: !0,
        toElement: !0,
        touches: !0,
        which: function (e) {
            var t = e.button;
            return null == e.which && be.test(e.type) ? null != e.charCode ? e.charCode : e.keyCode : !e.which && void 0 !== t && xe.test(e.type) ? 1 & t ? 1 : 2 & t ? 3 : 4 & t ? 2 : 0 : e.which
        }
    }, S.event.addProp), S.each({
        mouseenter: "mouseover",
        mouseleave: "mouseout",
        pointerenter: "pointerover",
        pointerleave: "pointerout"
    }, function (e, o) {
        S.event.special[e] = {
            delegateType: o, bindType: o, handle: function (e) {
                var t, n = e.relatedTarget, i = e.handleObj;
                return n && (n === this || S.contains(this, n)) || (e.type = i.origType, t = i.handler.apply(this, arguments), e.type = o), t
            }
        }
    }), S.fn.extend({
        on: function (e, t, n, i) {
            return Se(this, e, t, n, i)
        }, one: function (e, t, n, i) {
            return Se(this, e, t, n, i, 1)
        }, off: function (e, t, n) {
            var i, o;
            if (e && e.preventDefault && e.handleObj) return i = e.handleObj, S(e.delegateTarget).off(i.namespace ? i.origType + "." + i.namespace : i.origType, i.selector, i.handler), this;
            if ("object" != typeof e) return !1 !== t && "function" != typeof t || (n = t, t = void 0), !1 === n && (n = Te), this.each(function () {
                S.event.remove(this, e, n, t)
            });
            for (o in e) this.off(o, t, e[o]);
            return this
        }
    });
    var ke = /<(?!area|br|col|embed|hr|img|input|link|meta|param)(([a-z][^\/\0>\x20\t\r\n\f]*)[^>]*)\/>/gi,
        Ee = /<script|<style|<link/i, Pe = /checked\s*(?:[^=]|=\s*.checked.)/i,
        Ae = /^\s*<!(?:\[CDATA\[|--)|(?:\]\]|--)>\s*$/g;

    function Le(e, t) {
        return E(e, "table") && E(11 !== t.nodeType ? t : t.firstChild, "tr") && S(e).children("tbody")[0] || e
    }

    function qe(e) {
        return e.type = (null !== e.getAttribute("type")) + "/" + e.type, e
    }

    function De(e) {
        return "true/" === (e.type || "").slice(0, 5) ? e.type = e.type.slice(5) : e.removeAttribute("type"), e
    }

    function je(e, t) {
        var n, i, o, r, s, a, l, c;
        if (1 === t.nodeType) {
            if (Z.hasData(e) && (r = Z.access(e), s = Z.set(t, r), c = r.events)) for (o in delete s.handle, s.events = {}, c) for (n = 0, i = c[o].length; n < i; n++) S.event.add(t, o, c[o][n]);
            Q.hasData(e) && (a = Q.access(e), l = S.extend({}, a), Q.set(t, l))
        }
    }

    function Me(n, i, o, r) {
        i = m.apply([], i);
        var e, t, s, a, l, c, u = 0, d = n.length, f = d - 1, h = i[0], p = b(h);
        if (p || 1 < d && "string" == typeof h && !y.checkClone && Pe.test(h)) return n.each(function (e) {
            var t = n.eq(e);
            p && (i[0] = h.call(this, e, t.html())), Me(t, i, o, r)
        });
        if (d && (t = (e = ve(i, n[0].ownerDocument, !1, n, r)).firstChild, 1 === e.childNodes.length && (e = t), t || r)) {
            for (a = (s = S.map(fe(e, "script"), qe)).length; u < d; u++) l = e, u !== f && (l = S.clone(l, !0, !0), a && S.merge(s, fe(l, "script"))), o.call(n[u], l, u);
            if (a) for (c = s[s.length - 1].ownerDocument, S.map(s, De), u = 0; u < a; u++) l = s[u], ue.test(l.type || "") && !Z.access(l, "globalEval") && S.contains(c, l) && (l.src && "module" !== (l.type || "").toLowerCase() ? S._evalUrl && S._evalUrl(l.src) : x(l.textContent.replace(Ae, ""), c, l))
        }
        return n
    }

    function Ne(e, t, n) {
        for (var i, o = t ? S.filter(t, e) : e, r = 0; null != (i = o[r]); r++) n || 1 !== i.nodeType || S.cleanData(fe(i)), i.parentNode && (n && S.contains(i.ownerDocument, i) && he(fe(i, "script")), i.parentNode.removeChild(i));
        return e
    }

    S.extend({
        htmlPrefilter: function (e) {
            return e.replace(ke, "<$1></$2>")
        }, clone: function (e, t, n) {
            var i, o, r, s, a, l, c, u = e.cloneNode(!0), d = S.contains(e.ownerDocument, e);
            if (!(y.noCloneChecked || 1 !== e.nodeType && 11 !== e.nodeType || S.isXMLDoc(e))) for (s = fe(u), i = 0, o = (r = fe(e)).length; i < o; i++) a = r[i], l = s[i], "input" === (c = l.nodeName.toLowerCase()) && le.test(a.type) ? l.checked = a.checked : "input" !== c && "textarea" !== c || (l.defaultValue = a.defaultValue);
            if (t) if (n) for (r = r || fe(e), s = s || fe(u), i = 0, o = r.length; i < o; i++) je(r[i], s[i]); else je(e, u);
            return 0 < (s = fe(u, "script")).length && he(s, !d && fe(e, "script")), u
        }, cleanData: function (e) {
            for (var t, n, i, o = S.event.special, r = 0; void 0 !== (n = e[r]); r++) if (U(n)) {
                if (t = n[Z.expando]) {
                    if (t.events) for (i in t.events) o[i] ? S.event.remove(n, i) : S.removeEvent(n, i, t.handle);
                    n[Z.expando] = void 0
                }
                n[Q.expando] && (n[Q.expando] = void 0)
            }
        }
    }), S.fn.extend({
        detach: function (e) {
            return Ne(this, e, !0)
        }, remove: function (e) {
            return Ne(this, e)
        }, text: function (e) {
            return B(this, function (e) {
                return void 0 === e ? S.text(this) : this.empty().each(function () {
                    1 !== this.nodeType && 11 !== this.nodeType && 9 !== this.nodeType || (this.textContent = e)
                })
            }, null, e, arguments.length)
        }, append: function () {
            return Me(this, arguments, function (e) {
                1 !== this.nodeType && 11 !== this.nodeType && 9 !== this.nodeType || Le(this, e).appendChild(e)
            })
        }, prepend: function () {
            return Me(this, arguments, function (e) {
                if (1 === this.nodeType || 11 === this.nodeType || 9 === this.nodeType) {
                    var t = Le(this, e);
                    t.insertBefore(e, t.firstChild)
                }
            })
        }, before: function () {
            return Me(this, arguments, function (e) {
                this.parentNode && this.parentNode.insertBefore(e, this)
            })
        }, after: function () {
            return Me(this, arguments, function (e) {
                this.parentNode && this.parentNode.insertBefore(e, this.nextSibling)
            })
        }, empty: function () {
            for (var e, t = 0; null != (e = this[t]); t++) 1 === e.nodeType && (S.cleanData(fe(e, !1)), e.textContent = "");
            return this
        }, clone: function (e, t) {
            return e = null != e && e, t = null == t ? e : t, this.map(function () {
                return S.clone(this, e, t)
            })
        }, html: function (e) {
            return B(this, function (e) {
                var t = this[0] || {}, n = 0, i = this.length;
                if (void 0 === e && 1 === t.nodeType) return t.innerHTML;
                if ("string" == typeof e && !Ee.test(e) && !de[(ce.exec(e) || ["", ""])[1].toLowerCase()]) {
                    e = S.htmlPrefilter(e);
                    try {
                        for (; n < i; n++) 1 === (t = this[n] || {}).nodeType && (S.cleanData(fe(t, !1)), t.innerHTML = e);
                        t = 0
                    } catch (e) {
                    }
                }
                t && this.empty().append(e)
            }, null, e, arguments.length)
        }, replaceWith: function () {
            var n = [];
            return Me(this, arguments, function (e) {
                var t = this.parentNode;
                S.inArray(this, n) < 0 && (S.cleanData(fe(this)), t && t.replaceChild(e, this))
            }, n)
        }
    }), S.each({
        appendTo: "append",
        prependTo: "prepend",
        insertBefore: "before",
        insertAfter: "after",
        replaceAll: "replaceWith"
    }, function (e, s) {
        S.fn[e] = function (e) {
            for (var t, n = [], i = S(e), o = i.length - 1, r = 0; r <= o; r++) t = r === o ? this : this.clone(!0), S(i[r])[s](t), l.apply(n, t.get());
            return this.pushStack(n)
        }
    });
    var Fe, Ie, Re, He, Oe, ze, Be, _e = new RegExp("^(" + te + ")(?!px)[a-z%]+$", "i"), We = function (e) {
        var t = e.ownerDocument.defaultView;
        return t && t.opener || (t = T), t.getComputedStyle(e)
    }, Xe = new RegExp(ie.join("|"), "i");

    function Ye() {
        if (Be) {
            ze.style.cssText = "position:absolute;left:-11111px;width:60px;margin-top:1px;padding:0;border:0", Be.style.cssText = "position:relative;display:block;box-sizing:border-box;overflow:scroll;margin:auto;border:1px;padding:1px;width:60%;top:1%", ye.appendChild(ze).appendChild(Be);
            var e = T.getComputedStyle(Be);
            Fe = "1%" !== e.top, Oe = 12 === Ue(e.marginLeft), Be.style.right = "60%", He = 36 === Ue(e.right), Ie = 36 === Ue(e.width), Be.style.position = "absolute", Re = 36 === Be.offsetWidth || "absolute", ye.removeChild(ze), Be = null
        }
    }

    function Ue(e) {
        return Math.round(parseFloat(e))
    }

    function Ve(e, t, n) {
        var i, o, r, s, a = e.style;
        return (n = n || We(e)) && ("" !== (s = n.getPropertyValue(t) || n[t]) || S.contains(e.ownerDocument, e) || (s = S.style(e, t)), !y.pixelBoxStyles() && _e.test(s) && Xe.test(t) && (i = a.width, o = a.minWidth, r = a.maxWidth, a.minWidth = a.maxWidth = a.width = s, s = n.width, a.width = i, a.minWidth = o, a.maxWidth = r)), void 0 !== s ? s + "" : s
    }

    function Ze(e, t) {
        return {
            get: function () {
                if (!e()) return (this.get = t).apply(this, arguments);
                delete this.get
            }
        }
    }

    ze = $.createElement("div"), (Be = $.createElement("div")).style && (Be.style.backgroundClip = "content-box", Be.cloneNode(!0).style.backgroundClip = "", y.clearCloneStyle = "content-box" === Be.style.backgroundClip, S.extend(y, {
        boxSizingReliable: function () {
            return Ye(), Ie
        }, pixelBoxStyles: function () {
            return Ye(), He
        }, pixelPosition: function () {
            return Ye(), Fe
        }, reliableMarginLeft: function () {
            return Ye(), Oe
        }, scrollboxSize: function () {
            return Ye(), Re
        }
    }));
    var Qe = /^(none|table(?!-c[ea]).+)/, Ge = /^--/,
        Ke = {position: "absolute", visibility: "hidden", display: "block"},
        Je = {letterSpacing: "0", fontWeight: "400"}, et = ["Webkit", "Moz", "ms"], tt = $.createElement("div").style;

    function nt(e) {
        var t = S.cssProps[e];
        return t = t || (S.cssProps[e] = function (e) {
            if (e in tt) return e;
            for (var t = e[0].toUpperCase() + e.slice(1), n = et.length; n--;) if ((e = et[n] + t) in tt) return e
        }(e) || e)
    }

    function it(e, t, n) {
        var i = ne.exec(t);
        return i ? Math.max(0, i[2] - (n || 0)) + (i[3] || "px") : t
    }

    function ot(e, t, n, i, o, r) {
        var s = "width" === t ? 1 : 0, a = 0, l = 0;
        if (n === (i ? "border" : "content")) return 0;
        for (; s < 4; s += 2) "margin" === n && (l += S.css(e, n + ie[s], !0, o)), i ? ("content" === n && (l -= S.css(e, "padding" + ie[s], !0, o)), "margin" !== n && (l -= S.css(e, "border" + ie[s] + "Width", !0, o))) : (l += S.css(e, "padding" + ie[s], !0, o), "padding" !== n ? l += S.css(e, "border" + ie[s] + "Width", !0, o) : a += S.css(e, "border" + ie[s] + "Width", !0, o));
        return !i && 0 <= r && (l += Math.max(0, Math.ceil(e["offset" + t[0].toUpperCase() + t.slice(1)] - r - l - a - .5))), l
    }

    function rt(e, t, n) {
        var i = We(e), o = Ve(e, t, i), r = "border-box" === S.css(e, "boxSizing", !1, i), s = r;
        if (_e.test(o)) {
            if (!n) return o;
            o = "auto"
        }
        return s = s && (y.boxSizingReliable() || o === e.style[t]), "auto" !== o && (parseFloat(o) || "inline" !== S.css(e, "display", !1, i)) || (o = e["offset" + t[0].toUpperCase() + t.slice(1)], s = !0), (o = parseFloat(o) || 0) + ot(e, t, n || (r ? "border" : "content"), s, i, o) + "px"
    }

    function st(e, t, n, i, o) {
        return new st.prototype.init(e, t, n, i, o)
    }

    S.extend({
        cssHooks: {
            opacity: {
                get: function (e, t) {
                    if (t) {
                        var n = Ve(e, "opacity");
                        return "" === n ? "1" : n
                    }
                }
            }
        },
        cssNumber: {
            animationIterationCount: !0,
            columnCount: !0,
            fillOpacity: !0,
            flexGrow: !0,
            flexShrink: !0,
            fontWeight: !0,
            lineHeight: !0,
            opacity: !0,
            order: !0,
            orphans: !0,
            widows: !0,
            zIndex: !0,
            zoom: !0
        },
        cssProps: {},
        style: function (e, t, n, i) {
            if (e && 3 !== e.nodeType && 8 !== e.nodeType && e.style) {
                var o, r, s, a = Y(t), l = Ge.test(t), c = e.style;
                if (l || (t = nt(a)), s = S.cssHooks[t] || S.cssHooks[a], void 0 === n) return s && "get" in s && void 0 !== (o = s.get(e, !1, i)) ? o : c[t];
                "string" == (r = typeof n) && (o = ne.exec(n)) && o[1] && (n = re(e, t, o), r = "number"), null != n && n == n && ("number" === r && (n += o && o[3] || (S.cssNumber[a] ? "" : "px")), y.clearCloneStyle || "" !== n || 0 !== t.indexOf("background") || (c[t] = "inherit"), s && "set" in s && void 0 === (n = s.set(e, n, i)) || (l ? c.setProperty(t, n) : c[t] = n))
            }
        },
        css: function (e, t, n, i) {
            var o, r, s, a = Y(t);
            return Ge.test(t) || (t = nt(a)), (s = S.cssHooks[t] || S.cssHooks[a]) && "get" in s && (o = s.get(e, !0, n)), void 0 === o && (o = Ve(e, t, i)), "normal" === o && t in Je && (o = Je[t]), "" === n || n ? (r = parseFloat(o), !0 === n || isFinite(r) ? r || 0 : o) : o
        }
    }), S.each(["height", "width"], function (e, a) {
        S.cssHooks[a] = {
            get: function (e, t, n) {
                if (t) return !Qe.test(S.css(e, "display")) || e.getClientRects().length && e.getBoundingClientRect().width ? rt(e, a, n) : ee(e, Ke, function () {
                    return rt(e, a, n)
                })
            }, set: function (e, t, n) {
                var i, o = We(e), r = "border-box" === S.css(e, "boxSizing", !1, o), s = n && ot(e, a, n, r, o);
                return r && y.scrollboxSize() === o.position && (s -= Math.ceil(e["offset" + a[0].toUpperCase() + a.slice(1)] - parseFloat(o[a]) - ot(e, a, "border", !1, o) - .5)), s && (i = ne.exec(t)) && "px" !== (i[3] || "px") && (e.style[a] = t, t = S.css(e, a)), it(0, t, s)
            }
        }
    }), S.cssHooks.marginLeft = Ze(y.reliableMarginLeft, function (e, t) {
        if (t) return (parseFloat(Ve(e, "marginLeft")) || e.getBoundingClientRect().left - ee(e, {marginLeft: 0}, function () {
            return e.getBoundingClientRect().left
        })) + "px"
    }), S.each({margin: "", padding: "", border: "Width"}, function (o, r) {
        S.cssHooks[o + r] = {
            expand: function (e) {
                for (var t = 0, n = {}, i = "string" == typeof e ? e.split(" ") : [e]; t < 4; t++) n[o + ie[t] + r] = i[t] || i[t - 2] || i[0];
                return n
            }
        }, "margin" !== o && (S.cssHooks[o + r].set = it)
    }), S.fn.extend({
        css: function (e, t) {
            return B(this, function (e, t, n) {
                var i, o, r = {}, s = 0;
                if (Array.isArray(t)) {
                    for (i = We(e), o = t.length; s < o; s++) r[t[s]] = S.css(e, t[s], !1, i);
                    return r
                }
                return void 0 !== n ? S.style(e, t, n) : S.css(e, t)
            }, e, t, 1 < arguments.length)
        }
    }), ((S.Tween = st).prototype = {
        constructor: st, init: function (e, t, n, i, o, r) {
            this.elem = e, this.prop = n, this.easing = o || S.easing._default, this.options = t, this.start = this.now = this.cur(), this.end = i, this.unit = r || (S.cssNumber[n] ? "" : "px")
        }, cur: function () {
            var e = st.propHooks[this.prop];
            return e && e.get ? e.get(this) : st.propHooks._default.get(this)
        }, run: function (e) {
            var t, n = st.propHooks[this.prop];
            return this.options.duration ? this.pos = t = S.easing[this.easing](e, this.options.duration * e, 0, 1, this.options.duration) : this.pos = t = e, this.now = (this.end - this.start) * t + this.start, this.options.step && this.options.step.call(this.elem, this.now, this), n && n.set ? n.set(this) : st.propHooks._default.set(this), this
        }
    }).init.prototype = st.prototype, (st.propHooks = {
        _default: {
            get: function (e) {
                var t;
                return 1 !== e.elem.nodeType || null != e.elem[e.prop] && null == e.elem.style[e.prop] ? e.elem[e.prop] : (t = S.css(e.elem, e.prop, "")) && "auto" !== t ? t : 0
            }, set: function (e) {
                S.fx.step[e.prop] ? S.fx.step[e.prop](e) : 1 !== e.elem.nodeType || null == e.elem.style[S.cssProps[e.prop]] && !S.cssHooks[e.prop] ? e.elem[e.prop] = e.now : S.style(e.elem, e.prop, e.now + e.unit)
            }
        }
    }).scrollTop = st.propHooks.scrollLeft = {
        set: function (e) {
            e.elem.nodeType && e.elem.parentNode && (e.elem[e.prop] = e.now)
        }
    }, S.easing = {
        linear: function (e) {
            return e
        }, swing: function (e) {
            return .5 - Math.cos(e * Math.PI) / 2
        }, _default: "swing"
    }, S.fx = st.prototype.init, S.fx.step = {};
    var at, lt, ct, ut, dt = /^(?:toggle|show|hide)$/, ft = /queueHooks$/;

    function ht() {
        lt && (!1 === $.hidden && T.requestAnimationFrame ? T.requestAnimationFrame(ht) : T.setTimeout(ht, S.fx.interval), S.fx.tick())
    }

    function pt() {
        return T.setTimeout(function () {
            at = void 0
        }), at = Date.now()
    }

    function gt(e, t) {
        var n, i = 0, o = {height: e};
        for (t = t ? 1 : 0; i < 4; i += 2 - t) o["margin" + (n = ie[i])] = o["padding" + n] = e;
        return t && (o.opacity = o.width = e), o
    }

    function mt(e, t, n) {
        for (var i, o = (vt.tweeners[t] || []).concat(vt.tweeners["*"]), r = 0, s = o.length; r < s; r++) if (i = o[r].call(n, t, e)) return i
    }

    function vt(r, e, t) {
        var n, s, i = 0, o = vt.prefilters.length, a = S.Deferred().always(function () {
            delete l.elem
        }), l = function () {
            if (s) return !1;
            for (var e = at || pt(), t = Math.max(0, c.startTime + c.duration - e), n = 1 - (t / c.duration || 0), i = 0, o = c.tweens.length; i < o; i++) c.tweens[i].run(n);
            return a.notifyWith(r, [c, n, t]), n < 1 && o ? t : (o || a.notifyWith(r, [c, 1, 0]), a.resolveWith(r, [c]), !1)
        }, c = a.promise({
            elem: r,
            props: S.extend({}, e),
            opts: S.extend(!0, {specialEasing: {}, easing: S.easing._default}, t),
            originalProperties: e,
            originalOptions: t,
            startTime: at || pt(),
            duration: t.duration,
            tweens: [],
            createTween: function (e, t) {
                var n = S.Tween(r, c.opts, e, t, c.opts.specialEasing[e] || c.opts.easing);
                return c.tweens.push(n), n
            },
            stop: function (e) {
                var t = 0, n = e ? c.tweens.length : 0;
                if (s) return this;
                for (s = !0; t < n; t++) c.tweens[t].run(1);
                return e ? (a.notifyWith(r, [c, 1, 0]), a.resolveWith(r, [c, e])) : a.rejectWith(r, [c, e]), this
            }
        }), u = c.props;
        for (function (e, t) {
            var n, i, o, r, s;
            for (n in e) if (o = t[i = Y(n)], r = e[n], Array.isArray(r) && (o = r[1], r = e[n] = r[0]), n !== i && (e[i] = r, delete e[n]), (s = S.cssHooks[i]) && "expand" in s) for (n in r = s.expand(r), delete e[i], r) n in e || (e[n] = r[n], t[n] = o); else t[i] = o
        }(u, c.opts.specialEasing); i < o; i++) if (n = vt.prefilters[i].call(c, r, u, c.opts)) return b(n.stop) && (S._queueHooks(c.elem, c.opts.queue).stop = n.stop.bind(n)), n;
        return S.map(u, mt, c), b(c.opts.start) && c.opts.start.call(r, c), c.progress(c.opts.progress).done(c.opts.done, c.opts.complete).fail(c.opts.fail).always(c.opts.always), S.fx.timer(S.extend(l, {
            elem: r,
            anim: c,
            queue: c.opts.queue
        })), c
    }

    S.Animation = S.extend(vt, {
        tweeners: {
            "*": [function (e, t) {
                var n = this.createTween(e, t);
                return re(n.elem, e, ne.exec(t), n), n
            }]
        }, tweener: function (e, t) {
            for (var n, i = 0, o = (e = b(e) ? (t = e, ["*"]) : e.match(N)).length; i < o; i++) n = e[i], vt.tweeners[n] = vt.tweeners[n] || [], vt.tweeners[n].unshift(t)
        }, prefilters: [function (e, t, n) {
            var i, o, r, s, a, l, c, u, d = "width" in t || "height" in t, f = this, h = {}, p = e.style,
                g = e.nodeType && oe(e), m = Z.get(e, "fxshow");
            for (i in n.queue || (null == (s = S._queueHooks(e, "fx")).unqueued && (s.unqueued = 0, a = s.empty.fire, s.empty.fire = function () {
                s.unqueued || a()
            }), s.unqueued++, f.always(function () {
                f.always(function () {
                    s.unqueued--, S.queue(e, "fx").length || s.empty.fire()
                })
            })), t) if (o = t[i], dt.test(o)) {
                if (delete t[i], r = r || "toggle" === o, o === (g ? "hide" : "show")) {
                    if ("show" !== o || !m || void 0 === m[i]) continue;
                    g = !0
                }
                h[i] = m && m[i] || S.style(e, i)
            }
            if ((l = !S.isEmptyObject(t)) || !S.isEmptyObject(h)) for (i in d && 1 === e.nodeType && (n.overflow = [p.overflow, p.overflowX, p.overflowY], null == (c = m && m.display) && (c = Z.get(e, "display")), "none" === (u = S.css(e, "display")) && (c ? u = c : (ae([e], !0), c = e.style.display || c, u = S.css(e, "display"), ae([e]))), ("inline" === u || "inline-block" === u && null != c) && "none" === S.css(e, "float") && (l || (f.done(function () {
                p.display = c
            }), null == c && (u = p.display, c = "none" === u ? "" : u)), p.display = "inline-block")), n.overflow && (p.overflow = "hidden", f.always(function () {
                p.overflow = n.overflow[0], p.overflowX = n.overflow[1], p.overflowY = n.overflow[2]
            })), l = !1, h) l || (m ? "hidden" in m && (g = m.hidden) : m = Z.access(e, "fxshow", {display: c}), r && (m.hidden = !g), g && ae([e], !0), f.done(function () {
                for (i in g || ae([e]), Z.remove(e, "fxshow"), h) S.style(e, i, h[i])
            })), l = mt(g ? m[i] : 0, i, f), i in m || (m[i] = l.start, g && (l.end = l.start, l.start = 0))
        }], prefilter: function (e, t) {
            t ? vt.prefilters.unshift(e) : vt.prefilters.push(e)
        }
    }), S.speed = function (e, t, n) {
        var i = e && "object" == typeof e ? S.extend({}, e) : {
            complete: n || !n && t || b(e) && e,
            duration: e,
            easing: n && t || t && !b(t) && t
        };
        return S.fx.off ? i.duration = 0 : "number" != typeof i.duration && (i.duration in S.fx.speeds ? i.duration = S.fx.speeds[i.duration] : i.duration = S.fx.speeds._default), null != i.queue && !0 !== i.queue || (i.queue = "fx"), i.old = i.complete, i.complete = function () {
            b(i.old) && i.old.call(this), i.queue && S.dequeue(this, i.queue)
        }, i
    }, S.fn.extend({
        fadeTo: function (e, t, n, i) {
            return this.filter(oe).css("opacity", 0).show().end().animate({opacity: t}, e, n, i)
        }, animate: function (t, e, n, i) {
            function o() {
                var e = vt(this, S.extend({}, t), s);
                (r || Z.get(this, "finish")) && e.stop(!0)
            }

            var r = S.isEmptyObject(t), s = S.speed(e, n, i);
            return o.finish = o, r || !1 === s.queue ? this.each(o) : this.queue(s.queue, o)
        }, stop: function (o, e, r) {
            function s(e) {
                var t = e.stop;
                delete e.stop, t(r)
            }

            return "string" != typeof o && (r = e, e = o, o = void 0), e && !1 !== o && this.queue(o || "fx", []), this.each(function () {
                var e = !0, t = null != o && o + "queueHooks", n = S.timers, i = Z.get(this);
                if (t) i[t] && i[t].stop && s(i[t]); else for (t in i) i[t] && i[t].stop && ft.test(t) && s(i[t]);
                for (t = n.length; t--;) n[t].elem !== this || null != o && n[t].queue !== o || (n[t].anim.stop(r), e = !1, n.splice(t, 1));
                !e && r || S.dequeue(this, o)
            })
        }, finish: function (s) {
            return !1 !== s && (s = s || "fx"), this.each(function () {
                var e, t = Z.get(this), n = t[s + "queue"], i = t[s + "queueHooks"], o = S.timers, r = n ? n.length : 0;
                for (t.finish = !0, S.queue(this, s, []), i && i.stop && i.stop.call(this, !0), e = o.length; e--;) o[e].elem === this && o[e].queue === s && (o[e].anim.stop(!0), o.splice(e, 1));
                for (e = 0; e < r; e++) n[e] && n[e].finish && n[e].finish.call(this);
                delete t.finish
            })
        }
    }), S.each(["toggle", "show", "hide"], function (e, i) {
        var o = S.fn[i];
        S.fn[i] = function (e, t, n) {
            return null == e || "boolean" == typeof e ? o.apply(this, arguments) : this.animate(gt(i, !0), e, t, n)
        }
    }), S.each({
        slideDown: gt("show"),
        slideUp: gt("hide"),
        slideToggle: gt("toggle"),
        fadeIn: {opacity: "show"},
        fadeOut: {opacity: "hide"},
        fadeToggle: {opacity: "toggle"}
    }, function (e, i) {
        S.fn[e] = function (e, t, n) {
            return this.animate(i, e, t, n)
        }
    }), S.timers = [], S.fx.tick = function () {
        var e, t = 0, n = S.timers;
        for (at = Date.now(); t < n.length; t++) (e = n[t])() || n[t] !== e || n.splice(t--, 1);
        n.length || S.fx.stop(), at = void 0
    }, S.fx.timer = function (e) {
        S.timers.push(e), S.fx.start()
    }, S.fx.interval = 13, S.fx.start = function () {
        lt || (lt = !0, ht())
    }, S.fx.stop = function () {
        lt = null
    }, S.fx.speeds = {slow: 600, fast: 200, _default: 400}, S.fn.delay = function (i, e) {
        return i = S.fx && S.fx.speeds[i] || i, e = e || "fx", this.queue(e, function (e, t) {
            var n = T.setTimeout(e, i);
            t.stop = function () {
                T.clearTimeout(n)
            }
        })
    }, ct = $.createElement("input"), ut = $.createElement("select").appendChild($.createElement("option")), ct.type = "checkbox", y.checkOn = "" !== ct.value, y.optSelected = ut.selected, (ct = $.createElement("input")).value = "t", ct.type = "radio", y.radioValue = "t" === ct.value;
    var yt, bt = S.expr.attrHandle;
    S.fn.extend({
        attr: function (e, t) {
            return B(this, S.attr, e, t, 1 < arguments.length)
        }, removeAttr: function (e) {
            return this.each(function () {
                S.removeAttr(this, e)
            })
        }
    }), S.extend({
        attr: function (e, t, n) {
            var i, o, r = e.nodeType;
            if (3 !== r && 8 !== r && 2 !== r) return void 0 === e.getAttribute ? S.prop(e, t, n) : (1 === r && S.isXMLDoc(e) || (o = S.attrHooks[t.toLowerCase()] || (S.expr.match.bool.test(t) ? yt : void 0)), void 0 !== n ? null === n ? void S.removeAttr(e, t) : o && "set" in o && void 0 !== (i = o.set(e, n, t)) ? i : (e.setAttribute(t, n + ""), n) : o && "get" in o && null !== (i = o.get(e, t)) ? i : null == (i = S.find.attr(e, t)) ? void 0 : i)
        }, attrHooks: {
            type: {
                set: function (e, t) {
                    if (!y.radioValue && "radio" === t && E(e, "input")) {
                        var n = e.value;
                        return e.setAttribute("type", t), n && (e.value = n), t
                    }
                }
            }
        }, removeAttr: function (e, t) {
            var n, i = 0, o = t && t.match(N);
            if (o && 1 === e.nodeType) for (; n = o[i++];) e.removeAttribute(n)
        }
    }), yt = {
        set: function (e, t, n) {
            return !1 === t ? S.removeAttr(e, n) : e.setAttribute(n, n), n
        }
    }, S.each(S.expr.match.bool.source.match(/\w+/g), function (e, t) {
        var s = bt[t] || S.find.attr;
        bt[t] = function (e, t, n) {
            var i, o, r = t.toLowerCase();
            return n || (o = bt[r], bt[r] = i, i = null != s(e, t, n) ? r : null, bt[r] = o), i
        }
    });
    var xt = /^(?:input|select|textarea|button)$/i, wt = /^(?:a|area)$/i;

    function Ct(e) {
        return (e.match(N) || []).join(" ")
    }

    function Tt(e) {
        return e.getAttribute && e.getAttribute("class") || ""
    }

    function $t(e) {
        return Array.isArray(e) ? e : "string" == typeof e && e.match(N) || []
    }

    S.fn.extend({
        prop: function (e, t) {
            return B(this, S.prop, e, t, 1 < arguments.length)
        }, removeProp: function (e) {
            return this.each(function () {
                delete this[S.propFix[e] || e]
            })
        }
    }), S.extend({
        prop: function (e, t, n) {
            var i, o, r = e.nodeType;
            if (3 !== r && 8 !== r && 2 !== r) return 1 === r && S.isXMLDoc(e) || (t = S.propFix[t] || t, o = S.propHooks[t]), void 0 !== n ? o && "set" in o && void 0 !== (i = o.set(e, n, t)) ? i : e[t] = n : o && "get" in o && null !== (i = o.get(e, t)) ? i : e[t]
        }, propHooks: {
            tabIndex: {
                get: function (e) {
                    var t = S.find.attr(e, "tabindex");
                    return t ? parseInt(t, 10) : xt.test(e.nodeName) || wt.test(e.nodeName) && e.href ? 0 : -1
                }
            }
        }, propFix: {for: "htmlFor", class: "className"}
    }), y.optSelected || (S.propHooks.selected = {
        get: function (e) {
            var t = e.parentNode;
            return t && t.parentNode && t.parentNode.selectedIndex, null
        }, set: function (e) {
            var t = e.parentNode;
            t && (t.selectedIndex, t.parentNode && t.parentNode.selectedIndex)
        }
    }), S.each(["tabIndex", "readOnly", "maxLength", "cellSpacing", "cellPadding", "rowSpan", "colSpan", "useMap", "frameBorder", "contentEditable"], function () {
        S.propFix[this.toLowerCase()] = this
    }), S.fn.extend({
        addClass: function (t) {
            var e, n, i, o, r, s, a, l = 0;
            if (b(t)) return this.each(function (e) {
                S(this).addClass(t.call(this, e, Tt(this)))
            });
            if ((e = $t(t)).length) for (; n = this[l++];) if (o = Tt(n), i = 1 === n.nodeType && " " + Ct(o) + " ") {
                for (s = 0; r = e[s++];) i.indexOf(" " + r + " ") < 0 && (i += r + " ");
                o !== (a = Ct(i)) && n.setAttribute("class", a)
            }
            return this
        }, removeClass: function (t) {
            var e, n, i, o, r, s, a, l = 0;
            if (b(t)) return this.each(function (e) {
                S(this).removeClass(t.call(this, e, Tt(this)))
            });
            if (!arguments.length) return this.attr("class", "");
            if ((e = $t(t)).length) for (; n = this[l++];) if (o = Tt(n), i = 1 === n.nodeType && " " + Ct(o) + " ") {
                for (s = 0; r = e[s++];) for (; -1 < i.indexOf(" " + r + " ");) i = i.replace(" " + r + " ", " ");
                o !== (a = Ct(i)) && n.setAttribute("class", a)
            }
            return this
        }, toggleClass: function (o, t) {
            var r = typeof o, s = "string" == r || Array.isArray(o);
            return "boolean" == typeof t && s ? t ? this.addClass(o) : this.removeClass(o) : b(o) ? this.each(function (e) {
                S(this).toggleClass(o.call(this, e, Tt(this), t), t)
            }) : this.each(function () {
                var e, t, n, i;
                if (s) for (t = 0, n = S(this), i = $t(o); e = i[t++];) n.hasClass(e) ? n.removeClass(e) : n.addClass(e); else void 0 !== o && "boolean" != r || ((e = Tt(this)) && Z.set(this, "__className__", e), this.setAttribute && this.setAttribute("class", e || !1 === o ? "" : Z.get(this, "__className__") || ""))
            })
        }, hasClass: function (e) {
            var t, n, i = 0;
            for (t = " " + e + " "; n = this[i++];) if (1 === n.nodeType && -1 < (" " + Ct(Tt(n)) + " ").indexOf(t)) return !0;
            return !1
        }
    });
    var St = /\r/g;
    S.fn.extend({
        val: function (n) {
            var i, e, o, t = this[0];
            return arguments.length ? (o = b(n), this.each(function (e) {
                var t;
                1 === this.nodeType && (null == (t = o ? n.call(this, e, S(this).val()) : n) ? t = "" : "number" == typeof t ? t += "" : Array.isArray(t) && (t = S.map(t, function (e) {
                    return null == e ? "" : e + ""
                })), (i = S.valHooks[this.type] || S.valHooks[this.nodeName.toLowerCase()]) && "set" in i && void 0 !== i.set(this, t, "value") || (this.value = t))
            })) : t ? (i = S.valHooks[t.type] || S.valHooks[t.nodeName.toLowerCase()]) && "get" in i && void 0 !== (e = i.get(t, "value")) ? e : "string" == typeof (e = t.value) ? e.replace(St, "") : null == e ? "" : e : void 0
        }
    }), S.extend({
        valHooks: {
            option: {
                get: function (e) {
                    var t = S.find.attr(e, "value");
                    return null != t ? t : Ct(S.text(e))
                }
            }, select: {
                get: function (e) {
                    var t, n, i, o = e.options, r = e.selectedIndex, s = "select-one" === e.type, a = s ? null : [],
                        l = s ? r + 1 : o.length;
                    for (i = r < 0 ? l : s ? r : 0; i < l; i++) if (((n = o[i]).selected || i === r) && !n.disabled && (!n.parentNode.disabled || !E(n.parentNode, "optgroup"))) {
                        if (t = S(n).val(), s) return t;
                        a.push(t)
                    }
                    return a
                }, set: function (e, t) {
                    for (var n, i, o = e.options, r = S.makeArray(t), s = o.length; s--;) ((i = o[s]).selected = -1 < S.inArray(S.valHooks.option.get(i), r)) && (n = !0);
                    return n || (e.selectedIndex = -1), r
                }
            }
        }
    }), S.each(["radio", "checkbox"], function () {
        S.valHooks[this] = {
            set: function (e, t) {
                if (Array.isArray(t)) return e.checked = -1 < S.inArray(S(e).val(), t)
            }
        }, y.checkOn || (S.valHooks[this].get = function (e) {
            return null === e.getAttribute("value") ? "on" : e.value
        })
    }), y.focusin = "onfocusin" in T;

    function kt(e) {
        e.stopPropagation()
    }

    var Et = /^(?:focusinfocus|focusoutblur)$/;
    S.extend(S.event, {
        trigger: function (e, t, n, i) {
            var o, r, s, a, l, c, u, d, f = [n || $], h = v.call(e, "type") ? e.type : e,
                p = v.call(e, "namespace") ? e.namespace.split(".") : [];
            if (r = d = s = n = n || $, 3 !== n.nodeType && 8 !== n.nodeType && !Et.test(h + S.event.triggered) && (-1 < h.indexOf(".") && (h = (p = h.split(".")).shift(), p.sort()), l = h.indexOf(":") < 0 && "on" + h, (e = e[S.expando] ? e : new S.Event(h, "object" == typeof e && e)).isTrigger = i ? 2 : 3, e.namespace = p.join("."), e.rnamespace = e.namespace ? new RegExp("(^|\\.)" + p.join("\\.(?:.*\\.|)") + "(\\.|$)") : null, e.result = void 0, e.target || (e.target = n), t = null == t ? [e] : S.makeArray(t, [e]), u = S.event.special[h] || {}, i || !u.trigger || !1 !== u.trigger.apply(n, t))) {
                if (!i && !u.noBubble && !g(n)) {
                    for (a = u.delegateType || h, Et.test(a + h) || (r = r.parentNode); r; r = r.parentNode) f.push(r), s = r;
                    s === (n.ownerDocument || $) && f.push(s.defaultView || s.parentWindow || T)
                }
                for (o = 0; (r = f[o++]) && !e.isPropagationStopped();) d = r, e.type = 1 < o ? a : u.bindType || h, (c = (Z.get(r, "events") || {})[e.type] && Z.get(r, "handle")) && c.apply(r, t), (c = l && r[l]) && c.apply && U(r) && (e.result = c.apply(r, t), !1 === e.result && e.preventDefault());
                return e.type = h, i || e.isDefaultPrevented() || u._default && !1 !== u._default.apply(f.pop(), t) || !U(n) || l && b(n[h]) && !g(n) && ((s = n[l]) && (n[l] = null), S.event.triggered = h, e.isPropagationStopped() && d.addEventListener(h, kt), n[h](), e.isPropagationStopped() && d.removeEventListener(h, kt), S.event.triggered = void 0, s && (n[l] = s)), e.result
            }
        }, simulate: function (e, t, n) {
            var i = S.extend(new S.Event, n, {type: e, isSimulated: !0});
            S.event.trigger(i, null, t)
        }
    }), S.fn.extend({
        trigger: function (e, t) {
            return this.each(function () {
                S.event.trigger(e, t, this)
            })
        }, triggerHandler: function (e, t) {
            var n = this[0];
            if (n) return S.event.trigger(e, t, n, !0)
        }
    }), y.focusin || S.each({focus: "focusin", blur: "focusout"}, function (n, i) {
        function o(e) {
            S.event.simulate(i, e.target, S.event.fix(e))
        }

        S.event.special[i] = {
            setup: function () {
                var e = this.ownerDocument || this, t = Z.access(e, i);
                t || e.addEventListener(n, o, !0), Z.access(e, i, (t || 0) + 1)
            }, teardown: function () {
                var e = this.ownerDocument || this, t = Z.access(e, i) - 1;
                t ? Z.access(e, i, t) : (e.removeEventListener(n, o, !0), Z.remove(e, i))
            }
        }
    });
    var Pt = T.location, At = Date.now(), Lt = /\?/;
    S.parseXML = function (e) {
        var t;
        if (!e || "string" != typeof e) return null;
        try {
            t = (new T.DOMParser).parseFromString(e, "text/xml")
        } catch (e) {
            t = void 0
        }
        return t && !t.getElementsByTagName("parsererror").length || S.error("Invalid XML: " + e), t
    };
    var qt = /\[\]$/, Dt = /\r?\n/g, jt = /^(?:submit|button|image|reset|file)$/i,
        Mt = /^(?:input|select|textarea|keygen)/i;

    function Nt(n, e, i, o) {
        var t;
        if (Array.isArray(e)) S.each(e, function (e, t) {
            i || qt.test(n) ? o(n, t) : Nt(n + "[" + ("object" == typeof t && null != t ? e : "") + "]", t, i, o)
        }); else if (i || "object" !== w(e)) o(n, e); else for (t in e) Nt(n + "[" + t + "]", e[t], i, o)
    }

    S.param = function (e, t) {
        function n(e, t) {
            var n = b(t) ? t() : t;
            o[o.length] = encodeURIComponent(e) + "=" + encodeURIComponent(null == n ? "" : n)
        }

        var i, o = [];
        if (Array.isArray(e) || e.jquery && !S.isPlainObject(e)) S.each(e, function () {
            n(this.name, this.value)
        }); else for (i in e) Nt(i, e[i], t, n);
        return o.join("&")
    }, S.fn.extend({
        serialize: function () {
            return S.param(this.serializeArray())
        }, serializeArray: function () {
            return this.map(function () {
                var e = S.prop(this, "elements");
                return e ? S.makeArray(e) : this
            }).filter(function () {
                var e = this.type;
                return this.name && !S(this).is(":disabled") && Mt.test(this.nodeName) && !jt.test(e) && (this.checked || !le.test(e))
            }).map(function (e, t) {
                var n = S(this).val();
                return null == n ? null : Array.isArray(n) ? S.map(n, function (e) {
                    return {name: t.name, value: e.replace(Dt, "\r\n")}
                }) : {name: t.name, value: n.replace(Dt, "\r\n")}
            }).get()
        }
    });
    var Ft = /%20/g, It = /#.*$/, Rt = /([?&])_=[^&]*/, Ht = /^(.*?):[ \t]*([^\r\n]*)$/gm, Ot = /^(?:GET|HEAD)$/,
        zt = /^\/\//, Bt = {}, _t = {}, Wt = "*/".concat("*"), Xt = $.createElement("a");

    function Yt(r) {
        return function (e, t) {
            "string" != typeof e && (t = e, e = "*");
            var n, i = 0, o = e.toLowerCase().match(N) || [];
            if (b(t)) for (; n = o[i++];) "+" === n[0] ? (n = n.slice(1) || "*", (r[n] = r[n] || []).unshift(t)) : (r[n] = r[n] || []).push(t)
        }
    }

    function Ut(t, o, r, s) {
        var a = {}, l = t === _t;

        function c(e) {
            var i;
            return a[e] = !0, S.each(t[e] || [], function (e, t) {
                var n = t(o, r, s);
                return "string" != typeof n || l || a[n] ? l ? !(i = n) : void 0 : (o.dataTypes.unshift(n), c(n), !1)
            }), i
        }

        return c(o.dataTypes[0]) || !a["*"] && c("*")
    }

    function Vt(e, t) {
        var n, i, o = S.ajaxSettings.flatOptions || {};
        for (n in t) void 0 !== t[n] && ((o[n] ? e : i = i || {})[n] = t[n]);
        return i && S.extend(!0, e, i), e
    }

    Xt.href = Pt.href, S.extend({
        active: 0,
        lastModified: {},
        etag: {},
        ajaxSettings: {
            url: Pt.href,
            type: "GET",
            isLocal: /^(?:about|app|app-storage|.+-extension|file|res|widget):$/.test(Pt.protocol),
            global: !0,
            processData: !0,
            async: !0,
            contentType: "application/x-www-form-urlencoded; charset=UTF-8",
            accepts: {
                "*": Wt,
                text: "text/plain",
                html: "text/html",
                xml: "application/xml, text/xml",
                json: "application/json, text/javascript"
            },
            contents: {xml: /\bxml\b/, html: /\bhtml/, json: /\bjson\b/},
            responseFields: {xml: "responseXML", text: "responseText", json: "responseJSON"},
            converters: {"* text": String, "text html": !0, "text json": JSON.parse, "text xml": S.parseXML},
            flatOptions: {url: !0, context: !0}
        },
        ajaxSetup: function (e, t) {
            return t ? Vt(Vt(e, S.ajaxSettings), t) : Vt(S.ajaxSettings, e)
        },
        ajaxPrefilter: Yt(Bt),
        ajaxTransport: Yt(_t),
        ajax: function (e, t) {
            "object" == typeof e && (t = e, e = void 0), t = t || {};
            var u, d, f, n, h, i, p, g, o, r, m = S.ajaxSetup({}, t), v = m.context || m,
                y = m.context && (v.nodeType || v.jquery) ? S(v) : S.event, b = S.Deferred(),
                x = S.Callbacks("once memory"), w = m.statusCode || {}, s = {}, a = {}, l = "canceled", C = {
                    readyState: 0, getResponseHeader: function (e) {
                        var t;
                        if (p) {
                            if (!n) for (n = {}; t = Ht.exec(f);) n[t[1].toLowerCase()] = t[2];
                            t = n[e.toLowerCase()]
                        }
                        return null == t ? null : t
                    }, getAllResponseHeaders: function () {
                        return p ? f : null
                    }, setRequestHeader: function (e, t) {
                        return null == p && (e = a[e.toLowerCase()] = a[e.toLowerCase()] || e, s[e] = t), this
                    }, overrideMimeType: function (e) {
                        return null == p && (m.mimeType = e), this
                    }, statusCode: function (e) {
                        var t;
                        if (e) if (p) C.always(e[C.status]); else for (t in e) w[t] = [w[t], e[t]];
                        return this
                    }, abort: function (e) {
                        var t = e || l;
                        return u && u.abort(t), c(0, t), this
                    }
                };
            if (b.promise(C), m.url = ((e || m.url || Pt.href) + "").replace(zt, Pt.protocol + "//"), m.type = t.method || t.type || m.method || m.type, m.dataTypes = (m.dataType || "*").toLowerCase().match(N) || [""], null == m.crossDomain) {
                i = $.createElement("a");
                try {
                    i.href = m.url, i.href = i.href, m.crossDomain = Xt.protocol + "//" + Xt.host != i.protocol + "//" + i.host
                } catch (e) {
                    m.crossDomain = !0
                }
            }
            if (m.data && m.processData && "string" != typeof m.data && (m.data = S.param(m.data, m.traditional)), Ut(Bt, m, t, C), p) return C;
            for (o in (g = S.event && m.global) && 0 == S.active++ && S.event.trigger("ajaxStart"), m.type = m.type.toUpperCase(), m.hasContent = !Ot.test(m.type), d = m.url.replace(It, ""), m.hasContent ? m.data && m.processData && 0 === (m.contentType || "").indexOf("application/x-www-form-urlencoded") && (m.data = m.data.replace(Ft, "+")) : (r = m.url.slice(d.length), m.data && (m.processData || "string" == typeof m.data) && (d += (Lt.test(d) ? "&" : "?") + m.data, delete m.data), !1 === m.cache && (d = d.replace(Rt, "$1"), r = (Lt.test(d) ? "&" : "?") + "_=" + At++ + r), m.url = d + r), m.ifModified && (S.lastModified[d] && C.setRequestHeader("If-Modified-Since", S.lastModified[d]), S.etag[d] && C.setRequestHeader("If-None-Match", S.etag[d])), (m.data && m.hasContent && !1 !== m.contentType || t.contentType) && C.setRequestHeader("Content-Type", m.contentType), C.setRequestHeader("Accept", m.dataTypes[0] && m.accepts[m.dataTypes[0]] ? m.accepts[m.dataTypes[0]] + ("*" !== m.dataTypes[0] ? ", " + Wt + "; q=0.01" : "") : m.accepts["*"]), m.headers) C.setRequestHeader(o, m.headers[o]);
            if (m.beforeSend && (!1 === m.beforeSend.call(v, C, m) || p)) return C.abort();
            if (l = "abort", x.add(m.complete), C.done(m.success), C.fail(m.error), u = Ut(_t, m, t, C)) {
                if (C.readyState = 1, g && y.trigger("ajaxSend", [C, m]), p) return C;
                m.async && 0 < m.timeout && (h = T.setTimeout(function () {
                    C.abort("timeout")
                }, m.timeout));
                try {
                    p = !1, u.send(s, c)
                } catch (e) {
                    if (p) throw e;
                    c(-1, e)
                }
            } else c(-1, "No Transport");

            function c(e, t, n, i) {
                var o, r, s, a, l, c = t;
                p || (p = !0, h && T.clearTimeout(h), u = void 0, f = i || "", C.readyState = 0 < e ? 4 : 0, o = 200 <= e && e < 300 || 304 === e, n && (a = function (e, t, n) {
                    for (var i, o, r, s, a = e.contents, l = e.dataTypes; "*" === l[0];) l.shift(), void 0 === i && (i = e.mimeType || t.getResponseHeader("Content-Type"));
                    if (i) for (o in a) if (a[o] && a[o].test(i)) {
                        l.unshift(o);
                        break
                    }
                    if (l[0] in n) r = l[0]; else {
                        for (o in n) {
                            if (!l[0] || e.converters[o + " " + l[0]]) {
                                r = o;
                                break
                            }
                            s = s || o
                        }
                        r = r || s
                    }
                    if (r) return r !== l[0] && l.unshift(r), n[r]
                }(m, C, n)), a = function (e, t, n, i) {
                    var o, r, s, a, l, c = {}, u = e.dataTypes.slice();
                    if (u[1]) for (s in e.converters) c[s.toLowerCase()] = e.converters[s];
                    for (r = u.shift(); r;) if (e.responseFields[r] && (n[e.responseFields[r]] = t), !l && i && e.dataFilter && (t = e.dataFilter(t, e.dataType)), l = r, r = u.shift()) if ("*" === r) r = l; else if ("*" !== l && l !== r) {
                        if (!(s = c[l + " " + r] || c["* " + r])) for (o in c) if ((a = o.split(" "))[1] === r && (s = c[l + " " + a[0]] || c["* " + a[0]])) {
                            !0 === s ? s = c[o] : !0 !== c[o] && (r = a[0], u.unshift(a[1]));
                            break
                        }
                        if (!0 !== s) if (s && e.throws) t = s(t); else try {
                            t = s(t)
                        } catch (e) {
                            return {state: "parsererror", error: s ? e : "No conversion from " + l + " to " + r}
                        }
                    }
                    return {state: "success", data: t}
                }(m, a, C, o), o ? (m.ifModified && ((l = C.getResponseHeader("Last-Modified")) && (S.lastModified[d] = l), (l = C.getResponseHeader("etag")) && (S.etag[d] = l)), 204 === e || "HEAD" === m.type ? c = "nocontent" : 304 === e ? c = "notmodified" : (c = a.state, r = a.data, o = !(s = a.error))) : (s = c, !e && c || (c = "error", e < 0 && (e = 0))), C.status = e, C.statusText = (t || c) + "", o ? b.resolveWith(v, [r, c, C]) : b.rejectWith(v, [C, c, s]), C.statusCode(w), w = void 0, g && y.trigger(o ? "ajaxSuccess" : "ajaxError", [C, m, o ? r : s]), x.fireWith(v, [C, c]), g && (y.trigger("ajaxComplete", [C, m]), --S.active || S.event.trigger("ajaxStop")))
            }

            return C
        },
        getJSON: function (e, t, n) {
            return S.get(e, t, n, "json")
        },
        getScript: function (e, t) {
            return S.get(e, void 0, t, "script")
        }
    }), S.each(["get", "post"], function (e, o) {
        S[o] = function (e, t, n, i) {
            return b(t) && (i = i || n, n = t, t = void 0), S.ajax(S.extend({
                url: e,
                type: o,
                dataType: i,
                data: t,
                success: n
            }, S.isPlainObject(e) && e))
        }
    }), S._evalUrl = function (e) {
        return S.ajax({url: e, type: "GET", dataType: "script", cache: !0, async: !1, global: !1, throws: !0})
    }, S.fn.extend({
        wrapAll: function (e) {
            var t;
            return this[0] && (b(e) && (e = e.call(this[0])), t = S(e, this[0].ownerDocument).eq(0).clone(!0), this[0].parentNode && t.insertBefore(this[0]), t.map(function () {
                for (var e = this; e.firstElementChild;) e = e.firstElementChild;
                return e
            }).append(this)), this
        }, wrapInner: function (n) {
            return b(n) ? this.each(function (e) {
                S(this).wrapInner(n.call(this, e))
            }) : this.each(function () {
                var e = S(this), t = e.contents();
                t.length ? t.wrapAll(n) : e.append(n)
            })
        }, wrap: function (t) {
            var n = b(t);
            return this.each(function (e) {
                S(this).wrapAll(n ? t.call(this, e) : t)
            })
        }, unwrap: function (e) {
            return this.parent(e).not("body").each(function () {
                S(this).replaceWith(this.childNodes)
            }), this
        }
    }), S.expr.pseudos.hidden = function (e) {
        return !S.expr.pseudos.visible(e)
    }, S.expr.pseudos.visible = function (e) {
        return !!(e.offsetWidth || e.offsetHeight || e.getClientRects().length)
    }, S.ajaxSettings.xhr = function () {
        try {
            return new T.XMLHttpRequest
        } catch (e) {
        }
    };
    var Zt = {0: 200, 1223: 204}, Qt = S.ajaxSettings.xhr();
    y.cors = !!Qt && "withCredentials" in Qt, y.ajax = Qt = !!Qt, S.ajaxTransport(function (o) {
        var r, s;
        if (y.cors || Qt && !o.crossDomain) return {
            send: function (e, t) {
                var n, i = o.xhr();
                if (i.open(o.type, o.url, o.async, o.username, o.password), o.xhrFields) for (n in o.xhrFields) i[n] = o.xhrFields[n];
                for (n in o.mimeType && i.overrideMimeType && i.overrideMimeType(o.mimeType), o.crossDomain || e["X-Requested-With"] || (e["X-Requested-With"] = "XMLHttpRequest"), e) i.setRequestHeader(n, e[n]);
                r = function (e) {
                    return function () {
                        r && (r = s = i.onload = i.onerror = i.onabort = i.ontimeout = i.onreadystatechange = null, "abort" === e ? i.abort() : "error" === e ? "number" != typeof i.status ? t(0, "error") : t(i.status, i.statusText) : t(Zt[i.status] || i.status, i.statusText, "text" !== (i.responseType || "text") || "string" != typeof i.responseText ? {binary: i.response} : {text: i.responseText}, i.getAllResponseHeaders()))
                    }
                }, i.onload = r(), s = i.onerror = i.ontimeout = r("error"), void 0 !== i.onabort ? i.onabort = s : i.onreadystatechange = function () {
                    4 === i.readyState && T.setTimeout(function () {
                        r && s()
                    })
                }, r = r("abort");
                try {
                    i.send(o.hasContent && o.data || null)
                } catch (e) {
                    if (r) throw e
                }
            }, abort: function () {
                r && r()
            }
        }
    }), S.ajaxPrefilter(function (e) {
        e.crossDomain && (e.contents.script = !1)
    }), S.ajaxSetup({
        accepts: {script: "text/javascript, application/javascript, application/ecmascript, application/x-ecmascript"},
        contents: {script: /\b(?:java|ecma)script\b/},
        converters: {
            "text script": function (e) {
                return S.globalEval(e), e
            }
        }
    }), S.ajaxPrefilter("script", function (e) {
        void 0 === e.cache && (e.cache = !1), e.crossDomain && (e.type = "GET")
    }), S.ajaxTransport("script", function (n) {
        var i, o;
        if (n.crossDomain) return {
            send: function (e, t) {
                i = S("<script>").prop({charset: n.scriptCharset, src: n.url}).on("load error", o = function (e) {
                    i.remove(), o = null, e && t("error" === e.type ? 404 : 200, e.type)
                }), $.head.appendChild(i[0])
            }, abort: function () {
                o && o()
            }
        }
    });
    var Gt, Kt = [], Jt = /(=)\?(?=&|$)|\?\?/;
    S.ajaxSetup({
        jsonp: "callback", jsonpCallback: function () {
            var e = Kt.pop() || S.expando + "_" + At++;
            return this[e] = !0, e
        }
    }), S.ajaxPrefilter("json jsonp", function (e, t, n) {
        var i, o, r,
            s = !1 !== e.jsonp && (Jt.test(e.url) ? "url" : "string" == typeof e.data && 0 === (e.contentType || "").indexOf("application/x-www-form-urlencoded") && Jt.test(e.data) && "data");
        if (s || "jsonp" === e.dataTypes[0]) return i = e.jsonpCallback = b(e.jsonpCallback) ? e.jsonpCallback() : e.jsonpCallback, s ? e[s] = e[s].replace(Jt, "$1" + i) : !1 !== e.jsonp && (e.url += (Lt.test(e.url) ? "&" : "?") + e.jsonp + "=" + i), e.converters["script json"] = function () {
            return r || S.error(i + " was not called"), r[0]
        }, e.dataTypes[0] = "json", o = T[i], T[i] = function () {
            r = arguments
        }, n.always(function () {
            void 0 === o ? S(T).removeProp(i) : T[i] = o, e[i] && (e.jsonpCallback = t.jsonpCallback, Kt.push(i)), r && b(o) && o(r[0]), r = o = void 0
        }), "script"
    }), y.createHTMLDocument = ((Gt = $.implementation.createHTMLDocument("").body).innerHTML = "<form></form><form></form>", 2 === Gt.childNodes.length), S.parseHTML = function (e, t, n) {
        return "string" != typeof e ? [] : ("boolean" == typeof t && (n = t, t = !1), t || (y.createHTMLDocument ? ((i = (t = $.implementation.createHTMLDocument("")).createElement("base")).href = $.location.href, t.head.appendChild(i)) : t = $), r = !n && [], (o = P.exec(e)) ? [t.createElement(o[1])] : (o = ve([e], t, r), r && r.length && S(r).remove(), S.merge([], o.childNodes)));
        var i, o, r
    }, S.fn.load = function (e, t, n) {
        var i, o, r, s = this, a = e.indexOf(" ");
        return -1 < a && (i = Ct(e.slice(a)), e = e.slice(0, a)), b(t) ? (n = t, t = void 0) : t && "object" == typeof t && (o = "POST"), 0 < s.length && S.ajax({
            url: e,
            type: o || "GET",
            dataType: "html",
            data: t
        }).done(function (e) {
            r = arguments, s.html(i ? S("<div>").append(S.parseHTML(e)).find(i) : e)
        }).always(n && function (e, t) {
            s.each(function () {
                n.apply(this, r || [e.responseText, t, e])
            })
        }), this
    }, S.each(["ajaxStart", "ajaxStop", "ajaxComplete", "ajaxError", "ajaxSuccess", "ajaxSend"], function (e, t) {
        S.fn[t] = function (e) {
            return this.on(t, e)
        }
    }), S.expr.pseudos.animated = function (t) {
        return S.grep(S.timers, function (e) {
            return t === e.elem
        }).length
    }, S.offset = {
        setOffset: function (e, t, n) {
            var i, o, r, s, a, l, c = S.css(e, "position"), u = S(e), d = {};
            "static" === c && (e.style.position = "relative"), a = u.offset(), r = S.css(e, "top"), l = S.css(e, "left"), o = ("absolute" === c || "fixed" === c) && -1 < (r + l).indexOf("auto") ? (s = (i = u.position()).top, i.left) : (s = parseFloat(r) || 0, parseFloat(l) || 0), b(t) && (t = t.call(e, n, S.extend({}, a))), null != t.top && (d.top = t.top - a.top + s), null != t.left && (d.left = t.left - a.left + o), "using" in t ? t.using.call(e, d) : u.css(d)
        }
    }, S.fn.extend({
        offset: function (t) {
            if (arguments.length) return void 0 === t ? this : this.each(function (e) {
                S.offset.setOffset(this, t, e)
            });
            var e, n, i = this[0];
            return i ? i.getClientRects().length ? (e = i.getBoundingClientRect(), n = i.ownerDocument.defaultView, {
                top: e.top + n.pageYOffset,
                left: e.left + n.pageXOffset
            }) : {top: 0, left: 0} : void 0
        }, position: function () {
            if (this[0]) {
                var e, t, n, i = this[0], o = {top: 0, left: 0};
                if ("fixed" === S.css(i, "position")) t = i.getBoundingClientRect(); else {
                    for (t = this.offset(), n = i.ownerDocument, e = i.offsetParent || n.documentElement; e && (e === n.body || e === n.documentElement) && "static" === S.css(e, "position");) e = e.parentNode;
                    e && e !== i && 1 === e.nodeType && ((o = S(e).offset()).top += S.css(e, "borderTopWidth", !0), o.left += S.css(e, "borderLeftWidth", !0))
                }
                return {
                    top: t.top - o.top - S.css(i, "marginTop", !0),
                    left: t.left - o.left - S.css(i, "marginLeft", !0)
                }
            }
        }, offsetParent: function () {
            return this.map(function () {
                for (var e = this.offsetParent; e && "static" === S.css(e, "position");) e = e.offsetParent;
                return e || ye
            })
        }
    }), S.each({scrollLeft: "pageXOffset", scrollTop: "pageYOffset"}, function (t, o) {
        var r = "pageYOffset" === o;
        S.fn[t] = function (e) {
            return B(this, function (e, t, n) {
                var i;
                if (g(e) ? i = e : 9 === e.nodeType && (i = e.defaultView), void 0 === n) return i ? i[o] : e[t];
                i ? i.scrollTo(r ? i.pageXOffset : n, r ? n : i.pageYOffset) : e[t] = n
            }, t, e, arguments.length)
        }
    }), S.each(["top", "left"], function (e, n) {
        S.cssHooks[n] = Ze(y.pixelPosition, function (e, t) {
            if (t) return t = Ve(e, n), _e.test(t) ? S(e).position()[n] + "px" : t
        })
    }), S.each({Height: "height", Width: "width"}, function (s, a) {
        S.each({padding: "inner" + s, content: a, "": "outer" + s}, function (i, r) {
            S.fn[r] = function (e, t) {
                var n = arguments.length && (i || "boolean" != typeof e),
                    o = i || (!0 === e || !0 === t ? "margin" : "border");
                return B(this, function (e, t, n) {
                    var i;
                    return g(e) ? 0 === r.indexOf("outer") ? e["inner" + s] : e.document.documentElement["client" + s] : 9 === e.nodeType ? (i = e.documentElement, Math.max(e.body["scroll" + s], i["scroll" + s], e.body["offset" + s], i["offset" + s], i["client" + s])) : void 0 === n ? S.css(e, t, o) : S.style(e, t, n, o)
                }, a, n ? e : void 0, n)
            }
        })
    }), S.each("blur focus focusin focusout resize scroll click dblclick mousedown mouseup mousemove mouseover mouseout mouseenter mouseleave change select submit keydown keypress keyup contextmenu".split(" "), function (e, n) {
        S.fn[n] = function (e, t) {
            return 0 < arguments.length ? this.on(n, null, e, t) : this.trigger(n)
        }
    }), S.fn.extend({
        hover: function (e, t) {
            return this.mouseenter(e).mouseleave(t || e)
        }
    }), S.fn.extend({
        bind: function (e, t, n) {
            return this.on(e, null, t, n)
        }, unbind: function (e, t) {
            return this.off(e, null, t)
        }, delegate: function (e, t, n, i) {
            return this.on(t, e, n, i)
        }, undelegate: function (e, t, n) {
            return 1 === arguments.length ? this.off(e, "**") : this.off(t, e || "**", n)
        }
    }), S.proxy = function (e, t) {
        var n, i, o;
        if ("string" == typeof t && (n = e[t], t = e, e = n), b(e)) return i = a.call(arguments, 2), (o = function () {
            return e.apply(t || this, i.concat(a.call(arguments)))
        }).guid = e.guid = e.guid || S.guid++, o
    }, S.holdReady = function (e) {
        e ? S.readyWait++ : S.ready(!0)
    }, S.isArray = Array.isArray, S.parseJSON = JSON.parse, S.nodeName = E, S.isFunction = b, S.isWindow = g, S.camelCase = Y, S.type = w, S.now = Date.now, S.isNumeric = function (e) {
        var t = S.type(e);
        return ("number" === t || "string" === t) && !isNaN(e - parseFloat(e))
    }, "function" == typeof define && define.amd && define("jquery", [], function () {
        return S
    });
    var en = T.jQuery, tn = T.$;
    return S.noConflict = function (e) {
        return T.$ === S && (T.$ = tn), e && T.jQuery === S && (T.jQuery = en), S
    }, e || (T.jQuery = T.$ = S), S
}), function (u, s, g, m) {
    "use strict";

    function n(e, t) {
        var n, i, o = [], r = 0;
        e && e.isDefaultPrevented() || (e.preventDefault(), (i = (n = (t = e && e.data ? e.data.options : t || {}).$target || g(e.currentTarget)).attr("data-fancybox") || "") ? (r = (o = (o = t.selector ? g(t.selector) : e.data ? e.data.items : []).length ? o.filter('[data-fancybox="' + i + '"]') : g('[data-fancybox="' + i + '"]')).index(n)) < 0 && (r = 0) : o = [n], g.fancybox.open(o, t, r))
    }

    if (u.console = u.console || {
        info: function (e) {
        }
    }, g) {
        if (g.fn.fancybox) return console.info("fancyBox already initialized");
        var e = {
                loop: !1,
                gutter: 50,
                keyboard: !0,
                arrows: !0,
                infobar: !0,
                smallBtn: "auto",
                toolbar: "auto",
                buttons: ["zoom", "thumbs", "close"],
                idleTime: 3,
                protect: !1,
                modal: !1,
                image: {preload: !1},
                ajax: {settings: {data: {fancybox: !0}}},
                iframe: {
                    tpl: '<iframe id="fancybox-frame{rnd}" name="fancybox-frame{rnd}" class="fancybox-iframe" frameborder="0" vspace="0" hspace="0" webkitAllowFullScreen mozallowfullscreen allowFullScreen allowtransparency="true" src=""></iframe>',
                    preload: !0,
                    css: {},
                    attr: {scrolling: "auto"}
                },
                defaultType: "image",
                animationEffect: "zoom",
                animationDuration: 366,
                zoomOpacity: "auto",
                transitionEffect: "fade",
                transitionDuration: 366,
                slideClass: "",
                baseClass: "",
                baseTpl: '<div class="fancybox-container" role="dialog" tabindex="-1"><div class="fancybox-bg"></div><div class="fancybox-inner"><div class="fancybox-infobar"><span data-fancybox-index></span>&nbsp;/&nbsp;<span data-fancybox-count></span></div><div class="fancybox-toolbar">{{buttons}}</div><div class="fancybox-navigation">{{arrows}}</div><div class="fancybox-stage"></div><div class="fancybox-caption"></div></div></div>',
                spinnerTpl: '<div class="fancybox-loading"></div>',
                errorTpl: '<div class="fancybox-error"><p>{{ERROR}}</p></div>',
                btnTpl: {
                    download: '<a download data-fancybox-download class="fancybox-button fancybox-button--download" title="{{DOWNLOAD}}" href="javascript:;"><svg viewBox="0 0 40 40"><path d="M13,16 L20,23 L27,16 M20,7 L20,23 M10,24 L10,28 L30,28 L30,24" /></svg></a>',
                    zoom: '<button data-fancybox-zoom class="fancybox-button fancybox-button--zoom" title="{{ZOOM}}"><svg viewBox="0 0 40 40"><path d="M18,17 m-8,0 a8,8 0 1,0 16,0 a8,8 0 1,0 -16,0 M24,22 L31,29" /></svg></button>',
                    close: '<button data-fancybox-close class="fancybox-button fancybox-button--close" title="{{CLOSE}}"><svg viewBox="0 0 40 40"><path d="M10,10 L30,30 M30,10 L10,30" /></svg></button>',
                    smallBtn: '<button data-fancybox-close class="fancybox-close-small" title="{{CLOSE}}"><svg viewBox="0 0 32 32"><path d="M10,10 L22,22 M22,10 L10,22"></path></svg></button>',
                    arrowLeft: '<a data-fancybox-prev class="fancybox-button fancybox-button--arrow_left" title="{{PREV}}" href="javascript:;"><svg viewBox="0 0 40 40"><path d="M18,12 L10,20 L18,28 M10,20 L30,20"></path></svg></a>',
                    arrowRight: '<a data-fancybox-next class="fancybox-button fancybox-button--arrow_right" title="{{NEXT}}" href="javascript:;"><svg viewBox="0 0 40 40"><path d="M10,20 L30,20 M22,12 L30,20 L22,28"></path></svg></a>'
                },
                parentEl: "body",
                autoFocus: !1,
                backFocus: !0,
                trapFocus: !0,
                fullScreen: {autoStart: !1},
                touch: {vertical: !0, momentum: !0},
                hash: null,
                media: {},
                slideShow: {autoStart: !1, speed: 4e3},
                thumbs: {autoStart: !1, hideOnClose: !0, parentEl: ".fancybox-container", axis: "y"},
                wheel: "auto",
                onInit: g.noop,
                beforeLoad: g.noop,
                afterLoad: g.noop,
                beforeShow: g.noop,
                afterShow: g.noop,
                beforeClose: g.noop,
                afterClose: g.noop,
                onActivate: g.noop,
                onDeactivate: g.noop,
                clickContent: function (e, t) {
                    return "image" === e.type && "zoom"
                },
                clickSlide: "close",
                clickOutside: "close",
                dblclickContent: !1,
                dblclickSlide: !1,
                dblclickOutside: !1,
                mobile: {
                    idleTime: !1, clickContent: function (e, t) {
                        return "image" === e.type && "toggleControls"
                    }, clickSlide: function (e, t) {
                        return "image" === e.type ? "toggleControls" : "close"
                    }, dblclickContent: function (e, t) {
                        return "image" === e.type && "zoom"
                    }, dblclickSlide: function (e, t) {
                        return "image" === e.type && "zoom"
                    }
                },
                lang: "en",
                i18n: {
                    en: {
                        CLOSE: "Close",
                        NEXT: "Next",
                        PREV: "Previous",
                        ERROR: "The requested content cannot be loaded. <br/> Please try again later.",
                        PLAY_START: "Start slideshow",
                        PLAY_STOP: "Pause slideshow",
                        FULL_SCREEN: "Full screen",
                        THUMBS: "Thumbnails",
                        DOWNLOAD: "Download",
                        SHARE: "Share",
                        ZOOM: "Zoom"
                    },
                    de: {
                        CLOSE: "Schliessen",
                        NEXT: "Weiter",
                        PREV: "Zurück",
                        ERROR: "Die angeforderten Daten konnten nicht geladen werden. <br/> Bitte versuchen Sie es später nochmal.",
                        PLAY_START: "Diaschau starten",
                        PLAY_STOP: "Diaschau beenden",
                        FULL_SCREEN: "Vollbild",
                        THUMBS: "Vorschaubilder",
                        DOWNLOAD: "Herunterladen",
                        SHARE: "Teilen",
                        ZOOM: "Maßstab"
                    }
                }
            }, o = g(u), a = g(s), r = 0,
            f = u.requestAnimationFrame || u.webkitRequestAnimationFrame || u.mozRequestAnimationFrame || u.oRequestAnimationFrame || function (e) {
                return u.setTimeout(e, 1e3 / 60)
            }, h = function () {
                var e, t = s.createElement("fakeelement"), n = {
                    transition: "transitionend",
                    OTransition: "oTransitionEnd",
                    MozTransition: "transitionend",
                    WebkitTransition: "webkitTransitionEnd"
                };
                for (e in n) if (t.style[e] !== m) return n[e];
                return "transitionend"
            }(), p = function (e) {
                return e && e.length && e[0].offsetHeight
            }, l = function (e, t) {
                var n = g.extend(!0, {}, e, t);
                return g.each(t, function (e, t) {
                    g.isArray(t) && (n[e] = t)
                }), n
            }, i = function (e, t, n) {
                var i = this;
                i.opts = l({index: n}, g.fancybox.defaults), g.isPlainObject(t) && (i.opts = l(i.opts, t)), g.fancybox.isMobile && (i.opts = l(i.opts, i.opts.mobile)), i.id = i.opts.id || ++r, i.currIndex = parseInt(i.opts.index, 10) || 0, i.prevIndex = null, i.prevPos = null, i.currPos = 0, i.firstRun = !0, i.group = [], i.slides = {}, i.addContent(e), i.group.length && (i.$lastFocus = g(s.activeElement).trigger("blur"), i.init())
            };
        g.extend(i.prototype, {
            init: function () {
                var e, t, n, i = this, o = i.group[i.currIndex].opts, r = g.fancybox.scrollbarWidth;
                g.fancybox.getInstance() || !1 === o.hideScrollbar || (g("body").addClass("fancybox-active"), !g.fancybox.isMobile && s.body.scrollHeight > u.innerHeight && (r === m && (e = g('<div style="width:100px;height:100px;overflow:scroll;" />').appendTo("body"), r = g.fancybox.scrollbarWidth = e[0].offsetWidth - e[0].clientWidth, e.remove()), g("head").append('<style id="fancybox-style-noscroll" type="text/css">.compensate-for-scrollbar { margin-right: ' + r + "px; }</style>"), g("body").addClass("compensate-for-scrollbar"))), n = "", g.each(o.buttons, function (e, t) {
                    n += o.btnTpl[t] || ""
                }), t = g(i.translate(i, o.baseTpl.replace("{{buttons}}", n).replace("{{arrows}}", o.btnTpl.arrowLeft + o.btnTpl.arrowRight))).attr("id", "fancybox-container-" + i.id).addClass("fancybox-is-hidden").addClass(o.baseClass).data("FancyBox", i).appendTo(o.parentEl), i.$refs = {container: t}, ["bg", "inner", "infobar", "toolbar", "stage", "caption", "navigation"].forEach(function (e) {
                    i.$refs[e] = t.find(".fancybox-" + e)
                }), i.trigger("onInit"), i.activate(), i.jumpTo(i.currIndex)
            }, translate: function (e, t) {
                var i = e.opts.i18n[e.opts.lang];
                return t.replace(/\{\{(\w+)\}\}/g, function (e, t) {
                    var n = i[t];
                    return n === m ? e : n
                })
            }, addContent: function (e) {
                var t, c = this, n = g.makeArray(e);
                g.each(n, function (e, t) {
                    var n, i, o, r, s, a = {}, l = {};
                    g.isPlainObject(t) ? l = (a = t).opts || t : "object" === g.type(t) && g(t).length ? (l = (n = g(t)).data() || {}, (l = g.extend(!0, {}, l, l.options)).$orig = n, a.src = c.opts.src || l.src || n.attr("href"), a.type || a.src || (a.type = "inline", a.src = t)) : a = {
                        type: "html",
                        src: t + ""
                    }, a.opts = g.extend(!0, {}, c.opts, l), g.isArray(l.buttons) && (a.opts.buttons = l.buttons), i = a.type || a.opts.type, r = a.src || "", !i && r && ((o = r.match(/\.(mp4|mov|ogv)((\?|#).*)?$/i)) ? (i = "video", a.opts.videoFormat || (a.opts.videoFormat = "video/" + ("ogv" === o[1] ? "ogg" : o[1]))) : r.match(/(^data:image\/[a-z0-9+\/=]*,)|(\.(jp(e|g|eg)|gif|png|bmp|webp|svg|ico)((\?|#).*)?$)/i) ? i = "image" : r.match(/\.(pdf)((\?|#).*)?$/i) ? i = "iframe" : "#" === r.charAt(0) && (i = "inline")), i ? a.type = i : c.trigger("objectNeedsType", a), a.contentType || (a.contentType = -1 < g.inArray(a.type, ["html", "inline", "ajax"]) ? "html" : a.type), a.index = c.group.length, "auto" == a.opts.smallBtn && (a.opts.smallBtn = -1 < g.inArray(a.type, ["html", "inline", "ajax"])), "auto" === a.opts.toolbar && (a.opts.toolbar = !a.opts.smallBtn), a.opts.$trigger && a.index === c.opts.index && (a.opts.$thumb = a.opts.$trigger.find("img:first")), a.opts.$thumb && a.opts.$thumb.length || !a.opts.$orig || (a.opts.$thumb = a.opts.$orig.find("img:first")), "function" === g.type(a.opts.caption) && (a.opts.caption = a.opts.caption.apply(t, [c, a])), "function" === g.type(c.opts.caption) && (a.opts.caption = c.opts.caption.apply(t, [c, a])), a.opts.caption instanceof g || (a.opts.caption = a.opts.caption === m ? "" : a.opts.caption + ""), "ajax" !== a.type || 1 < (s = r.split(/\s+/, 2)).length && (a.src = s.shift(), a.opts.filter = s.shift()), a.opts.modal && (a.opts = g.extend(!0, a.opts, {
                        infobar: 0,
                        toolbar: 0,
                        smallBtn: 0,
                        keyboard: 0,
                        slideShow: 0,
                        fullScreen: 0,
                        thumbs: 0,
                        touch: 0,
                        clickContent: !1,
                        clickSlide: !1,
                        clickOutside: !1,
                        dblclickContent: !1,
                        dblclickSlide: !1,
                        dblclickOutside: !1
                    })), c.group.push(a)
                }), Object.keys(c.slides).length && (c.updateControls(), (t = c.Thumbs) && t.isActive && (t.create(), t.focus()))
            }, addEvents: function () {
                var i = this;
                i.removeEvents(), i.$refs.container.on("click.fb-close", "[data-fancybox-close]", function (e) {
                    e.stopPropagation(), e.preventDefault(), i.close(e)
                }).on("touchstart.fb-prev click.fb-prev", "[data-fancybox-prev]", function (e) {
                    e.stopPropagation(), e.preventDefault(), i.previous()
                }).on("touchstart.fb-next click.fb-next", "[data-fancybox-next]", function (e) {
                    e.stopPropagation(), e.preventDefault(), i.next()
                }).on("click.fb", "[data-fancybox-zoom]", function (e) {
                    i[i.isScaledDown() ? "scaleToActual" : "scaleToFit"]()
                }), o.on("orientationchange.fb resize.fb", function (e) {
                    e && e.originalEvent && "resize" === e.originalEvent.type ? f(function () {
                        i.update()
                    }) : (i.$refs.stage.hide(), setTimeout(function () {
                        i.$refs.stage.show(), i.update()
                    }, g.fancybox.isMobile ? 600 : 250))
                }), a.on("focusin.fb", function (e) {
                    var t = g.fancybox ? g.fancybox.getInstance() : null;
                    t.isClosing || !t.current || !t.current.opts.trapFocus || g(e.target).hasClass("fancybox-container") || g(e.target).is(s) || t && "fixed" !== g(e.target).css("position") && !t.$refs.container.has(e.target).length && (e.stopPropagation(), t.focus())
                }), a.on("keydown.fb", function (e) {
                    var t = i.current, n = e.keyCode || e.which;
                    if (t && t.opts.keyboard && !(e.ctrlKey || e.altKey || e.shiftKey || g(e.target).is("input") || g(e.target).is("textarea"))) return 8 === n || 27 === n ? (e.preventDefault(), void i.close(e)) : 37 === n || 38 === n ? (e.preventDefault(), void i.previous()) : 39 === n || 40 === n ? (e.preventDefault(), void i.next()) : void i.trigger("afterKeydown", e, n)
                }), i.group[i.currIndex].opts.idleTime && (i.idleSecondsCounter = 0, a.on("mousemove.fb-idle mouseleave.fb-idle mousedown.fb-idle touchstart.fb-idle touchmove.fb-idle scroll.fb-idle keydown.fb-idle", function (e) {
                    i.idleSecondsCounter = 0, i.isIdle && i.showControls(), i.isIdle = !1
                }), i.idleInterval = u.setInterval(function () {
                    i.idleSecondsCounter++, i.idleSecondsCounter >= i.group[i.currIndex].opts.idleTime && !i.isDragging && (i.isIdle = !0, i.idleSecondsCounter = 0, i.hideControls())
                }, 1e3))
            }, removeEvents: function () {
                o.off("orientationchange.fb resize.fb"), a.off("focusin.fb keydown.fb .fb-idle"), this.$refs.container.off(".fb-close .fb-prev .fb-next"), this.idleInterval && (u.clearInterval(this.idleInterval), this.idleInterval = null)
            }, previous: function (e) {
                return this.jumpTo(this.currPos - 1, e)
            }, next: function (e) {
                return this.jumpTo(this.currPos + 1, e)
            }, jumpTo: function (e, i) {
                var t, n, o, r, s, a, l, c = this, u = c.group.length;
                if (!(c.isDragging || c.isClosing || c.isAnimating && c.firstRun)) {
                    if (e = parseInt(e, 10), !(n = c.current ? c.current.opts.loop : c.opts.loop) && (e < 0 || u <= e)) return !1;
                    if (t = c.firstRun = !Object.keys(c.slides).length, !(u < 2 && !t && c.isDragging)) {
                        if (r = c.current, c.prevIndex = c.currIndex, c.prevPos = c.currPos, o = c.createSlide(e), 1 < u && ((n || 0 < o.index) && c.createSlide(e - 1), (n || o.index < u - 1) && c.createSlide(e + 1)), c.current = o, c.currIndex = o.index, c.currPos = o.pos, c.trigger("beforeShow", t), c.updateControls(), a = g.fancybox.getTranslate(o.$slide), o.isMoved = (0 !== a.left || 0 !== a.top) && !o.$slide.hasClass("fancybox-animated"), o.forcedDuration = m, g.isNumeric(i) ? o.forcedDuration = i : i = o.opts[t ? "animationDuration" : "transitionDuration"], i = parseInt(i, 10), t) return o.opts.animationEffect && i && c.$refs.container.css("transition-duration", i + "ms"), c.$refs.container.removeClass("fancybox-is-hidden"), p(c.$refs.container), c.$refs.container.addClass("fancybox-is-open"), p(c.$refs.container), o.$slide.addClass("fancybox-slide--previous"), c.loadSlide(o), o.$slide.removeClass("fancybox-slide--previous").addClass("fancybox-slide--current"), void c.preload("image");
                        g.each(c.slides, function (e, t) {
                            g.fancybox.stop(t.$slide)
                        }), o.$slide.removeClass("fancybox-slide--next fancybox-slide--previous").addClass("fancybox-slide--current"), o.isMoved ? (s = Math.round(o.$slide.width()), g.each(c.slides, function (e, t) {
                            var n = t.pos - o.pos;
                            g.fancybox.animate(t.$slide, {top: 0, left: n * s + n * t.opts.gutter}, i, function () {
                                t.$slide.removeAttr("style").removeClass("fancybox-slide--next fancybox-slide--previous"), t.pos === c.currPos && (o.isMoved = !1, c.complete())
                            })
                        })) : c.$refs.stage.children().removeAttr("style"), o.isLoaded ? c.revealContent(o) : c.loadSlide(o), c.preload("image"), r.pos !== o.pos && (l = "fancybox-slide--" + (r.pos > o.pos ? "next" : "previous"), r.$slide.removeClass("fancybox-slide--complete fancybox-slide--current fancybox-slide--next fancybox-slide--previous"), r.isComplete = !1, i && (o.isMoved || o.opts.transitionEffect) && (o.isMoved ? r.$slide.addClass(l) : (l = "fancybox-animated " + l + " fancybox-fx-" + o.opts.transitionEffect, g.fancybox.animate(r.$slide, l, i, function () {
                            r.$slide.removeClass(l).removeAttr("style")
                        }))))
                    }
                }
            }, createSlide: function (e) {
                var t, n, i = this;
                return n = (n = e % i.group.length) < 0 ? i.group.length + n : n, !i.slides[e] && i.group[n] && (t = g('<div class="fancybox-slide"></div>').appendTo(i.$refs.stage), i.slides[e] = g.extend(!0, {}, i.group[n], {
                    pos: e,
                    $slide: t,
                    isLoaded: !1
                }), i.updateSlide(i.slides[e])), i.slides[e]
            }, scaleToActual: function (e, t, n) {
                var i, o, r, s, a, l = this, c = l.current, u = c.$content, d = g.fancybox.getTranslate(c.$slide).width,
                    f = g.fancybox.getTranslate(c.$slide).height, h = c.width, p = c.height;
                !l.isAnimating && u && "image" == c.type && c.isLoaded && !c.hasError && (g.fancybox.stop(u), l.isAnimating = !0, e = e === m ? .5 * d : e, t = t === m ? .5 * f : t, (i = g.fancybox.getTranslate(u)).top -= g.fancybox.getTranslate(c.$slide).top, i.left -= g.fancybox.getTranslate(c.$slide).left, s = h / i.width, a = p / i.height, o = .5 * d - .5 * h, r = .5 * f - .5 * p, d < h && (0 < (o = i.left * s - (e * s - e)) && (o = 0), o < d - h && (o = d - h)), f < p && (0 < (r = i.top * a - (t * a - t)) && (r = 0), r < f - p && (r = f - p)), l.updateCursor(h, p), g.fancybox.animate(u, {
                    top: r,
                    left: o,
                    scaleX: s,
                    scaleY: a
                }, n || 330, function () {
                    l.isAnimating = !1
                }), l.SlideShow && l.SlideShow.isActive && l.SlideShow.stop())
            }, scaleToFit: function (e) {
                var t, n = this, i = n.current, o = i.$content;
                !n.isAnimating && o && "image" == i.type && i.isLoaded && !i.hasError && (g.fancybox.stop(o), n.isAnimating = !0, t = n.getFitPos(i), n.updateCursor(t.width, t.height), g.fancybox.animate(o, {
                    top: t.top,
                    left: t.left,
                    scaleX: t.width / o.width(),
                    scaleY: t.height / o.height()
                }, e || 330, function () {
                    n.isAnimating = !1
                }))
            }, getFitPos: function (e) {
                var t, n, i, o, r, s = e.$content, a = e.width || e.opts.width, l = e.height || e.opts.height, c = {};
                return !!(e.isLoaded && s && s.length) && (o = {
                    top: parseInt(e.$slide.css("paddingTop"), 10),
                    right: parseInt(e.$slide.css("paddingRight"), 10),
                    bottom: parseInt(e.$slide.css("paddingBottom"), 10),
                    left: parseInt(e.$slide.css("paddingLeft"), 10)
                }, t = parseInt(this.$refs.stage.width(), 10) - (o.left + o.right), n = parseInt(this.$refs.stage.height(), 10) - (o.top + o.bottom), a && l || (a = t, l = n), i = Math.min(1, t / a, n / l), a = Math.floor(i * a), l = Math.floor(i * l), "image" === e.type ? (c.top = Math.floor(.5 * (n - l)) + o.top, c.left = Math.floor(.5 * (t - a)) + o.left) : "video" === e.contentType && (a / (r = e.opts.width && e.opts.height ? a / l : e.opts.ratio || 16 / 9) < l ? l = a / r : l * r < a && (a = l * r)), c.width = a, c.height = l, c)
            }, update: function () {
                var n = this;
                g.each(n.slides, function (e, t) {
                    n.updateSlide(t)
                })
            }, updateSlide: function (e, t) {
                var n = this, i = e && e.$content, o = e.width || e.opts.width, r = e.height || e.opts.height;
                i && (o || r || "video" === e.contentType) && !e.hasError && (g.fancybox.stop(i), g.fancybox.setTranslate(i, n.getFitPos(e)), e.pos === n.currPos && (n.isAnimating = !1, n.updateCursor())), e.$slide.trigger("refresh"), n.$refs.toolbar.toggleClass("compensate-for-scrollbar", e.$slide.get(0).scrollHeight > e.$slide.get(0).clientHeight), n.trigger("onUpdate", e)
            }, centerSlide: function (e, t) {
                var n, i;
                this.current && (n = Math.round(e.$slide.width()), i = e.pos - this.current.pos, g.fancybox.animate(e.$slide, {
                    top: 0,
                    left: i * n + i * e.opts.gutter,
                    opacity: 1
                }, t === m ? 0 : t, null, !1))
            }, updateCursor: function (e, t) {
                var n, i = this, o = i.current,
                    r = i.$refs.container.removeClass("fancybox-is-zoomable fancybox-can-zoomIn fancybox-can-drag fancybox-can-zoomOut");
                o && !i.isClosing && (n = i.isZoomable(), r.toggleClass("fancybox-is-zoomable", n), g("[data-fancybox-zoom]").prop("disabled", !n), n && ("zoom" === o.opts.clickContent || g.isFunction(o.opts.clickContent) && "zoom" === o.opts.clickContent(o)) ? i.isScaledDown(e, t) ? r.addClass("fancybox-can-zoomIn") : o.opts.touch ? r.addClass("fancybox-can-drag") : r.addClass("fancybox-can-zoomOut") : o.opts.touch && "video" !== o.contentType && r.addClass("fancybox-can-drag"))
            }, isZoomable: function () {
                var e, t = this.current;
                if (t && !this.isClosing && "image" === t.type && !t.hasError) {
                    if (!t.isLoaded) return !0;
                    if (e = this.getFitPos(t), t.width > e.width || t.height > e.height) return !0
                }
                return !1
            }, isScaledDown: function (e, t) {
                var n = !1, i = this.current, o = i.$content;
                return e !== m && t !== m ? n = e < i.width && t < i.height : o && (n = (n = g.fancybox.getTranslate(o)).width < i.width && n.height < i.height), n
            }, canPan: function () {
                var e, t = !1, n = this.current;
                return "image" === n.type && (e = n.$content) && !n.hasError && (t = this.getFitPos(n), t = 1 < Math.abs(e.width() - t.width) || 1 < Math.abs(e.height() - t.height)), t
            }, loadSlide: function (n) {
                var e, t, i, o = this;
                if (!n.isLoading && !n.isLoaded) {
                    switch (n.isLoading = !0, o.trigger("beforeLoad", n), e = n.type, (t = n.$slide).off("refresh").trigger("onReset").addClass(n.opts.slideClass), e) {
                        case"image":
                            o.setImage(n);
                            break;
                        case"iframe":
                            o.setIframe(n);
                            break;
                        case"html":
                            o.setContent(n, n.src || n.content);
                            break;
                        case"video":
                            o.setContent(n, '<video class="fancybox-video" controls controlsList="nodownload"><source src="' + n.src + '" type="' + n.opts.videoFormat + "\">Your browser doesn't support HTML5 video</video");
                            break;
                        case"inline":
                            g(n.src).length ? o.setContent(n, g(n.src)) : o.setError(n);
                            break;
                        case"ajax":
                            o.showLoading(n), i = g.ajax(g.extend({}, n.opts.ajax.settings, {
                                url: n.src,
                                success: function (e, t) {
                                    "success" === t && o.setContent(n, e)
                                },
                                error: function (e, t) {
                                    e && "abort" !== t && o.setError(n)
                                }
                            })), t.one("onReset", function () {
                                i.abort()
                            });
                            break;
                        default:
                            o.setError(n)
                    }
                    return !0
                }
            }, setImage: function (t) {
                var e, n, i, o, r, s = this, a = t.opts.srcset || t.opts.image.srcset;
                if (t.timouts = setTimeout(function () {
                    var e = t.$image;
                    !t.isLoading || e && e[0].complete || t.hasError || s.showLoading(t)
                }, 350), a) {
                    o = u.devicePixelRatio || 1, r = u.innerWidth * o, (i = a.split(",").map(function (e) {
                        var i = {};
                        return e.trim().split(/\s+/).forEach(function (e, t) {
                            var n = parseInt(e.substring(0, e.length - 1), 10);
                            return 0 === t ? i.url = e : void (n && (i.value = n, i.postfix = e[e.length - 1]))
                        }), i
                    })).sort(function (e, t) {
                        return e.value - t.value
                    });
                    for (var l = 0; l < i.length; l++) {
                        var c = i[l];
                        if ("w" === c.postfix && c.value >= r || "x" === c.postfix && c.value >= o) {
                            n = c;
                            break
                        }
                    }
                    !n && i.length && (n = i[i.length - 1]), n && (t.src = n.url, t.width && t.height && "w" == n.postfix && (t.height = t.width / t.height * n.value, t.width = n.value), t.opts.srcset = a)
                }
                t.$content = g('<div class="fancybox-content"></div>').addClass("fancybox-is-hidden").appendTo(t.$slide.addClass("fancybox-slide--image")), e = t.opts.thumb || !(!t.opts.$thumb || !t.opts.$thumb.length) && t.opts.$thumb.attr("src"), !1 !== t.opts.preload && t.opts.width && t.opts.height && e && (t.width = t.opts.width, t.height = t.opts.height, t.$ghost = g("<img />").one("error", function () {
                    g(this).remove(), t.$ghost = null
                }).one("load", function () {
                    s.afterLoad(t)
                }).addClass("fancybox-image").appendTo(t.$content).attr("src", e)), s.setBigImage(t)
            }, setBigImage: function (t) {
                var n = this, i = g("<img />");
                t.$image = i.one("error", function () {
                    n.setError(t)
                }).one("load", function () {
                    var e;
                    t.$ghost || (n.resolveImageSlideSize(t, this.naturalWidth, this.naturalHeight), n.afterLoad(t)), t.timouts && (clearTimeout(t.timouts), t.timouts = null), n.isClosing || (t.opts.srcset && ((e = t.opts.sizes) && "auto" !== e || (e = (1 < t.width / t.height && 1 < o.width() / o.height() ? "100" : Math.round(t.width / t.height * 100)) + "vw"), i.attr("sizes", e).attr("srcset", t.opts.srcset)), t.$ghost && setTimeout(function () {
                        t.$ghost && !n.isClosing && t.$ghost.hide()
                    }, Math.min(300, Math.max(1e3, t.height / 1600))), n.hideLoading(t))
                }).addClass("fancybox-image").attr("src", t.src).appendTo(t.$content), (i[0].complete || "complete" == i[0].readyState) && i[0].naturalWidth && i[0].naturalHeight ? i.trigger("load") : i[0].error && i.trigger("error")
            }, resolveImageSlideSize: function (e, t, n) {
                var i = parseInt(e.opts.width, 10), o = parseInt(e.opts.height, 10);
                e.width = t, e.height = n, 0 < i && (e.width = i, e.height = Math.floor(i * n / t)), 0 < o && (e.width = Math.floor(o * t / n), e.height = o)
            }, setIframe: function (o) {
                var r, t = this, s = o.opts.iframe, e = o.$slide;
                o.$content = g('<div class="fancybox-content' + (s.preload ? " fancybox-is-hidden" : "") + '"></div>').css(s.css).appendTo(e), e.addClass("fancybox-slide--" + o.contentType), o.$iframe = r = g(s.tpl.replace(/\{rnd\}/g, (new Date).getTime())).attr(s.attr).appendTo(o.$content), s.preload ? (t.showLoading(o), r.on("load.fb error.fb", function (e) {
                    this.isReady = 1, o.$slide.trigger("refresh"), t.afterLoad(o)
                }), e.on("refresh.fb", function () {
                    var e, t = o.$content, n = s.css.width, i = s.css.height;
                    if (1 === r[0].isReady) {
                        try {
                            e = r.contents().find("body")
                        } catch (e) {
                        }
                        e && e.length && e.children().length && (t.css({
                            width: "",
                            height: ""
                        }), n === m && (n = Math.ceil(Math.max(e[0].clientWidth, e.outerWidth(!0)))), n && t.width(n), i === m && (i = Math.ceil(Math.max(e[0].clientHeight, e.outerHeight(!0)))), i && t.height(i)), t.removeClass("fancybox-is-hidden")
                    }
                })) : this.afterLoad(o), r.attr("src", o.src), e.one("onReset", function () {
                    try {
                        g(this).find("iframe").hide().unbind().attr("src", "//about:blank")
                    } catch (e) {
                    }
                    g(this).off("refresh.fb").empty(), o.isLoaded = !1
                })
            }, setContent: function (e, t) {
                var n;
                this.isClosing || (this.hideLoading(e), e.$content && g.fancybox.stop(e.$content), e.$slide.empty(), (n = t) && n.hasOwnProperty && n instanceof g && t.parent().length ? (t.parent().parent(".fancybox-slide--inline").trigger("onReset"), e.$placeholder = g("<div>").hide().insertAfter(t), t.css("display", "inline-block")) : e.hasError || ("string" !== g.type(t) || 3 === (t = g("<div>").append(g.trim(t)).contents())[0].nodeType && (t = g("<div>").html(t)), e.opts.filter && (t = g("<div>").html(t).find(e.opts.filter))), e.$slide.one("onReset", function () {
                    g(this).find("video,audio").trigger("pause"), e.$placeholder && (e.$placeholder.after(t.hide()).remove(), e.$placeholder = null), e.$smallBtn && (e.$smallBtn.remove(), e.$smallBtn = null), e.hasError || (g(this).empty(), e.isLoaded = !1)
                }), g(t).appendTo(e.$slide), g(t).is("video,audio") && (g(t).addClass("fancybox-video"), g(t).wrap("<div></div>"), e.contentType = "video", e.opts.width = e.opts.width || g(t).attr("width"), e.opts.height = e.opts.height || g(t).attr("height")), e.$content = e.$slide.children().filter("div,form,main,video,audio").first().addClass("fancybox-content"), e.$slide.addClass("fancybox-slide--" + e.contentType), this.afterLoad(e))
            }, setError: function (e) {
                e.hasError = !0, e.$slide.trigger("onReset").removeClass("fancybox-slide--" + e.contentType).addClass("fancybox-slide--error"), e.contentType = "html", this.setContent(e, this.translate(e, e.opts.errorTpl)), e.pos === this.currPos && (this.isAnimating = !1)
            }, showLoading: function (e) {
                (e = e || this.current) && !e.$spinner && (e.$spinner = g(this.translate(this, this.opts.spinnerTpl)).appendTo(e.$slide))
            }, hideLoading: function (e) {
                (e = e || this.current) && e.$spinner && (e.$spinner.remove(), delete e.$spinner)
            }, afterLoad: function (e) {
                var t = this;
                t.isClosing || (e.isLoading = !1, e.isLoaded = !0, t.trigger("afterLoad", e), t.hideLoading(e), e.pos === t.currPos && t.updateCursor(), !e.opts.smallBtn || e.$smallBtn && e.$smallBtn.length || (e.$smallBtn = g(t.translate(e, e.opts.btnTpl.smallBtn)).prependTo(e.$content)), e.opts.protect && e.$content && !e.hasError && (e.$content.on("contextmenu.fb", function (e) {
                    return 2 == e.button && e.preventDefault(), !0
                }), "image" === e.type && g('<div class="fancybox-spaceball"></div>').appendTo(e.$content)), t.revealContent(e))
            }, revealContent: function (t) {
                var e, n, i, o, r = this, s = t.$slide, a = !1, l = !1;
                return e = t.opts[r.firstRun ? "animationEffect" : "transitionEffect"], i = t.opts[r.firstRun ? "animationDuration" : "transitionDuration"], i = parseInt(t.forcedDuration === m ? i : t.forcedDuration, 10), t.pos === r.currPos && (t.isComplete ? e = !1 : r.isAnimating = !0), !t.isMoved && t.pos === r.currPos && i || (e = !1), "zoom" === e && (t.pos === r.currPos && i && "image" === t.type && !t.hasError && (l = r.getThumbPos(t)) ? a = r.getFitPos(t) : e = "fade"), "zoom" === e ? (a.scaleX = a.width / l.width, a.scaleY = a.height / l.height, "auto" == (o = t.opts.zoomOpacity) && (o = .1 < Math.abs(t.width / t.height - l.width / l.height)), o && (l.opacity = .1, a.opacity = 1), g.fancybox.setTranslate(t.$content.removeClass("fancybox-is-hidden"), l), p(t.$content), void g.fancybox.animate(t.$content, a, i, function () {
                    r.isAnimating = !1, r.complete()
                })) : (r.updateSlide(t), e ? (g.fancybox.stop(s), n = "fancybox-animated fancybox-slide--" + (t.pos >= r.prevPos ? "next" : "previous") + " fancybox-fx-" + e, s.removeAttr("style").removeClass("fancybox-slide--current fancybox-slide--next fancybox-slide--previous").addClass(n), t.$content.removeClass("fancybox-is-hidden"), p(s), void g.fancybox.animate(s, "fancybox-slide--current", i, function (e) {
                    s.removeClass(n).removeAttr("style"), t.pos === r.currPos && r.complete()
                }, !0)) : (p(s), t.$content.removeClass("fancybox-is-hidden"), void (t.pos === r.currPos && r.complete())))
            }, getThumbPos: function (e) {
                var t, n = !1, i = e.opts.$thumb, o = i && i.length && i[0].ownerDocument === s ? i.offset() : 0;
                return o && function (e) {
                    for (var t = e[0], i = t.getBoundingClientRect(), n = []; null !== t.parentElement;) "hidden" !== g(t.parentElement).css("overflow") && "auto" !== g(t.parentElement).css("overflow") || n.push(t.parentElement.getBoundingClientRect()), t = t.parentElement;
                    return n.every(function (e) {
                        var t = Math.min(i.right, e.right) - Math.max(i.left, e.left),
                            n = Math.min(i.bottom, e.bottom) - Math.max(i.top, e.top);
                        return 0 < t && 0 < n
                    }) && 0 < i.bottom && 0 < i.right && i.left < g(u).width() && i.top < g(u).height()
                }(i) && (t = this.$refs.stage.offset(), n = {
                    top: o.top - t.top + parseFloat(i.css("border-top-width") || 0),
                    left: o.left - t.left + parseFloat(i.css("border-left-width") || 0),
                    width: i.width(),
                    height: i.height(),
                    scaleX: 1,
                    scaleY: 1
                }), n
            }, complete: function () {
                var n = this, e = n.current, i = {};
                !e.isMoved && e.isLoaded && (e.isComplete || (e.isComplete = !0, e.$slide.siblings().trigger("onReset"), n.preload("inline"), p(e.$slide), e.$slide.addClass("fancybox-slide--complete"), g.each(n.slides, function (e, t) {
                    t.pos >= n.currPos - 1 && t.pos <= n.currPos + 1 ? i[t.pos] = t : t && (g.fancybox.stop(t.$slide), t.$slide.off().remove())
                }), n.slides = i), n.isAnimating = !1, n.updateCursor(), n.trigger("afterShow"), e.$slide.find("video,audio").filter(":visible:first").trigger("play"), (g(s.activeElement).is("[disabled]") || e.opts.autoFocus && "image" != e.type && "iframe" !== e.type) && n.focus())
            }, preload: function (e) {
                var t = this, n = t.slides[t.currPos + 1], i = t.slides[t.currPos - 1];
                n && n.type === e && t.loadSlide(n), i && i.type === e && t.loadSlide(i)
            }, focus: function () {
                var e, t = this.current;
                this.isClosing || t && t.isComplete && t.$content && ((e = t.$content.find("input[autofocus]:enabled:visible:first")).length || (e = t.$content.find("button,:input,[tabindex],a").filter(":enabled:visible:first")), (e = e && e.length ? e : t.$content).trigger("focus"))
            }, activate: function () {
                var t = this;
                g(".fancybox-container").each(function () {
                    var e = g(this).data("FancyBox");
                    e && e.id !== t.id && !e.isClosing && (e.trigger("onDeactivate"), e.removeEvents(), e.isVisible = !1)
                }), t.isVisible = !0, (t.current || t.isIdle) && (t.update(), t.updateControls()), t.trigger("onActivate"), t.addEvents()
            }, close: function (e, t) {
                function n() {
                    u.cleanUp(e)
                }

                var i, o, r, s, a, l, c, u = this, d = u.current;
                return !u.isClosing && (!(u.isClosing = !0) === u.trigger("beforeClose", e) ? (u.isClosing = !1, f(function () {
                    u.update()
                }), !1) : (u.removeEvents(), d.timouts && clearTimeout(d.timouts), r = d.$content, i = d.opts.animationEffect, o = g.isNumeric(t) ? t : i ? d.opts.animationDuration : 0, d.$slide.off(h).removeClass("fancybox-slide--complete fancybox-slide--next fancybox-slide--previous fancybox-animated"), d.$slide.siblings().trigger("onReset").remove(), o && u.$refs.container.removeClass("fancybox-is-open").addClass("fancybox-is-closing"), u.hideLoading(d), u.hideControls(), u.updateCursor(), "zoom" !== i || !0 !== e && r && o && "image" === d.type && !d.hasError && (c = u.getThumbPos(d)) || (i = "fade"), "zoom" === i ? (g.fancybox.stop(r), l = {
                    top: (s = g.fancybox.getTranslate(r)).top,
                    left: s.left,
                    scaleX: s.width / c.width,
                    scaleY: s.height / c.height,
                    width: c.width,
                    height: c.height
                }, "auto" == (a = d.opts.zoomOpacity) && (a = .1 < Math.abs(d.width / d.height - c.width / c.height)), a && (c.opacity = 0), g.fancybox.setTranslate(r, l), p(r), g.fancybox.animate(r, c, o, n)) : i && o ? !0 === e ? setTimeout(n, o) : g.fancybox.animate(d.$slide.removeClass("fancybox-slide--current"), "fancybox-animated fancybox-slide--previous fancybox-fx-" + i, o, n) : n(), !0))
            }, cleanUp: function (e) {
                var t, n = this, i = g("body");
                n.current.$slide.trigger("onReset"), n.$refs.container.empty().remove(), n.trigger("afterClose", e), n.$lastFocus && n.current.opts.backFocus && n.$lastFocus.trigger("focus"), n.current = null, (t = g.fancybox.getInstance()) ? t.activate() : (i.removeClass("fancybox-active compensate-for-scrollbar"), g("#fancybox-style-noscroll").remove())
            }, trigger: function (e, t) {
                var n, i = Array.prototype.slice.call(arguments, 1), o = this, r = t && t.opts ? t : o.current;
                return r ? i.unshift(r) : r = o, i.unshift(o), g.isFunction(r.opts[e]) && (n = r.opts[e].apply(r, i)), !1 === n ? n : void ("afterClose" !== e && o.$refs ? o.$refs.container.trigger(e + ".fb", i) : a.trigger(e + ".fb", i))
            }, updateControls: function (e) {
                var t = this, n = t.current, i = n.index, o = n.opts.caption, r = t.$refs.container,
                    s = t.$refs.caption;
                n.$slide.trigger("refresh"), t.$caption = o && o.length ? s.html(o) : null, t.isHiddenControls || t.isIdle || t.showControls(), r.find("[data-fancybox-count]").html(t.group.length), r.find("[data-fancybox-index]").html(i + 1), r.find("[data-fancybox-prev]").toggleClass("disabled", !n.opts.loop && i <= 0), r.find("[data-fancybox-next]").toggleClass("disabled", !n.opts.loop && i >= t.group.length - 1), "image" === n.type ? r.find("[data-fancybox-zoom]").show().end().find("[data-fancybox-download]").attr("href", n.opts.image.src || n.src).show() : n.opts.toolbar && r.find("[data-fancybox-download],[data-fancybox-zoom]").hide()
            }, hideControls: function () {
                this.isHiddenControls = !0, this.$refs.container.removeClass("fancybox-show-infobar fancybox-show-toolbar fancybox-show-caption fancybox-show-nav")
            }, showControls: function () {
                var e = this, t = e.current ? e.current.opts : e.opts, n = e.$refs.container;
                e.isHiddenControls = !1, e.idleSecondsCounter = 0, n.toggleClass("fancybox-show-toolbar", !(!t.toolbar || !t.buttons)).toggleClass("fancybox-show-infobar", !!(t.infobar && 1 < e.group.length)).toggleClass("fancybox-show-nav", !!(t.arrows && 1 < e.group.length)).toggleClass("fancybox-is-modal", !!t.modal), e.$caption ? n.addClass("fancybox-show-caption ") : n.removeClass("fancybox-show-caption")
            }, toggleControls: function () {
                this.isHiddenControls ? this.showControls() : this.hideControls()
            }
        }), g.fancybox = {
            version: "3.3.5",
            defaults: e,
            getInstance: function (e) {
                var t = g('.fancybox-container:not(".fancybox-is-closing"):last').data("FancyBox"),
                    n = Array.prototype.slice.call(arguments, 1);
                return t instanceof i && ("string" === g.type(e) ? t[e].apply(t, n) : "function" === g.type(e) && e.apply(t, n), t)
            },
            open: function (e, t, n) {
                return new i(e, t, n)
            },
            close: function (e) {
                var t = this.getInstance();
                t && (t.close(), !0 === e && this.close())
            },
            destroy: function () {
                this.close(!0), a.add("body").off("click.fb-start", "**")
            },
            isMobile: s.createTouch !== m && /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
            use3d: (t = s.createElement("div"), u.getComputedStyle && u.getComputedStyle(t) && u.getComputedStyle(t).getPropertyValue("transform") && !(s.documentMode && s.documentMode < 11)),
            getTranslate: function (e) {
                var t;
                return !(!e || !e.length) && {
                    top: (t = e[0].getBoundingClientRect()).top || 0,
                    left: t.left || 0,
                    width: t.width,
                    height: t.height,
                    opacity: parseFloat(e.css("opacity"))
                }
            },
            setTranslate: function (e, t) {
                var n = "", i = {};
                if (e && t) return t.left === m && t.top === m || (n = (t.left === m ? e.position().left : t.left) + "px, " + (t.top === m ? e.position().top : t.top) + "px", n = this.use3d ? "translate3d(" + n + ", 0px)" : "translate(" + n + ")"), t.scaleX !== m && t.scaleY !== m && (n = (n.length ? n + " " : "") + "scale(" + t.scaleX + ", " + t.scaleY + ")"), n.length && (i.transform = n), t.opacity !== m && (i.opacity = t.opacity), t.width !== m && (i.width = t.width), t.height !== m && (i.height = t.height), e.css(i)
            },
            animate: function (t, n, e, i, o) {
                var r = !1;
                g.isFunction(e) && (i = e, e = null), g.isPlainObject(n) || t.removeAttr("style"), g.fancybox.stop(t), t.on(h, function (e) {
                    e && e.originalEvent && (!t.is(e.originalEvent.target) || "z-index" == e.originalEvent.propertyName) || (g.fancybox.stop(t), r && g.fancybox.setTranslate(t, r), g.isPlainObject(n) ? !1 === o && t.removeAttr("style") : !0 !== o && t.removeClass(n), g.isFunction(i) && i(e))
                }), g.isNumeric(e) && t.css("transition-duration", e + "ms"), g.isPlainObject(n) ? (n.scaleX !== m && n.scaleY !== m && (r = g.extend({}, n, {
                    width: t.width() * n.scaleX,
                    height: t.height() * n.scaleY,
                    scaleX: 1,
                    scaleY: 1
                }), delete n.width, delete n.height, t.parent().hasClass("fancybox-slide--image") && t.parent().addClass("fancybox-is-scaling")), g.fancybox.setTranslate(t, n)) : t.addClass(n), t.data("timer", setTimeout(function () {
                    t.trigger("transitionend")
                }, e + 16))
            },
            stop: function (e) {
                e && e.length && (clearTimeout(e.data("timer")), e.off("transitionend").css("transition-duration", ""), e.parent().removeClass("fancybox-is-scaling"))
            }
        }, g.fn.fancybox = function (e) {
            var t;
            return (t = (e = e || {}).selector || !1) ? g("body").off("click.fb-start", t).on("click.fb-start", t, {options: e}, n) : this.off("click.fb-start").on("click.fb-start", {
                items: this,
                options: e
            }, n), this
        }, a.on("click.fb-start", "[data-fancybox]", n), a.on("click.fb-start", "[data-trigger]", function (e) {
            n(e, {
                $target: g('[data-fancybox="' + g(e.currentTarget).attr("data-trigger") + '"]').eq(g(e.currentTarget).attr("data-index") || 0),
                $trigger: g(this)
            })
        })
    }
    var t
}(window, document, window.jQuery || jQuery), function (h) {
    "use strict";

    function p(n, e, t) {
        if (n) return t = t || "", "object" === h.type(t) && (t = h.param(t, !0)), h.each(e, function (e, t) {
            n = n.replace("$" + e, t || "")
        }), t.length && (n += (0 < n.indexOf("?") ? "&" : "?") + t), n
    }

    var i = {
        youtube: {
            matcher: /(youtube\.com|youtu\.be|youtube\-nocookie\.com)\/(watch\?(.*&)?v=|v\/|u\/|embed\/?)?(videoseries\?list=(.*)|[\w-]{11}|\?listType=(.*)&list=(.*))(.*)/i,
            params: {autoplay: 1, autohide: 1, fs: 1, rel: 0, hd: 1, wmode: "transparent", enablejsapi: 1, html5: 1},
            paramPlace: 8,
            type: "iframe",
            url: "//www.youtube.com/embed/$4",
            thumb: "//img.youtube.com/vi/$4/hqdefault.jpg"
        },
        vimeo: {
            matcher: /^.+vimeo.com\/(.*\/)?([\d]+)(.*)?/,
            params: {autoplay: 1, hd: 1, show_title: 1, show_byline: 1, show_portrait: 0, fullscreen: 1, api: 1},
            paramPlace: 3,
            type: "iframe",
            url: "//player.vimeo.com/video/$2"
        },
        instagram: {
            matcher: /(instagr\.am|instagram\.com)\/p\/([a-zA-Z0-9_\-]+)\/?/i,
            type: "image",
            url: "//$1/p/$2/media/?size=l"
        },
        gmap_place: {
            matcher: /(maps\.)?google\.([a-z]{2,3}(\.[a-z]{2})?)\/(((maps\/(place\/(.*)\/)?\@(.*),(\d+.?\d+?)z))|(\?ll=))(.*)?/i,
            type: "iframe",
            url: function (e) {
                return "//maps.google." + e[2] + "/?ll=" + (e[9] ? e[9] + "&z=" + Math.floor(e[10]) + (e[12] ? e[12].replace(/^\//, "&") : "") : e[12] + "").replace(/\?/, "&") + "&output=" + (e[12] && 0 < e[12].indexOf("layer=c") ? "svembed" : "embed")
            }
        },
        gmap_search: {
            matcher: /(maps\.)?google\.([a-z]{2,3}(\.[a-z]{2})?)\/(maps\/search\/)(.*)/i,
            type: "iframe",
            url: function (e) {
                return "//maps.google." + e[2] + "/maps?q=" + e[5].replace("query=", "q=").replace("api=1", "") + "&output=embed"
            }
        }
    };
    h(document).on("objectNeedsType.fb", function (e, t, o) {
        var n, r, s, a, l, c, u, d = o.src || "", f = !1;
        n = h.extend(!0, {}, i, o.opts.media), h.each(n, function (e, t) {
            if (s = d.match(t.matcher)) {
                if (f = t.type, u = e, c = {}, t.paramPlace && s[t.paramPlace]) {
                    "?" == (l = s[t.paramPlace])[0] && (l = l.substring(1)), l = l.split("&");
                    for (var n = 0; n < l.length; ++n) {
                        var i = l[n].split("=", 2);
                        2 == i.length && (c[i[0]] = decodeURIComponent(i[1].replace(/\+/g, " ")))
                    }
                }
                return a = h.extend(!0, {}, t.params, o.opts[e], c), d = "function" === h.type(t.url) ? t.url.call(this, s, a, o) : p(t.url, s, a), r = "function" === h.type(t.thumb) ? t.thumb.call(this, s, a, o) : p(t.thumb, s), "youtube" === e ? d = d.replace(/&t=((\d+)m)?(\d+)s/, function (e, t, n, i) {
                    return "&start=" + ((n ? 60 * parseInt(n, 10) : 0) + parseInt(i, 10))
                }) : "vimeo" === e && (d = d.replace("&%23", "#")), !1
            }
        }), f ? (o.opts.thumb || o.opts.$thumb && o.opts.$thumb.length || (o.opts.thumb = r), "iframe" === f && (o.opts = h.extend(!0, o.opts, {
            iframe: {
                preload: !1,
                attr: {scrolling: "no"}
            }
        })), h.extend(o, {
            type: f,
            src: d,
            origSrc: o.src,
            contentSource: u,
            contentType: "image" === f ? "image" : "gmap_place" == u || "gmap_search" == u ? "map" : "video"
        })) : d && (o.type = o.opts.defaultType)
    })
}(window.jQuery || jQuery), function (g, a, m) {
    "use strict";

    function u(e) {
        var t = [];
        for (var n in e = (e = e.originalEvent || e || g.e).touches && e.touches.length ? e.touches : e.changedTouches && e.changedTouches.length ? e.changedTouches : [e]) e[n].pageX ? t.push({
            x: e[n].pageX,
            y: e[n].pageY
        }) : e[n].clientX && t.push({x: e[n].clientX, y: e[n].clientY});
        return t
    }

    function v(e, t, n) {
        return t && e ? "x" === n ? e.x - t.x : "y" === n ? e.y - t.y : Math.sqrt(Math.pow(e.x - t.x, 2) + Math.pow(e.y - t.y, 2)) : 0
    }

    function l(e) {
        if (e.is('a,area,button,[role="button"],input,label,select,summary,textarea,video,audio') || m.isFunction(e.get(0).onclick) || e.data("selectable")) return !0;
        for (var t = 0, n = e[0].attributes, i = n.length; t < i; t++) if ("data-fancybox-" === n[t].nodeName.substr(0, 14)) return !0;
        return !1
    }

    function c(e) {
        for (var t = !1; (n = e.get(0), void 0, i = g.getComputedStyle(n)["overflow-y"], o = g.getComputedStyle(n)["overflow-x"], r = ("scroll" === i || "auto" === i) && n.scrollHeight > n.clientHeight, s = ("scroll" === o || "auto" === o) && n.scrollWidth > n.clientWidth, !(t = r || s)) && ((e = e.parent()).length && !e.hasClass("fancybox-stage") && !e.is("body"));) ;
        var n, i, o, r, s;
        return t
    }

    function n(e) {
        var t = this;
        t.instance = e, t.$bg = e.$refs.bg, t.$stage = e.$refs.stage, t.$container = e.$refs.container, t.destroy(), t.$container.on("touchstart.fb.touch mousedown.fb.touch", m.proxy(t, "ontouchstart"))
    }

    var y = g.requestAnimationFrame || g.webkitRequestAnimationFrame || g.mozRequestAnimationFrame || g.oRequestAnimationFrame || function (e) {
            return g.setTimeout(e, 1e3 / 60)
        },
        b = g.cancelAnimationFrame || g.webkitCancelAnimationFrame || g.mozCancelAnimationFrame || g.oCancelAnimationFrame || function (e) {
            g.clearTimeout(e)
        };
    n.prototype.destroy = function () {
        this.$container.off(".fb.touch")
    }, n.prototype.ontouchstart = function (e) {
        var t = this, n = m(e.target), i = t.instance, o = i.current, r = o.$content, s = "touchstart" == e.type;
        if (s && t.$container.off("mousedown.fb.touch"), (!e.originalEvent || 2 != e.originalEvent.button) && n.length && !l(n) && !l(n.parent()) && (n.is("img") || !(e.originalEvent.clientX > n[0].clientWidth + n.offset().left))) {
            if (!o || i.isAnimating || i.isClosing) return e.stopPropagation(), void e.preventDefault();
            if (t.realPoints = t.startPoints = u(e), t.startPoints.length) {
                if (e.stopPropagation(), t.startEvent = e, t.canTap = !0, t.$target = n, t.$content = r, t.opts = o.opts.touch, t.isPanning = !1, t.isSwiping = !1, t.isZooming = !1, t.isScrolling = !1, t.startTime = (new Date).getTime(), t.distanceX = t.distanceY = t.distance = 0, t.canvasWidth = Math.round(o.$slide[0].clientWidth), t.canvasHeight = Math.round(o.$slide[0].clientHeight), t.contentLastPos = null, t.contentStartPos = m.fancybox.getTranslate(t.$content) || {
                    top: 0,
                    left: 0
                }, t.sliderStartPos = t.sliderLastPos || m.fancybox.getTranslate(o.$slide), t.stagePos = m.fancybox.getTranslate(i.$refs.stage), t.sliderStartPos.top -= t.stagePos.top, t.sliderStartPos.left -= t.stagePos.left, t.contentStartPos.top -= t.stagePos.top, t.contentStartPos.left -= t.stagePos.left, m(a).off(".fb.touch").on(s ? "touchend.fb.touch touchcancel.fb.touch" : "mouseup.fb.touch mouseleave.fb.touch", m.proxy(t, "ontouchend")).on(s ? "touchmove.fb.touch" : "mousemove.fb.touch", m.proxy(t, "ontouchmove")), m.fancybox.isMobile && a.addEventListener("scroll", t.onscroll, !0), !t.opts && !i.canPan() || !n.is(t.$stage) && !t.$stage.find(n).length) return void (n.is(".fancybox-image") && e.preventDefault());
                m.fancybox.isMobile && (c(n) || c(n.parent())) || e.preventDefault(), 1 !== t.startPoints.length && !o.hasError || (t.instance.canPan() ? (m.fancybox.stop(t.$content), t.$content.css("transition-duration", ""), t.isPanning = !0) : t.isSwiping = !0, t.$container.addClass("fancybox-controls--isGrabbing")), 2 === t.startPoints.length && "image" === o.type && (o.isLoaded || o.$ghost) && (t.canTap = !1, t.isSwiping = !1, t.isPanning = !1, t.isZooming = !0, m.fancybox.stop(t.$content), t.$content.css("transition-duration", ""), t.centerPointStartX = .5 * (t.startPoints[0].x + t.startPoints[1].x) - m(g).scrollLeft(), t.centerPointStartY = .5 * (t.startPoints[0].y + t.startPoints[1].y) - m(g).scrollTop(), t.percentageOfImageAtPinchPointX = (t.centerPointStartX - t.contentStartPos.left) / t.contentStartPos.width, t.percentageOfImageAtPinchPointY = (t.centerPointStartY - t.contentStartPos.top) / t.contentStartPos.height, t.startDistanceBetweenFingers = v(t.startPoints[0], t.startPoints[1]))
            }
        }
    }, n.prototype.onscroll = function (e) {
        this.isScrolling = !0, a.removeEventListener("scroll", this.onscroll, !0)
    }, n.prototype.ontouchmove = function (e) {
        var t = this, n = m(e.target);
        return void 0 !== e.originalEvent.buttons && 0 === e.originalEvent.buttons ? void t.ontouchend(e) : t.isScrolling || !n.is(t.$stage) && !t.$stage.find(n).length ? void (t.canTap = !1) : (t.newPoints = u(e), void ((t.opts || t.instance.canPan()) && t.newPoints.length && t.newPoints.length && (t.isSwiping && !0 === t.isSwiping || e.preventDefault(), t.distanceX = v(t.newPoints[0], t.startPoints[0], "x"), t.distanceY = v(t.newPoints[0], t.startPoints[0], "y"), t.distance = v(t.newPoints[0], t.startPoints[0]), 0 < t.distance && (t.isSwiping ? t.onSwipe(e) : t.isPanning ? t.onPan() : t.isZooming && t.onZoom()))))
    }, n.prototype.onSwipe = function (e) {
        var t, i = this, n = i.isSwiping, o = i.sliderStartPos.left || 0;
        if (!0 !== n) "x" == n && (0 < i.distanceX && (i.instance.group.length < 2 || 0 === i.instance.current.index && !i.instance.current.opts.loop) ? o += Math.pow(i.distanceX, .8) : i.distanceX < 0 && (i.instance.group.length < 2 || i.instance.current.index === i.instance.group.length - 1 && !i.instance.current.opts.loop) ? o -= Math.pow(-i.distanceX, .8) : o += i.distanceX), i.sliderLastPos = {
            top: "x" == n ? 0 : i.sliderStartPos.top + i.distanceY,
            left: o
        }, i.requestId && (b(i.requestId), i.requestId = null), i.requestId = y(function () {
            i.sliderLastPos && (m.each(i.instance.slides, function (e, t) {
                var n = t.pos - i.instance.currPos;
                m.fancybox.setTranslate(t.$slide, {
                    top: i.sliderLastPos.top,
                    left: i.sliderLastPos.left + n * i.canvasWidth + n * t.opts.gutter
                })
            }), i.$container.addClass("fancybox-is-sliding"))
        }); else if (10 < Math.abs(i.distance)) {
            if (i.canTap = !1, i.instance.group.length < 2 && i.opts.vertical ? i.isSwiping = "y" : i.instance.isDragging || !1 === i.opts.vertical || "auto" === i.opts.vertical && 800 < m(g).width() ? i.isSwiping = "x" : (t = Math.abs(180 * Math.atan2(i.distanceY, i.distanceX) / Math.PI), i.isSwiping = 45 < t && t < 135 ? "y" : "x"), i.canTap = !1, "y" === i.isSwiping && m.fancybox.isMobile && (c(i.$target) || c(i.$target.parent()))) return void (i.isScrolling = !0);
            i.instance.isDragging = i.isSwiping, i.startPoints = i.newPoints, m.each(i.instance.slides, function (e, t) {
                m.fancybox.stop(t.$slide), t.$slide.css("transition-duration", ""), t.inTransition = !1, t.pos === i.instance.current.pos && (i.sliderStartPos.left = m.fancybox.getTranslate(t.$slide).left - m.fancybox.getTranslate(i.instance.$refs.stage).left)
            }), i.instance.SlideShow && i.instance.SlideShow.isActive && i.instance.SlideShow.stop()
        }
    }, n.prototype.onPan = function () {
        var e = this;
        return v(e.newPoints[0], e.realPoints[0]) < (m.fancybox.isMobile ? 10 : 5) ? void (e.startPoints = e.newPoints) : (e.canTap = !1, e.contentLastPos = e.limitMovement(), e.requestId && (b(e.requestId), e.requestId = null), void (e.requestId = y(function () {
            m.fancybox.setTranslate(e.$content, e.contentLastPos)
        })))
    }, n.prototype.limitMovement = function () {
        var e, t, n, i, o, r, s = this, a = s.canvasWidth, l = s.canvasHeight, c = s.distanceX, u = s.distanceY,
            d = s.contentStartPos, f = d.left, h = d.top, p = d.width, g = d.height;
        return o = a < p ? f + c : f, r = h + u, e = Math.max(0, .5 * a - .5 * p), t = Math.max(0, .5 * l - .5 * g), n = Math.min(a - p, .5 * a - .5 * p), i = Math.min(l - g, .5 * l - .5 * g), 0 < c && e < o && (o = e - 1 + Math.pow(-e + f + c, .8) || 0), c < 0 && o < n && (o = n + 1 - Math.pow(n - f - c, .8) || 0), 0 < u && t < r && (r = t - 1 + Math.pow(-t + h + u, .8) || 0), u < 0 && r < i && (r = i + 1 - Math.pow(i - h - u, .8) || 0), {
            top: r,
            left: o
        }
    }, n.prototype.limitPosition = function (e, t, n, i) {
        var o = this.canvasWidth, r = this.canvasHeight;
        return e = o < n ? (e = 0 < e ? 0 : e) < o - n ? o - n : e : Math.max(0, o / 2 - n / 2), {
            top: t = r < i ? (t = 0 < t ? 0 : t) < r - i ? r - i : t : Math.max(0, r / 2 - i / 2),
            left: e
        }
    }, n.prototype.onZoom = function () {
        var e = this, t = e.contentStartPos, n = t.width, i = t.height, o = t.left, r = t.top,
            s = v(e.newPoints[0], e.newPoints[1]) / e.startDistanceBetweenFingers, a = Math.floor(n * s),
            l = Math.floor(i * s), c = (n - a) * e.percentageOfImageAtPinchPointX,
            u = (i - l) * e.percentageOfImageAtPinchPointY,
            d = (e.newPoints[0].x + e.newPoints[1].x) / 2 - m(g).scrollLeft(),
            f = (e.newPoints[0].y + e.newPoints[1].y) / 2 - m(g).scrollTop(), h = d - e.centerPointStartX,
            p = {top: r + (u + (f - e.centerPointStartY)), left: o + (c + h), scaleX: s, scaleY: s};
        e.canTap = !1, e.newWidth = a, e.newHeight = l, e.contentLastPos = p, e.requestId && (b(e.requestId), e.requestId = null), e.requestId = y(function () {
            m.fancybox.setTranslate(e.$content, e.contentLastPos)
        })
    }, n.prototype.ontouchend = function (e) {
        var t = this, n = Math.max((new Date).getTime() - t.startTime, 1), i = t.isSwiping, o = t.isPanning,
            r = t.isZooming, s = t.isScrolling;
        return t.endPoints = u(e), t.$container.removeClass("fancybox-controls--isGrabbing"), m(a).off(".fb.touch"), a.removeEventListener("scroll", t.onscroll, !0), t.requestId && (b(t.requestId), t.requestId = null), t.isSwiping = !1, t.isPanning = !1, t.isZooming = !1, t.isScrolling = !1, t.instance.isDragging = !1, t.canTap ? t.onTap(e) : (t.speed = 366, t.velocityX = t.distanceX / n * .5, t.velocityY = t.distanceY / n * .5, t.speedX = Math.max(.5 * t.speed, Math.min(1.5 * t.speed, 1 / Math.abs(t.velocityX) * t.speed)), void (o ? t.endPanning() : r ? t.endZooming() : t.endSwiping(i, s)))
    }, n.prototype.endSwiping = function (e, t) {
        var n = this, i = !1, o = n.instance.group.length;
        n.sliderLastPos = null, "y" == e && !t && 50 < Math.abs(n.distanceY) ? (m.fancybox.animate(n.instance.current.$slide, {
            top: n.sliderStartPos.top + n.distanceY + 150 * n.velocityY,
            opacity: 0
        }, 200), i = n.instance.close(!0, 200)) : "x" == e && 50 < n.distanceX && 1 < o ? i = n.instance.previous(n.speedX) : "x" == e && n.distanceX < -50 && 1 < o && (i = n.instance.next(n.speedX)), !1 !== i || "x" != e && "y" != e || (t || o < 2 ? n.instance.centerSlide(n.instance.current, 150) : n.instance.jumpTo(n.instance.current.index)), n.$container.removeClass("fancybox-is-sliding")
    }, n.prototype.endPanning = function () {
        var e, t, n, i = this;
        i.contentLastPos && (t = !1 === i.opts.momentum ? (e = i.contentLastPos.left, i.contentLastPos.top) : (e = i.contentLastPos.left + i.velocityX * i.speed, i.contentLastPos.top + i.velocityY * i.speed), (n = i.limitPosition(e, t, i.contentStartPos.width, i.contentStartPos.height)).width = i.contentStartPos.width, n.height = i.contentStartPos.height, m.fancybox.animate(i.$content, n, 330))
    }, n.prototype.endZooming = function () {
        var e, t, n, i, o = this, r = o.instance.current, s = o.newWidth, a = o.newHeight;
        o.contentLastPos && (e = o.contentLastPos.left, i = {
            top: t = o.contentLastPos.top,
            left: e,
            width: s,
            height: a,
            scaleX: 1,
            scaleY: 1
        }, m.fancybox.setTranslate(o.$content, i), s < o.canvasWidth && a < o.canvasHeight ? o.instance.scaleToFit(150) : s > r.width || a > r.height ? o.instance.scaleToActual(o.centerPointStartX, o.centerPointStartY, 150) : (n = o.limitPosition(e, t, s, a), m.fancybox.setTranslate(o.$content, m.fancybox.getTranslate(o.$content)), m.fancybox.animate(o.$content, n, 150)))
    }, n.prototype.onTap = function (n) {
        function e(e) {
            var t = s.opts[e];
            if (m.isFunction(t) && (t = t.apply(r, [s, n])), t) switch (t) {
                case"close":
                    r.close(i.startEvent);
                    break;
                case"toggleControls":
                    r.toggleControls(!0);
                    break;
                case"next":
                    r.next();
                    break;
                case"nextOrClose":
                    1 < r.group.length ? r.next() : r.close(i.startEvent);
                    break;
                case"zoom":
                    "image" == s.type && (s.isLoaded || s.$ghost) && (r.canPan() ? r.scaleToFit() : r.isScaledDown() ? r.scaleToActual(l, c) : r.group.length < 2 && r.close(i.startEvent))
            }
        }

        var t, i = this, o = m(n.target), r = i.instance, s = r.current, a = n && u(n) || i.startPoints,
            l = a[0] ? a[0].x - m(g).scrollLeft() - i.stagePos.left : 0,
            c = a[0] ? a[0].y - m(g).scrollTop() - i.stagePos.top : 0;
        if ((!n.originalEvent || 2 != n.originalEvent.button) && (o.is("img") || !(l > o[0].clientWidth + o.offset().left))) {
            if (o.is(".fancybox-bg,.fancybox-inner,.fancybox-outer,.fancybox-container")) t = "Outside"; else if (o.is(".fancybox-slide")) t = "Slide"; else {
                if (!r.current.$content || !r.current.$content.find(o).addBack().filter(o).length) return;
                t = "Content"
            }
            if (i.tapped) {
                if (clearTimeout(i.tapped), i.tapped = null, 50 < Math.abs(l - i.tapX) || 50 < Math.abs(c - i.tapY)) return this;
                e("dblclick" + t)
            } else i.tapX = l, i.tapY = c, s.opts["dblclick" + t] && s.opts["dblclick" + t] !== s.opts["click" + t] ? i.tapped = setTimeout(function () {
                i.tapped = null, e("click" + t)
            }, 500) : e("click" + t);
            return this
        }
    }, m(a).on("onActivate.fb", function (e, t) {
        t && !t.Guestures && (t.Guestures = new n(t))
    })
}(window, document, window.jQuery || jQuery), function (s, a) {
    "use strict";
    a.extend(!0, a.fancybox.defaults, {
        btnTpl: {slideShow: '<button data-fancybox-play class="fancybox-button fancybox-button--play" title="{{PLAY_START}}"><svg viewBox="0 0 40 40"><path d="M13,12 L27,20 L13,27 Z" /><path d="M15,10 v19 M23,10 v19" /></svg></button>'},
        slideShow: {autoStart: !1, speed: 3e3}
    });

    function n(e) {
        this.instance = e, this.init()
    }

    a.extend(n.prototype, {
        timer: null, isActive: !1, $button: null, init: function () {
            var e = this;
            e.$button = e.instance.$refs.toolbar.find("[data-fancybox-play]").on("click", function () {
                e.toggle()
            }), (e.instance.group.length < 2 || !e.instance.group[e.instance.currIndex].opts.slideShow) && e.$button.hide()
        }, set: function (e) {
            var t = this;
            t.instance && t.instance.current && (!0 === e || t.instance.current.opts.loop || t.instance.currIndex < t.instance.group.length - 1) ? t.timer = setTimeout(function () {
                t.isActive && t.instance.jumpTo((t.instance.currIndex + 1) % t.instance.group.length)
            }, t.instance.current.opts.slideShow.speed) : (t.stop(), t.instance.idleSecondsCounter = 0, t.instance.showControls())
        }, clear: function () {
            clearTimeout(this.timer), this.timer = null
        }, start: function () {
            var e = this.instance.current;
            e && (this.isActive = !0, this.$button.attr("title", e.opts.i18n[e.opts.lang].PLAY_STOP).removeClass("fancybox-button--play").addClass("fancybox-button--pause"), this.set(!0))
        }, stop: function () {
            var e = this.instance.current;
            this.clear(), this.$button.attr("title", e.opts.i18n[e.opts.lang].PLAY_START).removeClass("fancybox-button--pause").addClass("fancybox-button--play"), this.isActive = !1
        }, toggle: function () {
            this.isActive ? this.stop() : this.start()
        }
    }), a(s).on({
        "onInit.fb": function (e, t) {
            t && !t.SlideShow && (t.SlideShow = new n(t))
        }, "beforeShow.fb": function (e, t, n, i) {
            var o = t && t.SlideShow;
            i ? o && n.opts.slideShow.autoStart && o.start() : o && o.isActive && o.clear()
        }, "afterShow.fb": function (e, t, n) {
            var i = t && t.SlideShow;
            i && i.isActive && i.set()
        }, "afterKeydown.fb": function (e, t, n, i, o) {
            var r = t && t.SlideShow;
            !r || !n.opts.slideShow || 80 !== o && 32 !== o || a(s.activeElement).is("button,a,input") || (i.preventDefault(), r.toggle())
        }, "beforeClose.fb onDeactivate.fb": function (e, t) {
            var n = t && t.SlideShow;
            n && n.stop()
        }
    }), a(s).on("visibilitychange", function () {
        var e = a.fancybox.getInstance(), t = e && e.SlideShow;
        t && t.isActive && (s.hidden ? t.clear() : t.set())
    })
}(document, window.jQuery || jQuery), function (r, n) {
    "use strict";
    var t = function () {
        for (var e = [["requestFullscreen", "exitFullscreen", "fullscreenElement", "fullscreenEnabled", "fullscreenchange", "fullscreenerror"], ["webkitRequestFullscreen", "webkitExitFullscreen", "webkitFullscreenElement", "webkitFullscreenEnabled", "webkitfullscreenchange", "webkitfullscreenerror"], ["webkitRequestFullScreen", "webkitCancelFullScreen", "webkitCurrentFullScreenElement", "webkitCancelFullScreen", "webkitfullscreenchange", "webkitfullscreenerror"], ["mozRequestFullScreen", "mozCancelFullScreen", "mozFullScreenElement", "mozFullScreenEnabled", "mozfullscreenchange", "mozfullscreenerror"], ["msRequestFullscreen", "msExitFullscreen", "msFullscreenElement", "msFullscreenEnabled", "MSFullscreenChange", "MSFullscreenError"]], t = {}, n = 0; n < e.length; n++) {
            var i = e[n];
            if (i && i[1] in r) {
                for (var o = 0; o < i.length; o++) t[e[0][o]] = i[o];
                return t
            }
        }
        return !1
    }();
    if (t) {
        var i = {
            request: function (e) {
                (e = e || r.documentElement)[t.requestFullscreen](e.ALLOW_KEYBOARD_INPUT)
            }, exit: function () {
                r[t.exitFullscreen]()
            }, toggle: function (e) {
                e = e || r.documentElement, this.isFullscreen() ? this.exit() : this.request(e)
            }, isFullscreen: function () {
                return Boolean(r[t.fullscreenElement])
            }, enabled: function () {
                return Boolean(r[t.fullscreenEnabled])
            }
        };
        n.extend(!0, n.fancybox.defaults, {
            btnTpl: {fullScreen: '<button data-fancybox-fullscreen class="fancybox-button fancybox-button--fullscreen" title="{{FULL_SCREEN}}"><svg viewBox="0 0 40 40"><path d="M9,12 v16 h22 v-16 h-22 v8" /></svg></button>'},
            fullScreen: {autoStart: !1}
        }), n(r).on({
            "onInit.fb": function (e, t) {
                t && t.group[t.currIndex].opts.fullScreen ? (t.$refs.container.on("click.fb-fullscreen", "[data-fancybox-fullscreen]", function (e) {
                    e.stopPropagation(), e.preventDefault(), i.toggle()
                }), t.opts.fullScreen && !0 === t.opts.fullScreen.autoStart && i.request(), t.FullScreen = i) : t && t.$refs.toolbar.find("[data-fancybox-fullscreen]").hide()
            }, "afterKeydown.fb": function (e, t, n, i, o) {
                t && t.FullScreen && 70 === o && (i.preventDefault(), t.FullScreen.toggle())
            }, "beforeClose.fb": function (e, t) {
                t && t.FullScreen && t.$refs.container.hasClass("fancybox-is-fullscreen") && i.exit()
            }
        }), n(r).on(t.fullscreenchange, function () {
            var e = i.isFullscreen(), t = n.fancybox.getInstance();
            t && (t.current && "image" === t.current.type && t.isAnimating && (t.current.$content.css("transition", "none"), t.isAnimating = !1, t.update(!0, !0, 0)), t.trigger("onFullscreenChange", e), t.$refs.container.toggleClass("fancybox-is-fullscreen", e))
        })
    } else n && n.fancybox && (n.fancybox.defaults.btnTpl.fullScreen = !1)
}(document, window.jQuery || jQuery), function (e, r) {
    "use strict";
    var s = "fancybox-thumbs", a = s + "-active";
    r.fancybox.defaults = r.extend(!0, {
        btnTpl: {thumbs: '<button data-fancybox-thumbs class="fancybox-button fancybox-button--thumbs" title="{{THUMBS}}"><svg viewBox="0 0 120 120"><path d="M30,30 h14 v14 h-14 Z M50,30 h14 v14 h-14 Z M70,30 h14 v14 h-14 Z M30,50 h14 v14 h-14 Z M50,50 h14 v14 h-14 Z M70,50 h14 v14 h-14 Z M30,70 h14 v14 h-14 Z M50,70 h14 v14 h-14 Z M70,70 h14 v14 h-14 Z" /></svg></button>'},
        thumbs: {autoStart: !1, hideOnClose: !0, parentEl: ".fancybox-container", axis: "y"}
    }, r.fancybox.defaults);

    function i(e) {
        this.init(e)
    }

    r.extend(i.prototype, {
        $button: null, $grid: null, $list: null, isVisible: !1, isActive: !1, init: function (e) {
            var t, n, i = this;
            ((i.instance = e).Thumbs = i).opts = e.group[e.currIndex].opts.thumbs, t = (t = e.group[0]).opts.thumb || !(!t.opts.$thumb || !t.opts.$thumb.length) && t.opts.$thumb.attr("src"), 1 < e.group.length && (n = (n = e.group[1]).opts.thumb || !(!n.opts.$thumb || !n.opts.$thumb.length) && n.opts.$thumb.attr("src")), i.$button = e.$refs.toolbar.find("[data-fancybox-thumbs]"), i.opts && t && n && t && n ? (i.$button.show().on("click", function () {
                i.toggle()
            }), i.isActive = !0) : i.$button.hide()
        }, create: function () {
            var n, e = this, t = e.instance, i = e.opts.parentEl, o = [];
            e.$grid || (e.$grid = r('<div class="' + s + " " + s + "-" + e.opts.axis + '"></div>').appendTo(t.$refs.container.find(i).addBack().filter(i)), e.$grid.on("click", "li", function () {
                t.jumpTo(r(this).attr("data-index"))
            })), e.$list || (e.$list = r("<ul>").appendTo(e.$grid)), r.each(t.group, function (e, t) {
                (n = t.opts.thumb || (t.opts.$thumb ? t.opts.$thumb.attr("src") : null)) || "image" !== t.type || (n = t.src), o.push('<li data-index="' + e + '" tabindex="0" class="fancybox-thumbs-loading"' + (n && n.length ? ' style="background-image:url(' + n + ')" />' : "") + "></li>")
            }), e.$list[0].innerHTML = o.join(""), "x" === e.opts.axis && e.$list.width(parseInt(e.$grid.css("padding-right"), 10) + t.group.length * e.$list.children().eq(0).outerWidth(!0))
        }, focus: function (e) {
            var t, n, i = this, o = i.$list, r = i.$grid;
            i.instance.current && (n = (t = o.children().removeClass(a).filter('[data-index="' + i.instance.current.index + '"]').addClass(a)).position(), "y" === i.opts.axis && (n.top < 0 || n.top > o.height() - t.outerHeight()) ? o.stop().animate({scrollTop: o.scrollTop() + n.top}, e) : "x" === i.opts.axis && (n.left < r.scrollLeft() || n.left > r.scrollLeft() + (r.width() - t.outerWidth())) && o.parent().stop().animate({scrollLeft: n.left}, e))
        }, update: function () {
            var e = this;
            e.instance.$refs.container.toggleClass("fancybox-show-thumbs", this.isVisible), e.isVisible ? (e.$grid || e.create(), e.instance.trigger("onThumbsShow"), e.focus(0)) : e.$grid && e.instance.trigger("onThumbsHide"), e.instance.update()
        }, hide: function () {
            this.isVisible = !1, this.update()
        }, show: function () {
            this.isVisible = !0, this.update()
        }, toggle: function () {
            this.isVisible = !this.isVisible, this.update()
        }
    }), r(e).on({
        "onInit.fb": function (e, t) {
            var n;
            !t || t.Thumbs || (n = new i(t)).isActive && !0 === n.opts.autoStart && n.show()
        }, "beforeShow.fb": function (e, t, n, i) {
            var o = t && t.Thumbs;
            o && o.isVisible && o.focus(i ? 0 : 250)
        }, "afterKeydown.fb": function (e, t, n, i, o) {
            var r = t && t.Thumbs;
            r && r.isActive && 71 === o && (i.preventDefault(), r.toggle())
        }, "beforeClose.fb": function (e, t) {
            var n = t && t.Thumbs;
            n && n.isVisible && !1 !== n.opts.hideOnClose && n.$grid.hide()
        }
    })
}(document, window.jQuery || jQuery), function (e, r) {
    "use strict";
    r.extend(!0, r.fancybox.defaults, {
        btnTpl: {share: '<button data-fancybox-share class="fancybox-button fancybox-button--share" title="{{SHARE}}"><svg viewBox="0 0 40 40"><path d="M6,30 C8,18 19,16 23,16 L23,16 L23,10 L33,20 L23,29 L23,24 C19,24 8,27 6,30 Z"></svg></button>'},
        share: {
            url: function (e, t) {
                return !e.currentHash && "inline" !== t.type && "html" !== t.type && (t.origSrc || t.src) || window.location
            },
            tpl: '<div class="fancybox-share"><h1>{{SHARE}}</h1><p><a class="fancybox-share__button fancybox-share__button--fb" href="https://www.facebook.com/sharer/sharer.php?u={{url}}"><svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="m287 456v-299c0-21 6-35 35-35h38v-63c-7-1-29-3-55-3-54 0-91 33-91 94v306m143-254h-205v72h196" /></svg><span>Facebook</span></a><a class="fancybox-share__button fancybox-share__button--tw" href="https://twitter.com/intent/tweet?url={{url}}&text={{descr}}"><svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="m456 133c-14 7-31 11-47 13 17-10 30-27 37-46-15 10-34 16-52 20-61-62-157-7-141 75-68-3-129-35-169-85-22 37-11 86 26 109-13 0-26-4-37-9 0 39 28 72 65 80-12 3-25 4-37 2 10 33 41 57 77 57-42 30-77 38-122 34 170 111 378-32 359-208 16-11 30-25 41-42z" /></svg><span>Twitter</span></a><a class="fancybox-share__button fancybox-share__button--pt" href="https://www.pinterest.com/pin/create/button/?url={{url}}&description={{descr}}&media={{media}}"><svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="m265 56c-109 0-164 78-164 144 0 39 15 74 47 87 5 2 10 0 12-5l4-19c2-6 1-8-3-13-9-11-15-25-15-45 0-58 43-110 113-110 62 0 96 38 96 88 0 67-30 122-73 122-24 0-42-19-36-44 6-29 20-60 20-81 0-19-10-35-31-35-25 0-44 26-44 60 0 21 7 36 7 36l-30 125c-8 37-1 83 0 87 0 3 4 4 5 2 2-3 32-39 42-75l16-64c8 16 31 29 56 29 74 0 124-67 124-157 0-69-58-132-146-132z" fill="#fff"/></svg><span>Pinterest</span></a></p><p><input class="fancybox-share__input" type="text" value="{{url_raw}}" /></p></div>'
        }
    }), r(e).on("click", "[data-fancybox-share]", function () {
        var e, t, n, i = r.fancybox.getInstance(), o = i.current || null;
        o && ("function" === r.type(o.opts.share.url) && (e = o.opts.share.url.apply(o, [i, o])), t = o.opts.share.tpl.replace(/\{\{media\}\}/g, "image" === o.type ? encodeURIComponent(o.src) : "").replace(/\{\{url\}\}/g, encodeURIComponent(e)).replace(/\{\{url_raw\}\}/g, (n = {
            "&": "&amp;",
            "<": "&lt;",
            ">": "&gt;",
            '"': "&quot;",
            "'": "&#39;",
            "/": "&#x2F;",
            "`": "&#x60;",
            "=": "&#x3D;"
        }, String(e).replace(/[&<>"'`=\/]/g, function (e) {
            return n[e]
        }))).replace(/\{\{descr\}\}/g, i.$caption ? encodeURIComponent(i.$caption.text()) : ""), r.fancybox.open({
            src: i.translate(i, t),
            type: "html",
            opts: {
                animationEffect: !1, afterLoad: function (e, t) {
                    i.$refs.container.one("beforeClose.fb", function () {
                        e.close(null, 0)
                    }), t.$content.find(".fancybox-share__links a").click(function () {
                        return window.open(this.href, "Share", "width=550, height=450"), !1
                    })
                }
            }
        }))
    })
}(document, window.jQuery || jQuery), function (r, s, o) {
    "use strict";

    function a() {
        var e = s.location.hash.substr(1), t = e.split("-"),
            n = 1 < t.length && /^\+?\d+$/.test(t[t.length - 1]) && parseInt(t.pop(-1), 10) || 1;
        return {hash: e, index: n < 1 ? 1 : n, gallery: t.join("-")}
    }

    function t(e) {
        "" !== e.gallery && o("[data-fancybox='" + o.escapeSelector(e.gallery) + "']").eq(e.index - 1).trigger("click.fb-start")
    }

    function l(e) {
        var t, n;
        return !!e && ("" !== (n = (t = e.current ? e.current.opts : e.opts).hash || (t.$orig ? t.$orig.data("fancybox") : "")) && n)
    }

    o.escapeSelector || (o.escapeSelector = function (e) {
        return (e + "").replace(/([\0-\x1f\x7f]|^-?\d)|^-$|[^\x80-\uFFFF\w-]/g, function (e, t) {
            return t ? "\0" === e ? "�" : e.slice(0, -1) + "\\" + e.charCodeAt(e.length - 1).toString(16) + " " : "\\" + e
        })
    }), o(function () {
        !1 !== o.fancybox.defaults.hash && (o(r).on({
            "onInit.fb": function (e, t) {
                var n, i;
                !1 !== t.group[t.currIndex].opts.hash && (n = a(), (i = l(t)) && n.gallery && i == n.gallery && (t.currIndex = n.index - 1))
            }, "beforeShow.fb": function (e, t, n, i) {
                var o;
                !n || !1 === n.opts.hash || (o = l(t)) && (t.currentHash = o + (1 < t.group.length ? "-" + (n.index + 1) : ""), s.location.hash !== "#" + t.currentHash && (t.origHash || (t.origHash = s.location.hash), t.hashTimer && clearTimeout(t.hashTimer), t.hashTimer = setTimeout(function () {
                    "replaceState" in s.history ? (s.history[i ? "pushState" : "replaceState"]({}, r.title, s.location.pathname + s.location.search + "#" + t.currentHash), i && (t.hasCreatedHistory = !0)) : s.location.hash = t.currentHash, t.hashTimer = null
                }, 300)))
            }, "beforeClose.fb": function (e, t, n) {
                !1 !== n.opts.hash && (l(t), t.currentHash && t.hasCreatedHistory ? s.history.back() : t.currentHash && ("replaceState" in s.history ? s.history.replaceState({}, r.title, s.location.pathname + s.location.search + (t.origHash || "")) : s.location.hash = t.origHash), t.currentHash = null, clearTimeout(t.hashTimer))
            }
        }), o(s).on("hashchange.fb", function () {
            var i, e = a();
            o.each(o(".fancybox-container").get().reverse(), function (e, t) {
                var n = o(t).data("FancyBox");
                if (n.currentHash) return i = n, !1
            }), i ? !i.currentHash || i.currentHash === e.gallery + "-" + e.index || 1 === e.index && i.currentHash == e.gallery || (i.currentHash = null, i.close()) : "" !== e.gallery && t(e)
        }), setTimeout(function () {
            o.fancybox.getInstance() || t(a())
        }, 50))
    })
}(document, window, window.jQuery || jQuery), function (e, t) {
    "use strict";
    var o = (new Date).getTime();
    t(e).on({
        "onInit.fb": function (e, i, t) {
            i.$refs.stage.on("mousewheel DOMMouseScroll wheel MozMousePixelScroll", function (e) {
                var t = i.current, n = (new Date).getTime();
                i.group.length < 2 || !1 === t.opts.wheel || "auto" === t.opts.wheel && "image" !== t.type || (e.preventDefault(), e.stopPropagation(), t.$slide.hasClass("fancybox-animated") || (e = e.originalEvent || e, n - o < 250 || (o = n, i[(-e.deltaY || -e.deltaX || e.wheelDelta || -e.detail) < 0 ? "next" : "previous"]())))
            })
        }
    })
}(document, window.jQuery || jQuery), function (e) {
    "function" == typeof define && define.amd ? define(["jquery"], e) : "object" == typeof exports ? e(require("jquery")) : e(jQuery)
}(function (p) {
    var i = /\+/g;

    function g(e) {
        return v.raw ? e : encodeURIComponent(e)
    }

    function m(e, t) {
        var n = v.raw ? e : function (e) {
            0 === e.indexOf('"') && (e = e.slice(1, -1).replace(/\\"/g, '"').replace(/\\\\/g, "\\"));
            try {
                return e = decodeURIComponent(e.replace(i, " ")), v.json ? JSON.parse(e) : e
            } catch (e) {
            }
        }(e);
        return p.isFunction(t) ? t(n) : n
    }

    var v = p.cookie = function (e, t, n) {
        if (void 0 !== t && !p.isFunction(t)) {
            if ("number" == typeof (n = p.extend({}, v.defaults, n)).expires) {
                var i = n.expires, o = n.expires = new Date;
                o.setTime(+o + 864e5 * i)
            }
            return document.cookie = [g(e), "=", (r = t, g(v.json ? JSON.stringify(r) : String(r))), n.expires ? "; expires=" + n.expires.toUTCString() : "", n.path ? "; path=" + n.path : "", n.domain ? "; domain=" + n.domain : "", n.secure ? "; secure" : ""].join("")
        }
        for (var r, s, a = e ? void 0 : {}, l = document.cookie ? document.cookie.split("; ") : [], c = 0, u = l.length; c < u; c++) {
            var d = l[c].split("="), f = (s = d.shift(), v.raw ? s : decodeURIComponent(s)), h = d.join("=");
            if (e && e === f) {
                a = m(h, t);
                break
            }
            e || void 0 === (h = m(h)) || (a[f] = h)
        }
        return a
    };
    v.defaults = {}, p.removeCookie = function (e, t) {
        return void 0 !== p.cookie(e) && (p.cookie(e, "", p.extend({}, t, {expires: -1})), !p.cookie(e))
    }
}), function (e) {
    "function" == typeof define && define.amd ? define(["jquery"], e) : "object" == typeof module && module.exports ? module.exports = e(require("jquery")) : e(jQuery)
}(function (u) {
    u.extend(u.fn, {
        validate: function (e) {
            if (this.length) {
                var i = u.data(this[0], "validator");
                return i || (this.attr("novalidate", "novalidate"), i = new u.validator(e, this[0]), u.data(this[0], "validator", i), i.settings.onsubmit && (this.on("click.validate", ":submit", function (e) {
                    i.settings.submitHandler && (i.submitButton = e.target), u(this).hasClass("cancel") && (i.cancelSubmit = !0), void 0 !== u(this).attr("formnovalidate") && (i.cancelSubmit = !0)
                }), this.on("submit.validate", function (n) {
                    function e() {
                        var e, t;
                        return !i.settings.submitHandler || (i.submitButton && (e = u("<input type='hidden'/>").attr("name", i.submitButton.name).val(u(i.submitButton).val()).appendTo(i.currentForm)), t = i.settings.submitHandler.call(i, i.currentForm, n), i.submitButton && e.remove(), void 0 !== t && t)
                    }

                    return i.settings.debug && n.preventDefault(), i.cancelSubmit ? (i.cancelSubmit = !1, e()) : i.form() ? i.pendingRequest ? !(i.formSubmitted = !0) : e() : (i.focusInvalid(), !1)
                })), i)
            }
            e && e.debug && window.console && console.warn("Nothing selected, can't validate, returning nothing.")
        }, valid: function () {
            var e, t, n;
            return u(this[0]).is("form") ? e = this.validate().form() : (n = [], e = !0, t = u(this[0].form).validate(), this.each(function () {
                (e = t.element(this) && e) || (n = n.concat(t.errorList))
            }), t.errorList = n), e
        }, rules: function (e, t) {
            var n, i, o, r, s, a, l = this[0];
            if (null != l && null != l.form) {
                if (e) switch (n = u.data(l.form, "validator").settings, i = n.rules, o = u.validator.staticRules(l), e) {
                    case"add":
                        u.extend(o, u.validator.normalizeRule(t)), delete o.messages, i[l.name] = o, t.messages && (n.messages[l.name] = u.extend(n.messages[l.name], t.messages));
                        break;
                    case"remove":
                        return t ? (a = {}, u.each(t.split(/\s/), function (e, t) {
                            a[t] = o[t], delete o[t], "required" === t && u(l).removeAttr("aria-required")
                        }), a) : (delete i[l.name], o)
                }
                return (r = u.validator.normalizeRules(u.extend({}, u.validator.classRules(l), u.validator.attributeRules(l), u.validator.dataRules(l), u.validator.staticRules(l)), l)).required && (s = r.required, delete r.required, r = u.extend({required: s}, r), u(l).attr("aria-required", "true")), r.remote && (s = r.remote, delete r.remote, r = u.extend(r, {remote: s})), r
            }
        }
    }), u.extend(u.expr[":"], {
        blank: function (e) {
            return !u.trim("" + u(e).val())
        }, filled: function (e) {
            var t = u(e).val();
            return null !== t && !!u.trim("" + t)
        }, unchecked: function (e) {
            return !u(e).prop("checked")
        }
    }), u.validator = function (e, t) {
        this.settings = u.extend(!0, {}, u.validator.defaults, e), this.currentForm = t, this.init()
    }, u.validator.format = function (n, e) {
        return 1 === arguments.length ? function () {
            var e = u.makeArray(arguments);
            return e.unshift(n), u.validator.format.apply(this, e)
        } : (void 0 === e || (2 < arguments.length && e.constructor !== Array && (e = u.makeArray(arguments).slice(1)), e.constructor !== Array && (e = [e]), u.each(e, function (e, t) {
            n = n.replace(new RegExp("\\{" + e + "\\}", "g"), function () {
                return t
            })
        })), n)
    }, u.extend(u.validator, {
        defaults: {
            messages: {},
            groups: {},
            rules: {},
            errorClass: "error",
            pendingClass: "pending",
            validClass: "valid",
            errorElement: "label",
            focusCleanup: !1,
            focusInvalid: !0,
            errorContainer: u([]),
            errorLabelContainer: u([]),
            onsubmit: !0,
            ignore: ":hidden",
            ignoreTitle: !1,
            onfocusin: function (e) {
                this.lastActive = e, this.settings.focusCleanup && (this.settings.unhighlight && this.settings.unhighlight.call(this, e, this.settings.errorClass, this.settings.validClass), this.hideThese(this.errorsFor(e)))
            },
            onfocusout: function (e) {
                this.checkable(e) || !(e.name in this.submitted) && this.optional(e) || this.element(e)
            },
            onkeyup: function (e, t) {
                9 === t.which && "" === this.elementValue(e) || -1 !== u.inArray(t.keyCode, [16, 17, 18, 20, 35, 36, 37, 38, 39, 40, 45, 144, 225]) || (e.name in this.submitted || e.name in this.invalid) && this.element(e)
            },
            onclick: function (e) {
                e.name in this.submitted ? this.element(e) : e.parentNode.name in this.submitted && this.element(e.parentNode)
            },
            highlight: function (e, t, n) {
                "radio" === e.type ? this.findByName(e.name).addClass(t).removeClass(n) : u(e).addClass(t).removeClass(n)
            },
            unhighlight: function (e, t, n) {
                "radio" === e.type ? this.findByName(e.name).removeClass(t).addClass(n) : u(e).removeClass(t).addClass(n)
            }
        },
        setDefaults: function (e) {
            u.extend(u.validator.defaults, e)
        },
        messages: {
            required: "This field is required.",
            remote: "Please fix this field.",
            email: "Please enter a valid email address.",
            url: "Please enter a valid URL.",
            date: "Please enter a valid date.",
            dateISO: "Please enter a valid date (ISO).",
            number: "Please enter a valid number.",
            digits: "Please enter only digits.",
            equalTo: "Please enter the same value again.",
            maxlength: u.validator.format("Please enter no more than {0} characters."),
            minlength: u.validator.format("Please enter at least {0} characters."),
            rangelength: u.validator.format("Please enter a value between {0} and {1} characters long."),
            range: u.validator.format("Please enter a value between {0} and {1}."),
            max: u.validator.format("Please enter a value less than or equal to {0}."),
            min: u.validator.format("Please enter a value greater than or equal to {0}."),
            step: u.validator.format("Please enter a multiple of {0}.")
        },
        autoCreateRanges: !1,
        prototype: {
            init: function () {
                function e(e) {
                    !this.form && this.hasAttribute("contenteditable") && (this.form = u(this).closest("form")[0]);
                    var t = u.data(this.form, "validator"), n = "on" + e.type.replace(/^validate/, ""), i = t.settings;
                    i[n] && !u(this).is(i.ignore) && i[n].call(t, this, e)
                }

                this.labelContainer = u(this.settings.errorLabelContainer), this.errorContext = this.labelContainer.length && this.labelContainer || u(this.currentForm), this.containers = u(this.settings.errorContainer).add(this.settings.errorLabelContainer), this.submitted = {}, this.valueCache = {}, this.pendingRequest = 0, this.pending = {}, this.invalid = {}, this.reset();
                var n, i = this.groups = {};
                u.each(this.settings.groups, function (n, e) {
                    "string" == typeof e && (e = e.split(/\s/)), u.each(e, function (e, t) {
                        i[t] = n
                    })
                }), n = this.settings.rules, u.each(n, function (e, t) {
                    n[e] = u.validator.normalizeRule(t)
                }), u(this.currentForm).on("focusin.validate focusout.validate keyup.validate", ":text, [type='password'], [type='file'], select, textarea, [type='number'], [type='search'], [type='tel'], [type='url'], [type='email'], [type='datetime'], [type='date'], [type='month'], [type='week'], [type='time'], [type='datetime-local'], [type='range'], [type='color'], [type='radio'], [type='checkbox'], [contenteditable]", e).on("click.validate", "select, option, [type='radio'], [type='checkbox']", e), this.settings.invalidHandler && u(this.currentForm).on("invalid-form.validate", this.settings.invalidHandler), u(this.currentForm).find("[required], [data-rule-required], .required").attr("aria-required", "true")
            }, form: function () {
                return this.checkForm(), u.extend(this.submitted, this.errorMap), this.invalid = u.extend({}, this.errorMap), this.valid() || u(this.currentForm).triggerHandler("invalid-form", [this]), this.showErrors(), this.valid()
            }, checkForm: function () {
                this.prepareForm();
                for (var e = 0, t = this.currentElements = this.elements(); t[e]; e++) this.check(t[e]);
                return this.valid()
            }, element: function (e) {
                var t, n, i = this.clean(e), o = this.validationTargetFor(i), r = this, s = !0;
                return void 0 === o ? delete this.invalid[i.name] : (this.prepareElement(o), this.currentElements = u(o), (n = this.groups[o.name]) && u.each(this.groups, function (e, t) {
                    t === n && e !== o.name && ((i = r.validationTargetFor(r.clean(r.findByName(e)))) && i.name in r.invalid && (r.currentElements.push(i), s = r.check(i) && s))
                }), t = !1 !== this.check(o), s = s && t, this.invalid[o.name] = !t, this.numberOfInvalids() || (this.toHide = this.toHide.add(this.containers)), this.showErrors(), u(e).attr("aria-invalid", !t)), s
            }, showErrors: function (t) {
                if (t) {
                    var n = this;
                    u.extend(this.errorMap, t), this.errorList = u.map(this.errorMap, function (e, t) {
                        return {message: e, element: n.findByName(t)[0]}
                    }), this.successList = u.grep(this.successList, function (e) {
                        return !(e.name in t)
                    })
                }
                this.settings.showErrors ? this.settings.showErrors.call(this, this.errorMap, this.errorList) : this.defaultShowErrors()
            }, resetForm: function () {
                u.fn.resetForm && u(this.currentForm).resetForm(), this.invalid = {}, this.submitted = {}, this.prepareForm(), this.hideErrors();
                var e = this.elements().removeData("previousValue").removeAttr("aria-invalid");
                this.resetElements(e)
            }, resetElements: function (e) {
                var t;
                if (this.settings.unhighlight) for (t = 0; e[t]; t++) this.settings.unhighlight.call(this, e[t], this.settings.errorClass, ""), this.findByName(e[t].name).removeClass(this.settings.validClass); else e.removeClass(this.settings.errorClass).removeClass(this.settings.validClass)
            }, numberOfInvalids: function () {
                return this.objectLength(this.invalid)
            }, objectLength: function (e) {
                var t, n = 0;
                for (t in e) e[t] && n++;
                return n
            }, hideErrors: function () {
                this.hideThese(this.toHide)
            }, hideThese: function (e) {
                e.not(this.containers).text(""), this.addWrapper(e).hide()
            }, valid: function () {
                return 0 === this.size()
            }, size: function () {
                return this.errorList.length
            }, focusInvalid: function () {
                if (this.settings.focusInvalid) try {
                    u(this.findLastActive() || this.errorList.length && this.errorList[0].element || []).filter(":visible").focus().trigger("focusin")
                } catch (e) {
                }
            }, findLastActive: function () {
                var t = this.lastActive;
                return t && 1 === u.grep(this.errorList, function (e) {
                    return e.element.name === t.name
                }).length && t
            }, elements: function () {
                var t = this, n = {};
                return u(this.currentForm).find("input, select, textarea, [contenteditable]").not(":submit, :reset, :image, :disabled").not(this.settings.ignore).filter(function () {
                    var e = this.name || u(this).attr("name");
                    return !e && t.settings.debug && window.console && console.error("%o has no name assigned", this), this.hasAttribute("contenteditable") && (this.form = u(this).closest("form")[0]), !(e in n || !t.objectLength(u(this).rules()) || (n[e] = !0, 0))
                })
            }, clean: function (e) {
                return u(e)[0]
            }, errors: function () {
                var e = this.settings.errorClass.split(" ").join(".");
                return u(this.settings.errorElement + "." + e, this.errorContext)
            }, resetInternals: function () {
                this.successList = [], this.errorList = [], this.errorMap = {}, this.toShow = u([]), this.toHide = u([])
            }, reset: function () {
                this.resetInternals(), this.currentElements = u([])
            }, prepareForm: function () {
                this.reset(), this.toHide = this.errors().add(this.containers)
            }, prepareElement: function (e) {
                this.reset(), this.toHide = this.errorsFor(e)
            }, elementValue: function (e) {
                var t, n, i = u(e), o = e.type;
                return "radio" === o || "checkbox" === o ? this.findByName(e.name).filter(":checked").val() : "number" === o && void 0 !== e.validity ? e.validity.badInput ? "NaN" : i.val() : (t = e.hasAttribute("contenteditable") ? i.text() : i.val(), "file" === o ? "C:\\fakepath\\" === t.substr(0, 12) ? t.substr(12) : 0 <= (n = t.lastIndexOf("/")) ? t.substr(n + 1) : 0 <= (n = t.lastIndexOf("\\")) ? t.substr(n + 1) : t : "string" == typeof t ? t.replace(/\r/g, "") : t)
            }, check: function (t) {
                t = this.validationTargetFor(this.clean(t));
                var e, n, i, o = u(t).rules(), r = u.map(o, function (e, t) {
                    return t
                }).length, s = !1, a = this.elementValue(t);
                if ("function" == typeof o.normalizer) {
                    if ("string" != typeof (a = o.normalizer.call(t, a))) throw new TypeError("The normalizer should return a string value.");
                    delete o.normalizer
                }
                for (n in o) {
                    i = {method: n, parameters: o[n]};
                    try {
                        if ("dependency-mismatch" === (e = u.validator.methods[n].call(this, a, t, i.parameters)) && 1 === r) {
                            s = !0;
                            continue
                        }
                        if (s = !1, "pending" === e) return void (this.toHide = this.toHide.not(this.errorsFor(t)));
                        if (!e) return this.formatAndAdd(t, i), !1
                    } catch (e) {
                        throw this.settings.debug && window.console && console.log("Exception occurred when checking element " + t.id + ", check the '" + i.method + "' method.", e), e instanceof TypeError && (e.message += ".  Exception occurred when checking element " + t.id + ", check the '" + i.method + "' method."), e
                    }
                }
                if (!s) return this.objectLength(o) && this.successList.push(t), !0
            }, customDataMessage: function (e, t) {
                return u(e).data("msg" + t.charAt(0).toUpperCase() + t.substring(1).toLowerCase()) || u(e).data("msg")
            }, customMessage: function (e, t) {
                var n = this.settings.messages[e];
                return n && (n.constructor === String ? n : n[t])
            }, findDefined: function () {
                for (var e = 0; e < arguments.length; e++) if (void 0 !== arguments[e]) return arguments[e]
            }, defaultMessage: function (e, t) {
                "string" == typeof t && (t = {method: t});
                var n = this.findDefined(this.customMessage(e.name, t.method), this.customDataMessage(e, t.method), !this.settings.ignoreTitle && e.title || void 0, u.validator.messages[t.method], "<strong>Warning: No message defined for " + e.name + "</strong>"),
                    i = /\$?\{(\d+)\}/g;
                return "function" == typeof n ? n = n.call(this, t.parameters, e) : i.test(n) && (n = u.validator.format(n.replace(i, "{$1}"), t.parameters)), n
            }, formatAndAdd: function (e, t) {
                var n = this.defaultMessage(e, t);
                this.errorList.push({
                    message: n,
                    element: e,
                    method: t.method
                }), this.errorMap[e.name] = n, this.submitted[e.name] = n
            }, addWrapper: function (e) {
                return this.settings.wrapper && (e = e.add(e.parent(this.settings.wrapper))), e
            }, defaultShowErrors: function () {
                var e, t, n;
                for (e = 0; this.errorList[e]; e++) n = this.errorList[e], this.settings.highlight && this.settings.highlight.call(this, n.element, this.settings.errorClass, this.settings.validClass), this.showLabel(n.element, n.message);
                if (this.errorList.length && (this.toShow = this.toShow.add(this.containers)), this.settings.success) for (e = 0; this.successList[e]; e++) this.showLabel(this.successList[e]);
                if (this.settings.unhighlight) for (e = 0, t = this.validElements(); t[e]; e++) this.settings.unhighlight.call(this, t[e], this.settings.errorClass, this.settings.validClass);
                this.toHide = this.toHide.not(this.toShow), this.hideErrors(), this.addWrapper(this.toShow).show()
            }, validElements: function () {
                return this.currentElements.not(this.invalidElements())
            }, invalidElements: function () {
                return u(this.errorList).map(function () {
                    return this.element
                })
            }, showLabel: function (e, t) {
                var n, i, o, r, s = this.errorsFor(e), a = this.idOrName(e), l = u(e).attr("aria-describedby");
                s.length ? (s.removeClass(this.settings.validClass).addClass(this.settings.errorClass), s.html(t)) : (n = s = u("<" + this.settings.errorElement + ">").attr("id", a + "-error").addClass(this.settings.errorClass).html(t || ""), this.settings.wrapper && (n = s.hide().show().wrap("<" + this.settings.wrapper + "/>").parent()), this.labelContainer.length ? this.labelContainer.append(n) : this.settings.errorPlacement ? this.settings.errorPlacement.call(this, n, u(e)) : n.insertAfter(e), s.is("label") ? s.attr("for", a) : 0 === s.parents("label[for='" + this.escapeCssMeta(a) + "']").length && (o = s.attr("id"), l ? l.match(new RegExp("\\b" + this.escapeCssMeta(o) + "\\b")) || (l += " " + o) : l = o, u(e).attr("aria-describedby", l), (i = this.groups[e.name]) && (r = this, u.each(r.groups, function (e, t) {
                    t === i && u("[name='" + r.escapeCssMeta(e) + "']", r.currentForm).attr("aria-describedby", s.attr("id"))
                })))), !t && this.settings.success && (s.text(""), "string" == typeof this.settings.success ? s.addClass(this.settings.success) : this.settings.success(s, e)), this.toShow = this.toShow.add(s)
            }, errorsFor: function (e) {
                var t = this.escapeCssMeta(this.idOrName(e)), n = u(e).attr("aria-describedby"),
                    i = "label[for='" + t + "'], label[for='" + t + "'] *";
                return n && (i = i + ", #" + this.escapeCssMeta(n).replace(/\s+/g, ", #")), this.errors().filter(i)
            }, escapeCssMeta: function (e) {
                return e.replace(/([\\!"#$%&'()*+,./:;<=>?@\[\]^`{|}~])/g, "\\$1")
            }, idOrName: function (e) {
                return this.groups[e.name] || (this.checkable(e) ? e.name : e.id || e.name)
            }, validationTargetFor: function (e) {
                return this.checkable(e) && (e = this.findByName(e.name)), u(e).not(this.settings.ignore)[0]
            }, checkable: function (e) {
                return /radio|checkbox/i.test(e.type)
            }, findByName: function (e) {
                return u(this.currentForm).find("[name='" + this.escapeCssMeta(e) + "']")
            }, getLength: function (e, t) {
                switch (t.nodeName.toLowerCase()) {
                    case"select":
                        return u("option:selected", t).length;
                    case"input":
                        if (this.checkable(t)) return this.findByName(t.name).filter(":checked").length
                }
                return e.length
            }, depend: function (e, t) {
                return !this.dependTypes[typeof e] || this.dependTypes[typeof e](e, t)
            }, dependTypes: {
                boolean: function (e) {
                    return e
                }, string: function (e, t) {
                    return !!u(e, t.form).length
                }, function: function (e, t) {
                    return e(t)
                }
            }, optional: function (e) {
                var t = this.elementValue(e);
                return !u.validator.methods.required.call(this, t, e) && "dependency-mismatch"
            }, startRequest: function (e) {
                this.pending[e.name] || (this.pendingRequest++, u(e).addClass(this.settings.pendingClass), this.pending[e.name] = !0)
            }, stopRequest: function (e, t) {
                this.pendingRequest--, this.pendingRequest < 0 && (this.pendingRequest = 0), delete this.pending[e.name], u(e).removeClass(this.settings.pendingClass), t && 0 === this.pendingRequest && this.formSubmitted && this.form() ? (u(this.currentForm).submit(), this.formSubmitted = !1) : !t && 0 === this.pendingRequest && this.formSubmitted && (u(this.currentForm).triggerHandler("invalid-form", [this]), this.formSubmitted = !1)
            }, previousValue: function (e, t) {
                return t = "string" == typeof t && t || "remote", u.data(e, "previousValue") || u.data(e, "previousValue", {
                    old: null,
                    valid: !0,
                    message: this.defaultMessage(e, {method: t})
                })
            }, destroy: function () {
                this.resetForm(), u(this.currentForm).off(".validate").removeData("validator").find(".validate-equalTo-blur").off(".validate-equalTo").removeClass("validate-equalTo-blur")
            }
        },
        classRuleSettings: {
            required: {required: !0},
            email: {email: !0},
            url: {url: !0},
            date: {date: !0},
            dateISO: {dateISO: !0},
            number: {number: !0},
            digits: {digits: !0},
            creditcard: {creditcard: !0}
        },
        addClassRules: function (e, t) {
            e.constructor === String ? this.classRuleSettings[e] = t : u.extend(this.classRuleSettings, e)
        },
        classRules: function (e) {
            var t = {}, n = u(e).attr("class");
            return n && u.each(n.split(" "), function () {
                this in u.validator.classRuleSettings && u.extend(t, u.validator.classRuleSettings[this])
            }), t
        },
        normalizeAttributeRule: function (e, t, n, i) {
            /min|max|step/.test(n) && (null === t || /number|range|text/.test(t)) && (i = Number(i), isNaN(i) && (i = void 0)), i || 0 === i ? e[n] = i : t === n && "range" !== t && (e[n] = !0)
        },
        attributeRules: function (e) {
            var t, n, i = {}, o = u(e), r = e.getAttribute("type");
            for (t in u.validator.methods) n = "required" === t ? ("" === (n = e.getAttribute(t)) && (n = !0), !!n) : o.attr(t), this.normalizeAttributeRule(i, r, t, n);
            return i.maxlength && /-1|2147483647|524288/.test(i.maxlength) && delete i.maxlength, i
        },
        dataRules: function (e) {
            var t, n, i = {}, o = u(e), r = e.getAttribute("type");
            for (t in u.validator.methods) n = o.data("rule" + t.charAt(0).toUpperCase() + t.substring(1).toLowerCase()), this.normalizeAttributeRule(i, r, t, n);
            return i
        },
        staticRules: function (e) {
            var t = {}, n = u.data(e.form, "validator");
            return n.settings.rules && (t = u.validator.normalizeRule(n.settings.rules[e.name]) || {}), t
        },
        normalizeRules: function (i, o) {
            return u.each(i, function (e, t) {
                if (!1 !== t) {
                    if (t.param || t.depends) {
                        var n = !0;
                        switch (typeof t.depends) {
                            case"string":
                                n = !!u(t.depends, o.form).length;
                                break;
                            case"function":
                                n = t.depends.call(o, o)
                        }
                        n ? i[e] = void 0 === t.param || t.param : (u.data(o.form, "validator").resetElements(u(o)), delete i[e])
                    }
                } else delete i[e]
            }), u.each(i, function (e, t) {
                i[e] = u.isFunction(t) && "normalizer" !== e ? t(o) : t
            }), u.each(["minlength", "maxlength"], function () {
                i[this] && (i[this] = Number(i[this]))
            }), u.each(["rangelength", "range"], function () {
                var e;
                i[this] && (u.isArray(i[this]) ? i[this] = [Number(i[this][0]), Number(i[this][1])] : "string" == typeof i[this] && (e = i[this].replace(/[\[\]]/g, "").split(/[\s,]+/), i[this] = [Number(e[0]), Number(e[1])]))
            }), u.validator.autoCreateRanges && (null != i.min && null != i.max && (i.range = [i.min, i.max], delete i.min, delete i.max), null != i.minlength && null != i.maxlength && (i.rangelength = [i.minlength, i.maxlength], delete i.minlength, delete i.maxlength)), i
        },
        normalizeRule: function (e) {
            if ("string" == typeof e) {
                var t = {};
                u.each(e.split(/\s/), function () {
                    t[this] = !0
                }), e = t
            }
            return e
        },
        addMethod: function (e, t, n) {
            u.validator.methods[e] = t, u.validator.messages[e] = void 0 !== n ? n : u.validator.messages[e], t.length < 3 && u.validator.addClassRules(e, u.validator.normalizeRule(e))
        },
        methods: {
            required: function (e, t, n) {
                if (!this.depend(n, t)) return "dependency-mismatch";
                if ("select" !== t.nodeName.toLowerCase()) return this.checkable(t) ? 0 < this.getLength(e, t) : 0 < e.length;
                var i = u(t).val();
                return i && 0 < i.length
            }, email: function (e, t) {
                return this.optional(t) || /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(e)
            }, url: function (e, t) {
                return this.optional(t) || /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})).?)(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(e)
            }, date: function (e, t) {
                return this.optional(t) || !/Invalid|NaN/.test(new Date(e).toString())
            }, dateISO: function (e, t) {
                return this.optional(t) || /^\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])$/.test(e)
            }, number: function (e, t) {
                return this.optional(t) || /^(?:-?\d+|-?\d{1,3}(?:,\d{3})+)?(?:\.\d+)?$/.test(e)
            }, digits: function (e, t) {
                return this.optional(t) || /^\d+$/.test(e)
            }, minlength: function (e, t, n) {
                var i = u.isArray(e) ? e.length : this.getLength(e, t);
                return this.optional(t) || n <= i
            }, maxlength: function (e, t, n) {
                var i = u.isArray(e) ? e.length : this.getLength(e, t);
                return this.optional(t) || i <= n
            }, rangelength: function (e, t, n) {
                var i = u.isArray(e) ? e.length : this.getLength(e, t);
                return this.optional(t) || i >= n[0] && i <= n[1]
            }, min: function (e, t, n) {
                return this.optional(t) || n <= e
            }, max: function (e, t, n) {
                return this.optional(t) || e <= n
            }, range: function (e, t, n) {
                return this.optional(t) || e >= n[0] && e <= n[1]
            }, step: function (e, t, n) {
                function i(e) {
                    var t = ("" + e).match(/(?:\.(\d+))?$/);
                    return t && t[1] ? t[1].length : 0
                }

                function o(e) {
                    return Math.round(e * Math.pow(10, r))
                }

                var r, s = u(t).attr("type"), a = "Step attribute on input type " + s + " is not supported.",
                    l = new RegExp("\\b" + s + "\\b"), c = !0;
                if (s && !l.test(["text", "number", "range"].join())) throw new Error(a);
                return r = i(n), (i(e) > r || o(e) % o(n) != 0) && (c = !1), this.optional(t) || c
            }, equalTo: function (e, t, n) {
                var i = u(n);
                return this.settings.onfocusout && i.not(".validate-equalTo-blur").length && i.addClass("validate-equalTo-blur").on("blur.validate-equalTo", function () {
                    u(t).valid()
                }), e === i.val()
            }, remote: function (r, s, e, a) {
                if (this.optional(s)) return "dependency-mismatch";
                a = "string" == typeof a && a || "remote";
                var l, t, n, c = this.previousValue(s, a);
                return this.settings.messages[s.name] || (this.settings.messages[s.name] = {}), c.originalMessage = c.originalMessage || this.settings.messages[s.name][a], this.settings.messages[s.name][a] = c.message, e = "string" == typeof e && {url: e} || e, n = u.param(u.extend({data: r}, e.data)), c.old === n ? c.valid : (c.old = n, (l = this).startRequest(s), (t = {})[s.name] = r, u.ajax(u.extend(!0, {
                    mode: "abort",
                    port: "validate" + s.name,
                    dataType: "json",
                    data: t,
                    context: l.currentForm,
                    success: function (e) {
                        var t, n, i, o = !0 === e || "true" === e;
                        l.settings.messages[s.name][a] = c.originalMessage, o ? (i = l.formSubmitted, l.resetInternals(), l.toHide = l.errorsFor(s), l.formSubmitted = i, l.successList.push(s), l.invalid[s.name] = !1, l.showErrors()) : (t = {}, n = e || l.defaultMessage(s, {
                            method: a,
                            parameters: r
                        }), t[s.name] = c.message = n, l.invalid[s.name] = !0, l.showErrors(t)), c.valid = o, l.stopRequest(s, o)
                    }
                }, e)), "pending")
            }
        }
    });
    var i, o = {};
    u.ajaxPrefilter ? u.ajaxPrefilter(function (e, t, n) {
        var i = e.port;
        "abort" === e.mode && (o[i] && o[i].abort(), o[i] = n)
    }) : (i = u.ajax, u.ajax = function (e) {
        var t = ("mode" in e ? e : u.ajaxSettings).mode, n = ("port" in e ? e : u.ajaxSettings).port;
        return "abort" === t ? (o[n] && o[n].abort(), o[n] = i.apply(this, arguments), o[n]) : i.apply(this, arguments)
    })
}), window.onload = function () {
    /Mobile|webOS|BlackBerry|IEMobile|MeeGo|mini|Fennec|Windows Phone|Android|iP(ad|od|hone)/i.test(navigator.userAgent) ? $('input[name="_mobile"]').val("1") : $('input[name="_mobile"]').val("0"), "none" == $.cookie("check_city") && ($(".h-mobile-check_city").remove(), $(".h-city-question-in").remove()), $(".close-select-city").click(function (e) {
        e.preventDefault(), $(".h-mobile-check_city").remove(), $(".h-city-question-in").remove(), $.cookie("check_city", "none", {expires: 7})
    }), $(".city-list-search a, .city-list a").click(function (e) {
        $.cookie("check_city", "none", {expires: 7})
    })
}, $(function () {
    function i() {
        if (document.body.hasAttribute("data-body-scroll-fix")) {
            var e = document.body.getAttribute("data-body-scroll-fix");
            document.body.removeAttribute("data-body-scroll-fix"), document.body.style.overflow = "", document.body.style.position = "", document.body.style.top = "", document.body.style.left = "", document.body.style.width = "", window.scroll(0, e)
        }
    }

    $(".title-dev").each(function () {
        if (!$(this).hasClass("title-telephone") && !$(this).closest(".pereferya").hasClass("pereferya-not-found")) {
            var e = $(this).html();
            $(this).html("<strong>" + e.split(" ")[0] + "</strong> " + e.substring(e.indexOf(" ") + 1))
        }
    }), $(".row-map-list").each(function () {
        21 < $(".row-map-list > div").length && ($(".show-full-map-list").removeClass("hide"), $(".show-full-map-list a").click(function (e) {
            e.preventDefault(), $(this).parent().hasClass("show_all") ? ($(this).text("Показать еще").parent().removeClass("show_all"), $(".row-map-list").removeClass("show_all")) : ($(this).text("Скрыть").parent().addClass("show_all"), $(".row-map-list").addClass("show_all"))
        }))
    }), $(".go-top-modal a").click(function (e) {
        $.fancybox.close(), $("#hnav").removeClass("visible"), i()
    }), $(".go-top a").click(function (e) {
        e.preventDefault();
        var t = $(this).attr("href");
        if ($.fancybox.close(), $("#hnav").removeClass("visible"), i(), "#" == t) $("html, body").animate({scrollTop: 0}, 500); else {
            var n = $(t).offset().top;
            $("html, body").animate({scrollTop: n - 60}, 500)
        }
        return !1
    }), $(function () {
        !function () {
            // var t = $(".row-packet");
            // $(".row-packet.slick-initialized").length && t.slick("unslick");
            // t.slick({
            //     slidesToShow: 3,
            //     slidesToScroll: 3,
            //     dots: !1,
            //     arrows: !1,
            //     nextArrow: '<div class="slick-next slick-arrow"><img src="./img/icon/right-arrow.svg"></div>',
            //     prevArrow: '<div class="slick-prev slick-arrow"><img src="./img/icon/right-arrow.svg"></div>',
            //     variableWidth: !0,
            //     focusOnSelect: !0,
            //     responsive: [{breakpoint: 5e3, settings: "unslick"}, {
            //         breakpoint: 992,
            //         settings: {slidesToShow: 1, slidesToScroll: 1, arrows: !0}
            //     }]
            // }), $(window).on("resize orientationChange", function (e) {
            //     t.slick("resize")
            // })
        }()
    }), $(document).ready(function () {
        var e = $(".menu-burger"), t = $(".mobile-close"), n = $("#hnav");
        e.click(function (e) {
            setTimeout(function () {
                if (!document.body.hasAttribute("data-body-scroll-fix")) {
                    var e = window.pageYOffset || document.documentElement.scrollTop;
                    document.body.setAttribute("data-body-scroll-fix", e), document.body.style.overflow = "hidden", document.body.style.position = "fixed", document.body.style.top = "-" + e + "px", document.body.style.left = "0", document.body.style.width = "100%"
                }
            }, 10), n.scrollTop(0), n.addClass("visible")
        }), t.click(function (e) {
            i(), n.removeClass("visible")
        })
    }), $(document).ready(function () {
        var t, r = [];
        t = $(".city-main-list, .city-list").find("li").map(function (e, t) {
            return [$(this).text() + "@" + $(this).find("a").attr("href")]
        }).get(), $(function () {
            $("#search-bar").keydown(function (e) {
                setTimeout(function () {
                    var e = $("#search-bar").val().charAt(0).toUpperCase() + $("#search-bar").val().substr(1);
                    r = [], function (e, t) {
                        for (var n = 0; n < t.length; n++) if (t[n].match(e)) {
                            var i = t[n].split("@")[1], o = t[n].replace(e, "<strong>" + e + "</strong>");
                            r.push('<li class="prediction-item"><a href="' + i + '">' + o.substr(0, o.indexOf("@")) + "</a></li>")
                        }
                    }(e, t), "" != e && $("input").val ? $(".city-list-search").html(r).slideDown() : $(".city-list-search").html("").slideUp(), $(".prediction-item:first-child").addClass("focus")
                }, 50)
            })
        }), $("#search-bar").focus(function () {
            0 < $(".prediction-item").length && $(".output").slideDown(), $("#searchform").submit(function (e) {
                e.preventDefault(), $text = $(".focus").find("span").text(), $(".output").slideUp(), $("#search-bar").val($text), $("input").blur()
            })
        }), $("#search-bar").blur(function () {
            0 < $(".prediction-item").length && $(".output").slideUp()
        })
    }), $("[data-fancybox]").fancybox({
        animationEffect: !1,
        touch: !1,
        buttons: ["thumbs", "close"],
        afterClose: function () {
            $(".div-bottom-form").hide(), $(".div-bottom-form").find(".modal-result").hide(), $(".div-top-form").show()
        }
    }), $(".popap-tab").each(function () {
        $(this).find(".popap-tab-nav a").click(function (e) {
            e.preventDefault(), $(this).closest(".popap-tab-nav").find("a").removeClass("active"), $(this).addClass("active");
            var t = $(this).attr("data-tabnav-open");
            $(this).closest(".popap-tab").find(".popap-tab-content").find("div").hide(), $(this).closest(".popap-tab").find('div[data-tab-open="' + t + '"]').show()
        })
    }), $(".question-parent-title").click(function () {
        $(this).parent().hasClass("active") ? ($(this).parent().removeClass("active"), $(this).parent().find(".question-parent-content").slideUp(300)) : ($(this).parent().addClass("active"), $(this).next().slideToggle(300))
    }), $(window).bind("scroll", function () {
        $(".hnav-fixed").outerHeight();
        var e = $("#header").outerHeight();
        $(window).scrollTop() > e ? $(".hnav-fixed").addClass("fixed") : $(".hnav-fixed").removeClass("fixed")
    }), $(".form-address").each(function () {
        $(this);
        $(this).validate({
            rules: {
                name: {required: !0},
                street: {required: !0},
                phone: {required: !0},
                house: {required: !0},
                flat: {required: !0},
                agree_form: {required: !0}
            },
            messages: {
                name: {required: "* заполнить"},
                street: {required: "* заполнить"},
                phone: {required: "* заполнить"},
                house: {required: "* заполнить"},
                flat: {required: "* заполнить"},
                agree_form: {required: !1}
            },
            errorPlacement: function (e, t) {
                if ("checkbox" == t.attr("type")) return t.next("label").append(e);
                e.insertAfter($(t))
            },
            submitHandler: function (t) {
                $.ajax({
                    type: "POST",
                    headers: {"X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content")},
                    url: "/crm/send",
                    data: $(t).serialize(),
                    error: function () {
                        $.fancybox.open({
                            src: "#bad-result_popap",
                            type: "inline",
                            animationEffect: !1,
                            touch: !1,
                            buttons: ["thumbs", "close"]
                        })
                    },
                    beforeSend: function () {
                    },
                    success: function (e) {
                        $.fancybox.open({
                            src: "#good-result_popap",
                            type: "inline",
                            animationEffect: !1,
                            touch: !1,
                            buttons: ["thumbs", "close"]
                        }), setTimeout(function () {
                            $(t).trigger("reset")
                        }, 1e3)
                    }
                })
            }
        })
    }), $(".form-block-header").each(function () {
        $(this);
        $(this).validate({
            rules: {name: {required: !0}, phone: {required: !0}},
            messages: {name: {required: "* заполнить"}, phone: {required: "* заполнить"}},
            submitHandler: function (t) {
                $.ajax({
                    type: "POST",
                    headers: {"X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content")},
                    url: "/crm/send",
                    data: $(t).serialize(),
                    error: function () {
                        $.fancybox.open({
                            src: "#bad-result_popap",
                            type: "inline",
                            animationEffect: !1,
                            touch: !1,
                            buttons: ["thumbs", "close"]
                        })
                    },
                    beforeSend: function () {
                    },
                    success: function (e) {
                        $.fancybox.open({
                            src: "#good-result_popap",
                            type: "inline",
                            animationEffect: !1,
                            touch: !1,
                            buttons: ["thumbs", "close"]
                        }), setTimeout(function () {
                            $(t).trigger("reset")
                        }, 1e3)
                    }
                })
            }
        })
    }), $(".form-block").each(function () {
        $(this);
        $(this).validate({
            rules: {name: {required: !0}, phone: {required: !0}, agree_form: {required: !0}},
            messages: {name: {required: "* заполнить"}, phone: {required: "* заполнить"}, agree_form: {required: !1}},
            errorPlacement: function (e, t) {
                if ("checkbox" == t.attr("type")) return t.next("label").append(e);
                e.insertAfter($(t))
            },
            submitHandler: function (t) {
                $.ajax({
                    type: "POST",
                    headers: {"X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content")},
                    url: "/crm/send",
                    data: $(t).serialize(),
                    error: function () {
                        $(t).closest(".div-top-form").next(".div-bottom-form").find(".modal-result-good").hide(), $(t).closest(".div-top-form").next(".div-bottom-form").find(".modal-result-bad").show(), $(t).closest(".div-top-form").hide().next(".div-bottom-form").show()
                    },
                    beforeSend: function () {
                    },
                    success: function (e) {
                        $(t).closest(".div-top-form").next(".div-bottom-form").find(".modal-result-bad").hide(), $(t).closest(".div-top-form").next(".div-bottom-form").find(".modal-result-good").show(), $(t).closest(".div-top-form").hide().next(".div-bottom-form").show(), setTimeout(function () {
                            $(t).trigger("reset")
                        }, 1e3)
                    }
                })
            }
        })
    }), $(".form-block-check-address").each(function () {
        $(this);
        $(this).validate({
            rules: {street: {required: !0}, home: {required: !0}},
            messages: {street: {required: "* заполнить"}, home: {required: "* заполнить"}},
            submitHandler: function (e) {
                $(e).closest(".div-top-form").hide().next(".div-bottom-form").show().find(".modal-result-good").show(), $(e).closest(".div-top-form").next(".div-bottom-form").find(".modal-result-good").show(), setTimeout(function () {
                    $(e).trigger("reset")
                }, 1e3)
            }
        })
    });


});


