var ignoreTypes = {
    м: true,
    тер: true,
    мост: true
};

var search_mask = (where, text) => {
    var markInstance = new Mark(where);
    var input_value = text;
    var options = {};
    markInstance.unmark({
        done: function() {
            markInstance.mark(input_value, options);
        }
    });
};

function reg_keyboard(str) {
    let replacer = {
        q: "й",
        w: "ц",
        e: "у",
        r: "к",
        t: "е",
        y: "н",
        u: "г",
        i: "ш",
        o: "щ",
        p: "з",
        "[": "х",
        "]": "ъ",
        a: "ф",
        s: "ы",
        d: "в",
        f: "а",
        g: "п",
        h: "р",
        j: "о",
        k: "л",
        l: "д",
        ";": "ж",
        "'": "э",
        z: "я",
        x: "ч",
        c: "с",
        v: "м",
        b: "и",
        n: "т",
        m: "ь",
        ",": "б",
        ".": "ю",
        "/": "."
    };
    return str.replace(/[A-z/,.;\'\]\[]/g, function(x) {
        return x == x.toLowerCase()
            ? replacer[x]
            : replacer[x.toLowerCase()].toUpperCase();
    });
}

var search_street = document.querySelector("#modal-address");
var street = search_street.querySelector("#street");
var plachelder_street = document.querySelector(".input_street");
var streetInp = street;
var street_id = "";

var wrap = search_street.querySelector("#suggestions");

var search_help = search_street.querySelector(".modal-address__info");
var search_clear = search_street.querySelector(".modal-address__close");

var result_start = search_street.querySelector(".search-address__proccess");
var result_end = search_street.querySelector(".search-address__result");
var result_bad = search_street.querySelector(".search-address__bad");
var result_close = search_street.querySelectorAll(".close_result");

if (result_close) {
    result_close.forEach(function(item) {
        item.addEventListener("click", function() {
            result_start.style.display = "block";
            result_end.style.display = "none";
            result_bad.style.display = "none";
        });
    });
}

function closeTouch() {
    var el = document.querySelector("body");
    el.addEventListener("touchstart", swipeTouch, false);
}

function swipeTouch() {
    street.blur();
}

function sendOnChange(element) {
    if ("createEvent" in document) {
        var evt = document.createEvent("HTMLEvents");
        evt.initEvent("input", false, true);
        element.dispatchEvent(evt);
    } else {
        element.fireEvent("oninput");
    }
}

closeTouch();

if (street) {
    street.addEventListener("input", onChange);

    function onChange(e) {
        if (e.target.value.length > 1) {
            if (search_clear) search_clear.style.display = "flex";
            wrap.style.display = "block";
            var promise = suggest(e.target.value);
            search_help.classList.add("hide");
            promise
                .then(function(response) {
                    return response.json();
                })
                .then(function(suggestions) {
                    wrap.innerHTML = "";
                    for (var key in suggestions["suggestions"]) {
                        if (
                            ignoreTypes[
                                suggestions["suggestions"][key]["data"]
                                    .street_type
                                ]
                        ) {
                            continue;
                        }

                        var street =
                            suggestions["suggestions"][key]["data"][
                                "street_with_type"
                                ];
                        if (street) {
                            var house =
                                suggestions["suggestions"][key]["data"][
                                    "house"
                                    ];
                            var house_in =
                                house === null ? "" : ", " + house;

                            var house_fias_id =
                                suggestions["suggestions"][key]["data"][
                                    "house_fias_id"
                                    ];
                            var street_fias_id =
                                suggestions["suggestions"][key]["data"][
                                    "street_fias_id"
                                    ];

                            var block_type =
                                suggestions["suggestions"][key]["data"][
                                    "block_type"
                                    ];
                            if (
                                block_type == "лит" ||
                                block_type == "литер"
                            )
                                block_type = "";
                            if (block_type == "стр")
                                block_type = " " + block_type + " ";
                            var block =
                                suggestions["suggestions"][key]["data"][
                                    "block"
                                    ];
                            block =
                                block === null ? "" : block_type + block;

                            var city =
                                suggestions["suggestions"][key]["data"][
                                    "city"
                                    ];
                            var region =
                                suggestions["suggestions"][key]["data"][
                                    "region"
                                    ];
                            var region_with_type =
                                suggestions["suggestions"][key]["data"][
                                    "region_with_type"
                                    ] || "";
                            var area_with_type =
                                ". " +
                                suggestions["suggestions"][key]["data"][
                                    "area_with_type"
                                    ] +
                                ". " || "";
                            var settlement_with_type =
                                suggestions["suggestions"][key]["data"][
                                    "settlement_with_type"
                                    ] || "";

                            var uniqueAddress = "";
                            if (
                                suggestions["suggestions"][key]["data"][
                                    "block"
                                    ] &&
                                suggestions["suggestions"][key]["data"][
                                    "block_type"
                                    ]
                            ) {
                                uniqueAddress =
                                    suggestions["suggestions"][key]["data"][
                                        "city_kladr_id"
                                        ] +
                                    suggestions["suggestions"][key]["data"][
                                        "street_with_type"
                                        ] +
                                    suggestions["suggestions"][key]["data"][
                                        "house"
                                        ] +
                                    suggestions["suggestions"][key]["data"][
                                        "block_type"
                                        ] +
                                    suggestions["suggestions"][key]["data"][
                                        "block"
                                        ];
                            } else {
                                uniqueAddress =
                                    suggestions["suggestions"][key]["data"][
                                        "city_kladr_id"
                                        ] +
                                    suggestions["suggestions"][key]["data"][
                                        "street_with_type"
                                        ] +
                                    suggestions["suggestions"][key]["data"][
                                        "house"
                                        ];
                            }

                            // city = '<div class="city">' + city + '</div>';
                            if (city) {
                                city =
                                    city == region
                                        ? region_with_type
                                        : city;
                            } else {
                                city =
                                    region_with_type +
                                    area_with_type +
                                    settlement_with_type;
                            }
                            region = '<div class="city">' + city + "</div>";

                            if (house === null) {
                                var div = document.createElement("div");
                                div.innerHTML =
                                    '<div class="suggestions-item" data-id="' +
                                    street_fias_id +
                                    '"><div class="title suggestions__title">' +
                                    street +
                                    "</div>" +
                                    region +
                                    "</div>";
                                wrap.append(div);
                            } else {
                                var div = document.createElement("div");
                                div.innerHTML =
                                    '<div class="suggestions-item"><a href="#" class="js-select-house" data-fias="' +
                                    house_fias_id +
                                    '"></a><div class="title">' +
                                    street +
                                    house_in +
                                    block +
                                    "</div>" +
                                    region +
                                    "</div>";
                                var el = div.querySelector(
                                    ".js-select-house"
                                );
                                el.addEventListener("click", function(evt) {
                                    evt.preventDefault();
                                    //console.log(suggestions);

                                    var house_fias_id =
                                        evt.target.dataset.fias;
                                    var clickedSuggesstion = suggestions[
                                        "suggestions"
                                        ].find(
                                        sug =>
                                            sug.data.house_fias_id ==
                                            house_fias_id
                                    );

                                    getUrl(
                                        uniqueAddress,
                                        clickedSuggesstion
                                    )
                                        .then(function(response) {
                                            return response.json();
                                        })
                                        .then(function(result) {
                                            $.fancybox.close();
                                            if (result.success) {
                                                $.fancybox.open({
                                                    src: "#detected-connection-modal",
                                                    type: "inline"
                                                })
                                            } else {
                                                $.fancybox.open({
                                                    src: "#not-found-connection-modal",
                                                    type: "inline"
                                                })
                                            }
                                        })
                                        .catch(function(error) {
                                            console.log(error);
                                        });
                                });
                                wrap.append(div);
                            }

                            var address_list = document.querySelectorAll(
                                ".suggestions-item"
                            );
                            address_list.forEach(function(item) {
                                search_mask(
                                    item.querySelector(
                                        ".suggestions__title"
                                    ),
                                    reg_keyboard(e.target.value)
                                );
                                if (house === null) {
                                    item.onclick = function(evt) {
                                        document.querySelector(
                                            "#street"
                                        ).value =
                                            item.querySelector(
                                                ".suggestions__title"
                                            ).textContent + " ";
                                        street_id = item.getAttribute(
                                            "data-id"
                                        );
                                        sendOnChange(streetInp);
                                        document
                                            .querySelector("#street")
                                            .focus();
                                    };
                                }
                            });
                        }
                    }
                })
                .catch(function(error) {
                    console.log(error);
                });
            if (plachelder_street) plachelder_street.value = e.target.value;
        } else {
            search_help.classList.remove("hide");
            wrap.innerHTML = "";
            wrap.style.display = "none";
            if (search_clear) search_clear.style.display = "none";
            street_id = "";
            if (plachelder_street) plachelder_street.value = "";
        }
    }

    function suggest(query) {
        var serviceUrl = `https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address`;
        var request = {
            query: query,
            count: 12,
            locations: [
                {
                    kladr_id: kladr,
                    street_fias_id: street_id
                }
            ],
            from_bound: { value: "street" },
            to_bound: { value: "house" }
        };

        if (kladr == 7700000000000) {
            // Москва
            request.locations.push({ kladr_id: 5000000000000 });
        } else if (kladr == 7800000000000) {
            // Питер
            request.locations.push({ kladr_id: 4700000000000 });
        }

        var params = {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Token " + token
            },
            body: JSON.stringify(request)
        };
        return fetch(serviceUrl, params);
    }

    function getUrl(unique_address, choosedSuggestion) {
        var request = {
            unique_address: unique_address
        };

        if (choosedSuggestion != undefined) {
            var hwb = choosedSuggestion.data.house;

            if (choosedSuggestion.block_type != null) {
                hwb += choosedSuggestion.data.block_type;
            }

            if (choosedSuggestion.data.block != null) {
                hwb += choosedSuggestion.data.block;
            }

            request.area = choosedSuggestion.data.area;
            request.house_fias_id = choosedSuggestion.data.house_fias_id;
            request.street_with_type =
                choosedSuggestion.data.street_with_type;
            request.house_with_block = hwb;
            request.kladr = choosedSuggestion.data.city_kladr_id;
            request.geo_lat = choosedSuggestion.data.geo_lat;
            request.geo_lon = choosedSuggestion.data.geo_lon;
        }
        var serviceUrl = `/crm/check-connection`;

        var params = {
            headers: {"X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content")},
            method: "POST",
            body: JSON.stringify(request)
        };
        return fetch(serviceUrl, params);
    }
    search_clear.onclick = function() {
        street.value = "";
        plachelder_street.value = "";
        street.focus();
        sendOnChange(street);
    };
}
// select address desktop

if (street && street.value.trim().length > 0) {
    sendOnChange(street);
}